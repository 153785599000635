import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, responsiveSizing, spacing, typography } from '@vfuk/core-mixins'

import { StyledTextAreaInputProps } from './TextAreaInput.style.types'

export const TextAreaInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const TextAreaInput = styled.textarea<StyledTextAreaInputProps>(
  (props: StyledTextAreaInputProps): FlattenSimpleInterpolation => css`
    color: ${props.theme.color.monochrome5.default};
    transition: 0.4s box-shadow ease;
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome3.default};
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    width: 100%;
    vertical-align: top;

    ${responsiveSizing('min-height', 8)};
    ${advancedSpacing('padding', [2, 3])}
    ${typography.heading(1, false)}

  ${props.state &&
    css`
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color[props.state].default};
      ${spacing('padding-right', 7)}
    `}

  ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.color.monochrome2.default};
      color: ${props.theme.color.monochrome3.default};
      &:focus {
        caret-color: transparent;
      }
    `}

  &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }
  `,
)
