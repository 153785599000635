import { PureComponent } from 'react'
import isFunction from 'lodash/isFunction'

import { RendererProps, RendererState } from './Renderer.types'
import { OverlayCloseSource } from './constants/constants'

export default class Renderer<P extends RendererProps, S extends RendererState> extends PureComponent<P, S> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public state = {
    show: false,
  }

  private setShow(isOpen: boolean): void {
    setTimeout(() => {
      this.setState({
        show: isOpen,
      })
    })
  }

  public getOnCloseHandler = (source: OverlayCloseSource): undefined | (() => void) => {
    const { onClose } = this.props

    if (!onClose) {
      return
    }
    return (): void => {
      onClose(source)
    }
  }

  public onDoneCallback = (): void => {
    this.setState({
      show: false,
    })
    if (isFunction(this.props.hasClosed)) {
      this.props.hasClosed()
    }
  }

  public componentDidMount(): void {
    this.setShow(this.props.isOpen)
  }

  public componentDidUpdate(prevProps: P): void {
    if (prevProps.isOpen === this.props.isOpen) return
    this.setShow(this.props.isOpen)
  }
}
