import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, responsiveCalc, srOnly } from '@vfuk/core-mixins'

import { StyledRadioProps } from './Radio.style.types'

import localThemes from '../themes/Radio.theme'

export const RadioWrapper = styled.div(() => {
  return css`
    min-height: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    ${responsiveCalc('padding-left', '1.25rem', '+', 2)};
  `
})

type RadioProps = Pick<StyledRadioProps, 'theme'>
export const Radio = styled.input<RadioProps>(
  (props) => css`
    font-size: 1rem;
    height: 2rem;
    left: 0;
    opacity: 0;
    margin: 0;
    position: relative;
    top: 0;
    width: 0;

    &:focus {
      & + label:before {
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
        outline: 3px solid transparent;
      }
    }
  `,
)

export const HiddenLabel = styled.span(
  () => css`
    ${srOnly()}
  `,
)

type LabelTextProps = Pick<StyledRadioProps, 'theme' | 'checked' | 'disabled'>
const labelLineHeight = 2
export const LabelText = styled.label<LabelTextProps>((props) => {
  const localTheme = localThemes(props.theme)

  return css`
    font-size: ${props.theme.typography.paragraph[2].fontMobile};
    line-height: ${labelLineHeight}rem;

    &:before {
      content: '';
      position: absolute;
      top: ${labelLineHeight / 2}rem;
      left: 0;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background: ${props.theme.color.monochrome1.default};
      border: 1px solid ${localTheme.borderColor};
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: ${labelLineHeight / 2}rem;
      left: 4px;
      height: 12px;
      width: 12px;
      opacity: 0;
      background-color: ${props.disabled ? localTheme.state.disabled.checked.backgroundColor : localTheme.checked.backgroundColor};
      border-radius: 50%;
      transform: translateY(-50%);
      transition: opacity 0.1s ease-in-out;
    }

    ${props.checked &&
    css`
      &:before {
        border-color: ${localTheme.checked.borderColor};
      }

      &:after {
        opacity: 1;
      }
    `}

    ${props.disabled &&
    css`
      color: ${localTheme.state.disabled.color};
      cursor: not-allowed;

      &:before {
        background-color: ${localTheme.state.disabled.backgroundColor};
        border-color: ${localTheme.state.disabled.borderColor};
      }
    `}

    ${respondTo.md(css`
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
    `)}
  `
})

export const FullWidthWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
  `,
)
