import { isValidElement, cloneElement } from 'react'

import { ParseCardsSectionReturn, BreakpointKeys } from './parseCardsSection.types'

import { CardsSection } from '../../CardList.types'

function modifyCardProps(rowOfCards: CardsSection['cards']): CardsSection['cards'] {
  return rowOfCards.map((card) => {
    if (isValidElement(card) && !card.props.container?.label) {
      return cloneElement(card, {
        container: {
          ...card.props.container,
          showOuterTopPadding: true,
        },
      })
    }

    return card
  })
}

function checkForCardLabelsInRow(rowOfCards: CardsSection['cards']): CardsSection['cards'] {
  const cardInRowHasLabel = rowOfCards.findIndex((card) => {
    return isValidElement(card) && card.props.container?.label
  })

  if (cardInRowHasLabel >= 0) return modifyCardProps(rowOfCards)

  return rowOfCards
}

function parseCardsSectionCards(
  cards: CardsSection['cards'],
  columns: number | undefined,
  renderSameHeight: boolean,
): CardsSection['cards'] {
  if (!columns) return cards

  if (!Array.isArray(cards) || columns === 1) return [cards]

  const rows = Math.ceil(cards.length / columns)

  let firstCardInRowIndex: number = 0
  const parsedCards = []

  for (let index = 0; index < rows; index++) {
    const finalCardInRowIndex: number = columns * (index + 1)
    const rowOfCards = cards.slice(firstCardInRowIndex, finalCardInRowIndex)

    if (renderSameHeight) parsedCards.push(checkForCardLabelsInRow(rowOfCards))
    else parsedCards.push(...checkForCardLabelsInRow(rowOfCards))

    firstCardInRowIndex = finalCardInRowIndex
  }

  return parsedCards
}

function parseCardsSection(cardsSection: CardsSection, renderSameHeight: boolean): ParseCardsSectionReturn {
  return Object.keys(cardsSection.grid.columns).map((breakpoint: BreakpointKeys) => {
    return {
      breakpoint,
      cards: parseCardsSectionCards(cardsSection.cards, cardsSection.grid.columns[breakpoint], renderSameHeight),
    }
  })
}

export default parseCardsSection
