import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import colorSwatchThemes from '@vfuk/core-color-swatch/dist/themes/ColorSwatch.theme'
import { respondTo } from '@vfuk/core-mixins'

import { StyledColorSelectProps } from './ColorSelect.style.types'

export const ColorSwatchPlaceholder = styled.div((props: StyledColorSelectProps): FlattenSimpleInterpolation => {
  const colorSwatchTheme = colorSwatchThemes(props.theme)
  return css`
    width: ${colorSwatchTheme.size[1].swatch.sm}px;
    height: ${colorSwatchTheme.size[1].swatch.sm}px;

    ${respondTo.md(css`
      width: ${colorSwatchTheme.size[1].swatch.md}px;
      height: ${colorSwatchTheme.size[1].swatch.md}px;
    `)}

    ${respondTo.lg(css`
      width: ${colorSwatchTheme.size[1].swatch.lg}px;
      height: ${colorSwatchTheme.size[1].swatch.lg}px;
    `)}
  `
})
