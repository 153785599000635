import React, { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'

import FunctionalAvatar from '@vfuk/core-functional-avatar'

import * as Styled from './styles/InitialsAvatar.style'

import { InitialsAvatarProps } from './InitialsAvatar.types'

import localThemes from './themes/InitialsAvatar.theme'

export const InitialsAvatar: FC<InitialsAvatarProps> = ({
  initials,
  href,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  size = 2,
  srText,
  dataAttributes,
  theme,
  customRouterProps,
}: InitialsAvatarProps): ReactElement => {
  const localTheme = localThemes(theme!)

  return (
    <FunctionalAvatar
      backgroundColor={localTheme.backgroundColor}
      size={size}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      customRouterProps={customRouterProps}
      href={href}
      dataAttributes={dataAttributes}
      srText={srText}
    >
      <Styled.Initials size={size}>{initials}</Styled.Initials>
    </FunctionalAvatar>
  )
}

export default withTheme(InitialsAvatar)
