import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DateTextInputProps } from '../../DateTextInput.types'

dayjs.extend(customParseFormat)

const dateFormatter = (date: string | Date, showMonthYearPicker: DateTextInputProps['showMonthYearPicker']): dayjs.Dayjs => {
  const dateFormat = showMonthYearPicker ? 'MM/YYYY' : 'DD/MM/YYYY'
  return dayjs(date, dateFormat, true)
}

export default dateFormatter
