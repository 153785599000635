import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { TagTheme } from './Tag.theme.types'

const tagLocal = (theme: Theme): TagTheme => {
  return {
    appearance: {
      primary: {
        inverseText: true,
        inverseIcon: true,
        backgroundColor: theme.color.monochrome6.default,
        hover: {
          backgroundColor: theme.color.monochrome6.hover,
        },
        inverse: {
          inverseText: false,
          inverseIcon: false,
          backgroundColor: theme.inverseColor.monochrome6.default,
          hover: {
            backgroundColor: theme.inverseColor.monochrome6.hover,
          },
        },
      },
      secondary: {
        inverseText: true,
        inverseIcon: true,
        backgroundColor: theme.color.secondary1.default,
        hover: {
          backgroundColor: theme.color.secondary1.hover,
        },
        inverse: {
          inverseText: false,
          inverseIcon: false,
          backgroundColor: theme.inverseColor.secondary1.default,
          hover: {
            backgroundColor: theme.inverseColor.secondary1.hover,
          },
        },
      },
    },
  }
}

export default tagLocal
