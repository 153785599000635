import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import { StyledIconSnippetProps } from './IconSnippet.style.types'

export const IconSnippetContainer = styled.div<StyledIconSnippetProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;
    height: 100%;

    > span {
      ${spacing('margin-right', 3)}
    }

    ${props.justify === 'center' &&
    css`
      display: block;
      text-align: center;
      margin: 0 auto;

      > span {
        margin-right: 0;
        ${spacing('margin-bottom', 3)}
      }
    `}
  `,
)
