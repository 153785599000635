import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { DividerTheme } from './Divider.theme.types'

const dividerLocal = (theme: Theme): DividerTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome2.default,
        inverse: {
          color: theme.inverseColor.monochrome2.default,
        },
      },
      secondary: {
        color: theme.color.monochrome3.default,
        inverse: {
          color: theme.inverseColor.monochrome3.default,
        },
      },
      alt1: {
        color: theme.color.primary1.default,
        inverse: {
          color: theme.inverseColor.primary1.default,
        },
      },
      alt2: {
        color: theme.color.primary1.default,
        inverse: {
          color: theme.inverseColor.primary1.default,
        },
      },
      alt3: {
        color: theme.color.monochrome6.default,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
        },
      },
      alt4: {
        color: theme.color.primary4.default,
        inverse: {
          color: theme.inverseColor.primary4.default,
        },
      },
    },
    level: {
      1: {
        borderStyle: theme.border.style[1],
      },
      2: {
        borderStyle: theme.border.style[2],
      },
    },
  }
}

export default dividerLocal
