import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';

// const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
const PrivateRoute = props => {
  // const authJson = localStorage.getItem('auth');
  // const auth = authJson ? JSON.parse(authJson) : null;
  const { children: childComponent, ...rest } = props;
  const auth = props.auth;
  const isLoggedIn = () => auth && auth.access_token;

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      children={isLoggedIn() ? childComponent : <Redirect to="/" />}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.authentication
});

// PrivateRoute.defaultProps = {
//   isAuthenticated: false
// };

// PrivateRoute.propTypes = {
//   isAuthenticated: PropTypes.bool,
//   component: PropTypes.shape({}).isRequired
// };

export default connect(mapStateToProps)(PrivateRoute);
// export default PrivateRoute;
