import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing, srOnly } from '@vfuk/core-mixins'

export const DateTextInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: none;
  `,
)

export const IconContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('padding-right', 3)};
    pointer-events: none;
    display: flex;
    align-items: center;
  `,
)

export const DateTextInputLabel = styled.label(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
