import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Col } from 'styled-bootstrap-grid'

import { spacing } from '@vfuk/core-mixins'

import { StyledGridColumn } from './GridColumn.style.types'

export const GridColumn = styled(Col)(
  (props: StyledGridColumn): FlattenSimpleInterpolation => css`
    ${props.autoMargin &&
    `
    margin-${props.autoMargin}: auto;
  `}

    ${props.marginTop && spacing('margin-top', props.marginTop)}

  ${props.marginBottom && spacing('margin-bottom', props.marginBottom)}
  `,
)
