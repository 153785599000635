import React, { ReactElement, FC } from 'react'

import Icon from '@vfuk/core-icon'

import Paragraph from '@vfuk/core-paragraph'

import Link from '@vfuk/core-link'

import { BenefitItemProps } from './BenefitItem.types'

import * as Styled from './styles/BenefitItem.style'

const BenefitItem: FC<BenefitItemProps> = ({
  heading,
  subText,
  icon,
  link,
  subIcons,
  iconSeparatorText,
  cardLevel = 1,
}: BenefitItemProps): ReactElement => {
  return (
    <Styled.BenefitItem>
      <If condition={icon?.name}>
        <Icon name={icon?.name} size={cardLevel === 2 ? 3 : 4} group={icon?.group || 'system'} />
      </If>
      <Styled.BenefitItemTextContainer>
        <If condition={heading.text}>
          <Paragraph size={cardLevel === 2 ? 1 : 2} noMargin>
            {heading.text}
          </Paragraph>
        </If>
        <If condition={subText}>
          <Paragraph size={1} noMargin>
            {subText}
          </Paragraph>
        </If>
        <If condition={link}>
          <Link
            showUnderline
            href={link?.href}
            onClick={link?.onClick}
            onMouseDown={link?.onMouseDown}
            onMouseUp={link?.onMouseUp}
            onTouchStart={link?.onTouchStart}
            onTouchEnd={link?.onTouchEnd}
            htmlAttributes={link?.htmlAttributes}
            customRouterProps={link?.customRouterProps}
          >
            <Paragraph size={1} noMargin>
              {link?.text}
            </Paragraph>
          </Link>
        </If>
        <Styled.BenefitItemSubIconContainer>
          {subIcons?.map((subIcon, index) => (
            <React.Fragment key={index}>
              <Choose>
                <When condition={iconSeparatorText}>
                  <Icon name={subIcon?.name} size={3} group={subIcon?.group || 'system'} />
                  <If condition={iconSeparatorText && index !== subIcons?.length - 1}>
                    <Paragraph weight={3} noMargin>
                      {iconSeparatorText}
                    </Paragraph>
                  </If>
                </When>
                <Otherwise>
                  <Icon name={subIcon?.name} size={cardLevel === 2 ? 1 : 2} group={subIcon?.group || 'system'} />
                </Otherwise>
              </Choose>
            </React.Fragment>
          ))}
        </Styled.BenefitItemSubIconContainer>
      </Styled.BenefitItemTextContainer>
    </Styled.BenefitItem>
  )
}

export default BenefitItem
