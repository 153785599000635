import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSelectInputWithLabelProps } from './SelectInputWithLabel.style.types'

export const Container = styled.div(
  (props: StyledSelectInputWithLabelProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
  `,
)
