import React, { FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'
import localThemes from '@vfuk/core-button/dist/themes/Button.theme'

import { SmallButtonProps } from './SmallButton.types'

import * as Styled from './styles/SmallButton.style'

export const SmallButton: FC<SmallButtonProps> = ({
  appearance = 'primary',
  theme,
  width = 'auto',
  loading = false,
  htmlAttributes,
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
}) => {
  const localThemeAppearance = localThemes(theme!).appearance[appearance!]

  return (
    <Styled.SmallButton
      appearance={appearance!}
      dataAttributes={htmlAttributes?.dataAttributes}
      disabled={state === 'disabled' || loading!}
      href={href}
      id={id}
      inverse={inverse}
      label={text}
      loading={loading!}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      state={state!}
      width={width!}
      customRouterProps={customRouterProps}
    >
      <Choose>
        <When condition={loading || state === 'selected'}>
          <Styled.IconContainer>
            <If condition={loading}>
              <Choose>
                <When condition={inverse}>
                  <LoadingSpinner
                    appearance={localThemeAppearance.inverse.loadingSpinnerAppearance}
                    inverse={localThemeAppearance.inverse.inverseIcon}
                    size={1}
                  />
                </When>
                <Otherwise>
                  <LoadingSpinner
                    appearance={localThemeAppearance.loadingSpinnerAppearance}
                    inverse={localThemeAppearance.inverseIcon}
                    size={1}
                  />
                </Otherwise>
              </Choose>
            </If>
            <If condition={state === 'selected' && !loading}>
              <Choose>
                <When condition={inverse}>
                  <Icon
                    appearance={localThemeAppearance.inverse.state[state!].iconAppearance}
                    inverse={localThemeAppearance.inverse.state[state!].inverseIcon}
                    name='tick'
                    group='system'
                    size={2}
                    isResponsive={false}
                  />
                </When>
                <Otherwise>
                  <Icon
                    appearance={localThemeAppearance.state[state!].iconAppearance}
                    inverse={localThemeAppearance.state[state!].inverseIcon}
                    name='tick'
                    group='system'
                    size={2}
                    isResponsive={false}
                  />
                </Otherwise>
              </Choose>
            </If>
          </Styled.IconContainer>
          <Styled.HiddenText>{text}</Styled.HiddenText>
        </When>
        <Otherwise>{text}</Otherwise>
      </Choose>
    </Styled.SmallButton>
  )
}

export default withTheme(SmallButton)
