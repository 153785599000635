import React, { memo, ReactElement } from 'react'

import Button from '@vfuk/core-button'
import ButtonWithIcon from '@vfuk/core-button-with-icon'

import { ChooseButtonProps } from './ChooseButtonType.types'

const ChooseButtonType = ({ button }: ChooseButtonProps): ReactElement => (
  <Choose>
    <When condition={button?.icon}>
      <ButtonWithIcon
        appearance={button.appearance}
        text={button.text}
        onClick={button.onClick}
        onMouseDown={button.onMouseDown}
        onMouseUp={button.onMouseUp}
        onTouchStart={button.onTouchStart}
        onTouchEnd={button.onTouchEnd}
        href={button.href}
        loading={button.loading}
        state={button.state}
        htmlAttributes={button.htmlAttributes}
        icon={{
          name: button!.icon!.name,
          justify: button!.icon!.justify,
        }}
        customRouterProps={button?.customRouterProps}
      />
    </When>
    <Otherwise>
      <Button {...button} />
    </Otherwise>
  </Choose>
)

export default memo(ChooseButtonType)
