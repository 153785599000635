import React, { ReactElement, FC, memo } from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'

import SimpleGrid from '@vfuk/core-simple-grid'

import ColorSwatch from '@vfuk/core-color-swatch'

import * as Styled from './styles/ColorPicker.style'

import { ColorPickerProps } from './ColorPicker.types'

const ColorPicker: FC<ColorPickerProps> = ({
  label,
  selected,
  groupName,
  inverse,
  onChange,
  onBlur,
  items,
  columns,
}: ColorPickerProps): ReactElement => {
  const defaultColumns = {
    sm: items.length <= 2 ? items.length : 3,
    md: items.length <= 4 ? items.length : 5,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset onBlur={handleOnBlur}>
      <Styled.Legend>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2} verticalAlign='stretch'>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                dataAttributes={pickerItem.dataAttributes}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
              >
                <Styled.ColorWrapper>
                  <ColorSwatch key={`${pickerItem.id + groupName}`} inverse={inverse} size={3} color={pickerItem.color} />
                </Styled.ColorWrapper>
              </FunctionalPickerItem>
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default memo(ColorPicker)
