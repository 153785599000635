import dayjs from 'dayjs'

import { PartialDateTextInputProps } from '../inputErrorHandler/inputErrorHandler.types'

import errorMessages from '../../constants/errorMessages'

const isParsedDateValid = (parsedDate: dayjs.Dayjs, props: PartialDateTextInputProps): string[] | undefined => {
  if (!parsedDate.isValid()) {
    // Returns empty array when errorMessages=null, so that no error messages are shown (custom nor default)
    if (props.errorMessages === null) return ['']

    // Returns default error message when errorMessages is undefined
    return [`${errorMessages.INVALID_DATE} ${props.showMonthYearPicker ? 'MM/YYYY' : 'DD/MM/YYYY'}`]
  }
}

export default isParsedDateValid
