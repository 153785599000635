import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'

import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import IconButton from '@vfuk/core-icon-button'
import { Layout } from '@vfuk/core-simple-grid/dist/SimpleGrid.types'
import SimpleGrid from '@vfuk/core-simple-grid'
import capitalizeFirstCharacter from '@vfuk/core-helpers/dist/capitalizeFirstCharacter'
import { AnimationTypes } from '@vfuk/core-animate/dist/animations/animations.types'
import { withTheme } from 'styled-components'

import * as Styled from './styles/SideTrayRenderer.style'

import { SideTrayRendererProps } from './SideTrayRenderer.types'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'

export class SideTrayRenderer extends Renderer<SideTrayRendererProps, RendererState> {
  public static defaultProps: Partial<SideTrayRendererProps> = {
    side: 'left',
    size: 1,
    overlayBlur: false,
    enableOverlay: false,
    isClosable: true,
    topOffset: '0',
  }

  private columnWidths(size: SideTrayRendererProps['size']): Layout {
    if (size === 1) {
      return {
        sm: [12],
        md: [5],
        lg: [3],
      }
    }
    if (size === 2) {
      return {
        sm: [12],
        md: [6],
        lg: [5],
      }
    }
    return {
      sm: [12],
      md: [9],
      lg: [6],
    }
  }

  public render(): ReactNode {
    const animationDuration = this.props.animate ? 200 : 0

    return createPortal(
      <Styled.SideTrayRenderer zIndex={this.props.zIndex} topOffset={this.props.topOffset}>
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? [`slideFrom${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes] : ['none'],
            duration: animationDuration,
            delay: animationDuration,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? [`slideTo${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes] : ['none'],
            duration: animationDuration,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.Container>
            <SimpleGrid layout={this.columnWidths(this.props.size)} verticalAlign='stretch' justify={this.props.side} spacing={0}>
              <Styled.SideTray side={this.props.side}>
                <FocusLock shards={this.props.focusEnabledRefs}>
                  <If condition={this.props.isClosable}>
                    <Styled.CloseButton aria-label={this.props.srName} animationDuration={animationDuration} side={this.props.side}>
                      <IconButton
                        icon={{ name: 'close' }}
                        appearance='secondary'
                        size={4}
                        onClick={this.getOnCloseHandler(OverlayCloseSource.SIDETRAY_CROSS_CLICK)}
                      />
                    </Styled.CloseButton>
                  </If>
                  {this.props.children}
                </FocusLock>
              </Styled.SideTray>
            </SimpleGrid>
          </Styled.Container>
        </Animate>

        <If condition={this.props.enableOverlay}>
          <Animate
            show={this.state.show}
            enter={{
              animations: this.props.animate ? ['fadeIn'] : ['none'],
              duration: this.props.animate ? 400 : 0,
            }}
            exit={{
              animations: this.props.animate ? ['fadeOut'] : ['none'],
              duration: animationDuration,
            }}
          >
            <Overlay
              show
              onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.SIDETRAY_OUTSIDE_CLICK) : undefined}
              position='fixed'
              zIndex={0}
              blur={this.props.overlayBlur}
            />
          </Animate>
        </If>
      </Styled.SideTrayRenderer>,
      document.body,
    )
  }
}

export default withTheme(SideTrayRenderer)
