import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledTabProps } from './Tab.style.types'

export const TabContainer = styled.div(
  (props: StyledTabProps): FlattenSimpleInterpolation => css`
    > div:first-child {
      &:focus {
        outline: 0 none !important;
      }
    }
  `,
)
