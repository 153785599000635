/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing, srOnly, typography } from '@vfuk/core-mixins'

import { StyledFieldWrapperProps } from './FieldWrapper.style.types'

export const FieldWrapper = styled.div(
  (props: Pick<StyledFieldWrapperProps, 'width'>): FlattenSimpleInterpolation => css`
    width: 100%;
    min-width: 90px;
    max-width: 300px;

    ${respondTo.md(css`
      max-width: 700px;
      ${props.width === 'full' &&
      css`
        max-width: 100%;
      `}
    `)}

    ${props.width === 'auto' &&
    css`
      display: inline-block;
      width: auto;
    `}

  ${props.width === 'full' &&
    css`
      max-width: 100%;
    `}
  `,
)

type StyledLabelProps = Pick<StyledFieldWrapperProps, 'state' | 'showLabel' | 'isTypePassword' | 'theme'>
export const Label = styled.label<StyledLabelProps>((props: StyledLabelProps): FlattenSimpleInterpolation => {
  return css`
    display: block;
    color: ${props.theme.color.monochrome6.default};

    ${props.state === 'disabled' &&
    css`
      color: ${props.theme.color.monochrome6.disabled};
    `}

    ${typography.heading(1, false)};

    font-weight: ${props.theme.fontWeight[2]};

    ${!props.showLabel &&
    css`
      ${srOnly()};
    `}
  `
})

type StyledSubTextProps = Pick<StyledFieldWrapperProps, 'theme' | 'state'>
export const SubText = styled.div<StyledSubTextProps>((props: StyledSubTextProps): FlattenSimpleInterpolation => {
  return css`
    ${typography.paragraph(1, false)};

    ${props.state === 'disabled' &&
    css`
      color: ${props.theme.color.monochrome6.disabled};
    `}
  `
})

export const InputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const OptionalOrRequiredLabel = styled.div((): FlattenSimpleInterpolation => {
  return css`
    ${typography.paragraph(1, false)}
  `
})

type StyledFlexIndicatorContainerProps = Pick<StyledFieldWrapperProps, 'showLabel'>
export const FlexIndicatorContainer = styled.div<StyledFlexIndicatorContainerProps>(
  (props: StyledFlexIndicatorContainerProps): FlattenSimpleInterpolation => {
    return css`
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;

      ${!props.showLabel &&
      css`
        display: block;
        ${srOnly()};
      `}
    `
  },
)

type StyledTopLabelWrapperProps = Pick<StyledFieldWrapperProps, 'showLabel'>
export const TopLabelWrapper = styled.div<StyledTopLabelWrapperProps>((props: StyledTopLabelWrapperProps): FlattenSimpleInterpolation => {
  return css`
    ${props.showLabel &&
    css`
      ${spacing('margin-bottom', 2)};
    `}
  `
})
