import React, { ReactElement, FC } from 'react'

import * as Styled from './styles/Block.style'

import { BlockProps } from './Block.types'

const Block: FC<BlockProps> = ({
  className,
  children,
  appearance = 'primary',
  paddingLevel = 1,
  removeBottomPadding = false,
}: BlockProps): ReactElement => {
  return (
    <Styled.Block className={className} appearance={appearance} paddingLevel={paddingLevel} removeBottomPadding={removeBottomPadding}>
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
    </Styled.Block>
  )
}

export default Block
