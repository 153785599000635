import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import { withTheme } from 'styled-components'

import * as Styled from './styles/Checkbox.style'
import localThemes from './themes/CheckBox.theme'

import { CheckboxProps } from './Checkbox.types'

export class Checkbox extends PureComponent<CheckboxProps> {
  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onChange) {
      this.props.onChange(event)
    }
  }

  public render(): ReactNode {
    const localTheme = localThemes(this.props.theme!)

    return (
      <Styled.CheckboxLabel checked={this.props.checked} state={this.props.state} id={this.props.id}>
        <input
          type='checkbox'
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
          aria-invalid={this.props.state === 'error'}
          required={this.props.required}
          name={this.props.name}
          onChange={this.handleOnChange}
          onBlur={this.props.onBlur}
          checked={this.props.state === 'disabled' ? this.props.checked : this.props.indeterminate || this.props.checked}
          value={this.props.value}
          {...this.props.dataAttributes}
        />
        <Styled.Checkbox indeterminate={!!this.props.indeterminate} state={this.props.state}>
          <Choose>
            <When condition={this.props.indeterminate}>
              <Icon group={localTheme.iconGroup} inverse name={localTheme.indeterminateIconName} size={1} />
            </When>
            <Otherwise>
              <Icon group={localTheme.iconGroup} inverse name={localTheme.iconName} size={1} />
            </Otherwise>
          </Choose>
        </Styled.Checkbox>
        {this.props.children}
      </Styled.CheckboxLabel>
    )
  }
}

export default withTheme(Checkbox)
