import React, { PureComponent, ReactNode } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'
import CalendarPicker from '@vfuk/core-calendar-picker'

import errorMessages from '@vfuk/core-date-text-input/dist/constants/errorMessages'

import { CalendarPickerWithLabelProps, CalendarPickerWithLabelState } from './CalendarPickerWithLabel.types'

export default class CalendarPickerWithLabel extends PureComponent<CalendarPickerWithLabelProps> {
  public state: CalendarPickerWithLabelState = {
    isDateValid: true,
    errorMessages: [],
  }

  private handleInvalidInputEntry = (errors: errorMessages[]): void => {
    this.setState({
      isDateValid: !errors.length,
      errorMessages: errors,
    })
    this.props.calendarPicker.onInvalidInputEntry(errors)
  }

  private handleOnChange = (value: Date | null, dateString: string): void => {
    this.setState({ isDateValid: !!value })
    this.props.calendarPicker.onChange(value, dateString)
  }

  private handleStateText(): string[] | undefined {
    const stateTextArray: string[] = this.state.isDateValid ? [] : [...this.state.errorMessages]

    if (this.props.fieldWrapper.stateText) {
      if (Array.isArray(this.props.fieldWrapper.stateText)) {
        stateTextArray.push(...this.props.fieldWrapper.stateText)
      } else {
        stateTextArray.push(this.props.fieldWrapper.stateText)
      }
    }

    if (!stateTextArray.length) {
      return undefined
    }
    return stateTextArray
  }

  private handleFieldWrapperState(): CalendarPickerWithLabelProps['state'] | undefined {
    if (this.props.state === 'disabled') return this.props.state

    // If custom errorMessages is set to null and a state has not been passed in, do not set the state prop (will show default state)
    if (this.props.calendarPicker.errorMessages === null && !this.props.state) return undefined

    // If custom errorMessages is set to null and a state has been passed in, set the state prop
    if (this.props.calendarPicker.errorMessages === null && this.props.state) return this.props.state

    // When the date is valid, return the state provided
    if (this.state.isDateValid) return this.props.state

    // When the date is invalid, return error state
    return 'error'
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        helpText={this.props.fieldWrapper.helpText}
        label={this.props.fieldWrapper.label}
        validateOn={this.props.fieldWrapper.validateOn}
        state={this.handleFieldWrapperState()}
        subText={this.props.fieldWrapper.subText}
        stateText={this.handleStateText()}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        renderAsLabel={false}
        formElementId={this.props.calendarPicker.id}
      >
        <CalendarPicker
          value={this.props.calendarPicker.value}
          id={this.props.calendarPicker.id}
          name={this.props.calendarPicker.name}
          onChange={this.handleOnChange}
          onBlur={this.props.calendarPicker.onBlur}
          onCalendarOpen={this.props.calendarPicker.onCalendarOpen}
          onCalendarClose={this.props.calendarPicker.onCalendarClose}
          required={this.props.calendarPicker.required}
          autoComplete={this.props.calendarPicker.autoComplete}
          minDate={this.props.calendarPicker.minDate}
          maxDate={this.props.calendarPicker.maxDate}
          includeDates={this.props.calendarPicker.includeDates}
          excludeDates={this.props.calendarPicker.excludeDates}
          filterDate={this.props.calendarPicker.filterDate}
          showMonthYearPicker={this.props.calendarPicker.showMonthYearPicker}
          openToDate={this.props.calendarPicker.openToDate}
          shouldCloseOnSelect={this.props.calendarPicker.shouldCloseOnSelect}
          onInvalidInputEntry={this.handleInvalidInputEntry}
          state={this.props.state}
          errorMessages={this.props.calendarPicker.errorMessages}
          popupPosition={this.props.calendarPicker.popupPosition}
          labels={this.props.calendarPicker.labels}
        />
      </FieldWrapper>
    )
  }
}
