import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Heading } from '@vfuk/core-heading/dist/styles/Heading.style'
import { ContainerContent } from '@vfuk/core-container/dist/styles/Container.style'
import { backgroundImage, spacing } from '@vfuk/core-mixins'

import { StyledIconAndImageCardProps } from './IconAndImageCard.style.types'

export const ContainerHolder = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex: 1;

    ${ContainerContent} {
      display: flex;
      flex-direction: column;
    }
  `,
)

export const Content = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    align-items: self-start;
    ${spacing('padding', 3)};
  `,
)

export const TextContent = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 3)};
  `,
)

export const BackgroundImage = styled.div(
  (props: StyledIconAndImageCardProps): FlattenSimpleInterpolation => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props.hasButton ? '197px' : '257px'};
    ${backgroundImage(props.backgroundImage)};
    ${spacing('margin-bottom', 7)};

    ${Heading} {
      color: ${props.theme.color.monochrome1.default};
      z-index: 1;
    }

    ${props.state === 'disabled' &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${props.theme.color.opacity5.default};
      }
    `}
  `,
)

export const IconWithBackgroundWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(50%);
    z-index: 1;
    ${spacing('margin-left', 3)}
  `,
)
