import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import { StyledSnippetProps } from './Snippet.style.types'

type SnippetProps = Pick<StyledSnippetProps, 'theme' | 'justify' | 'inverse'>
export const Snippet = styled.div(
  (props: SnippetProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
    height: 100%;

    ${props.inverse &&
    css`
      color: ${props.theme.color.monochrome1.default};
    `}
  `,
)

type SnippetTextWrapperProps = Pick<StyledSnippetProps, 'hasButton'>
export const SnippetTextWrapper = styled.div(
  (props: SnippetTextWrapperProps): FlattenSimpleInterpolation => css`
    ${props.hasButton &&
    css`
      ${spacing('margin-bottom', 3)}
    `}
  `,
)
