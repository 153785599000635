import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo } from '@vfuk/core-mixins'

import { StyledContentVideoProps } from './ContentVideo.types'

export const VideoWrapper = styled.div<StyledContentVideoProps>(
  (props): FlattenSimpleInterpolation => css`
    align-self: stretch;
    width: auto;

    ${props.videoPosition === 'center' &&
    css`
      ${advancedSpacing('margin', [0, -4])}
    `}

    ${respondTo.lg(css`
      align-self: center;
      max-width: 700px;
      width: 100%;

      ${(props.videoPosition === 'left' || props.videoPosition === 'right') &&
      css`
        width: 50%;
      `}
    `)}
  `,
)
