import React, { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'
import { TinyColor } from '@ctrl/tinycolor'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/ColorSwatch.style'
import localThemes from './themes/ColorSwatch.theme'

import { ColorSwatchProps } from './ColorSwatch.types'

export const ColorSwatch: FC<ColorSwatchProps> = ({
  color,
  size = 1,
  available = true,
  inverse = false,
  theme,
}: ColorSwatchProps): ReactElement => {
  const localTheme = localThemes(theme)
  const tinyColor = new TinyColor(color)
  const lowContrast = inverse ? tinyColor.isDark() : tinyColor.isLight()

  return (
    <Styled.ColorSwatch color={color} size={size!} lowContrast={lowContrast} inverse={inverse}>
      <If condition={!available}>
        <Icon name='close' size={localTheme.size[size].icon} inverse={inverse ? lowContrast : !lowContrast} />
      </If>
    </Styled.ColorSwatch>
  )
}

export default withTheme(ColorSwatch)
