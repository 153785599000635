import React from 'react'

import * as Styled from './styles/UnitInput.style'

import { UnitInputProps } from './UnitInput.types'

const UnitInput: React.FC<UnitInputProps> = ({
  type,
  value,
  onChange,
  id,
  name,
  onKeyDown,
  onBlur,
  onClick,
  required,
  disabled,
  inputLength,
  autoComplete = true,
  describedBy,
  labels,
}: UnitInputProps) => {
  const inputId = `${id}-${type}`
  const placeholders = {
    day: 'DD',
    month: 'MM',
    year: 'YYYY',
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (onClick) onClick()
    event.stopPropagation()
  }
  return (
    <Styled.UnitInputContainer showDelimeter={type !== 'year'}>
      <Styled.Label htmlFor={inputId}>{labels && labels[type]}</Styled.Label>
      <Styled.UnitInput
        unitType={type}
        id={inputId}
        name={inputId}
        type='text'
        maxLength={inputLength}
        size={inputLength}
        onChange={onChange}
        value={value}
        placeholder={placeholders[type]}
        onKeyDown={onKeyDown}
        onClick={handleClick}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        aria-disabled={disabled}
        aria-describedby={describedBy}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
    </Styled.UnitInputContainer>
  )
}

export default UnitInput
