import React, { FC, ReactElement } from 'react'
import { withTheme } from 'styled-components'

import Container from '@vfuk/core-container'
import CardHeader from '@vfuk/core-card-header'
import CardBody from '@vfuk/core-card-body'

import * as Styled from './styles/IconCard.style'

import { IconCardProps } from './IconCard.types'

import localTheme from './themes/IconCard.theme'

export const IconCard: FC<IconCardProps> = ({
  appearance = 'primary',
  level = 1,
  elevation,
  pill,
  icon,
  marketingShout,
  headingText,
  text,
  primaryButton,
  secondaryButton,
}: IconCardProps): ReactElement => {
  const iconCardAppearance = localTheme().appearance[appearance!]
  return (
    <Container appearance={iconCardAppearance.containerAppearance} elevation={elevation} paddingLevel={0}>
      <Styled.IconCard>
        <CardHeader
          pill={pill}
          icon={{
            ...icon,
            appearance: iconCardAppearance.level[level!].iconAppearance,
            inverse: iconCardAppearance.level[level!].inverseIcon,
          }}
          marketingShout={marketingShout}
        />
        <CardBody
          heading={{
            text: headingText,
            size: 2,
            level: 2,
            inverse: iconCardAppearance.level[level!].inverseText,
          }}
          paragraph={{
            text,
            inverse: iconCardAppearance.level[level!].inverseText,
          }}
          primaryButton={{
            ...primaryButton!,
            appearance: iconCardAppearance.level[level!].primaryButtonAppearance,
            inverse: iconCardAppearance.level[level!].inverseButton,
          }}
          secondaryButton={{
            ...secondaryButton!,
            appearance: iconCardAppearance.level[level!].secondaryButtonAppearance,
            inverse: iconCardAppearance.level[level!].inverseButton,
          }}
          centerButtons
        />
      </Styled.IconCard>
    </Container>
  )
}

export default withTheme(IconCard)
