import React, { PureComponent, ReactNode } from 'react'

import { withTheme } from 'styled-components'

import IconWithBackground from '@vfuk/core-icon-with-background'

import Span from '@vfuk/core-span'

import generateRandomColor from '@vfuk/core-helpers/dist/generateRandomColor'

import * as Styled from './styles/HorizontalBarChart.style'

import { HorizontalBarChartProps, HorizontalBarChartState } from './HorizontalBarChart.types'

import colourMap from './utils/colourMap'

import localThemes from './themes/HorizontalBarChart.theme'

import { HorizontalBarChartTheme, StateKeys } from './themes/HorizontalBarChart.theme.types'

export class HorizontalBarChart extends PureComponent<HorizontalBarChartProps, HorizontalBarChartState> {
  public static defaultProps: Partial<HorizontalBarChartProps> = {
    inverse: false,
    showLabels: true,
  }

  state: HorizontalBarChartState = {
    HorizontalBars: [],
  }

  componentDidMount(): void {
    this.getHorizontalBars()
  }

  getStepColor(index: number, localTheme: HorizontalBarChartTheme): string {
    if (this.props.inverse) return localTheme.state[this.props.steps[index].state as StateKeys].inverse.backgroundColor
    return localTheme.state[this.props.steps[index].state as StateKeys].backgroundColor
  }

  getHorizontalBars(): void {
    const localTheme = localThemes(this.props.theme!)
    const colors = colourMap(localTheme)
    const predefinedColors = this.props.inverse ? colors.inversedColors : colors.colors
    const stepsWithColors = this.props.steps.map((step, index) => {
      let color = ''
      if (step.state) {
        color = this.getStepColor(index, localTheme)
      } else if (predefinedColors[index]) {
        color = predefinedColors[index]
      } else {
        color = generateRandomColor()
      }
      return {
        ...step,
        color,
      }
    })
    this.setState({ HorizontalBars: stepsWithColors })
  }

  public render(): ReactNode {
    return (
      <Styled.HorizontalBarChartContainer inverse={this.props.inverse} topText={this.props.topText!} showLabels={this.props.showLabels}>
        <If condition={this.props.icon?.name}>
          <Styled.IconWithBackgroundWrapper topText={this.props.topText!} showLabels={this.props.showLabels}>
            <IconWithBackground icon={this.props.icon!} shape='square' size={3} appearance='alt3' />
          </Styled.IconWithBackgroundWrapper>
        </If>

        <Styled.HorizontalBarChartSection topText={this.props.topText!} showLabels={this.props.showLabels}>
          <Styled.TopText topText={this.props.topText!}>
            <Span inverse={this.props.inverse}>{this.props.topText?.left}</Span>
            <Span inverse={this.props.inverse}>{this.props.topText?.right}</Span>
          </Styled.TopText>

          <Styled.HorizontalBar inverse={this.props.inverse}>
            {this.state.HorizontalBars?.map((step, index) => (
              <Styled.HorizontalBarChart
                key={index}
                color={step.color!}
                inverse={this.props.inverse}
                width={step.percentage}
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={step.percentage}
                aria-valuetext={step.value}
                title={this.props.srText}
                role='progressbar'
              />
            ))}
          </Styled.HorizontalBar>

          <If condition={this.props.showLabels}>
            <Styled.Legends inverse={this.props.inverse} topText={this.props.topText} icon={this.props.icon}>
              {this.state.HorizontalBars?.map((step, index) => (
                <Styled.Legend key={index}>
                  <Styled.LegendColor color={step.color!}></Styled.LegendColor>
                  <Styled.LegendKey>
                    {step.key}
                    <If condition={step.key && step.value}>: </If>
                  </Styled.LegendKey>
                  {step.value}
                </Styled.Legend>
              ))}
            </Styled.Legends>
          </If>
        </Styled.HorizontalBarChartSection>
      </Styled.HorizontalBarChartContainer>
    )
  }
}

export default withTheme(HorizontalBarChart)
