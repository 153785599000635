import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CONFIG_REQUEST,
  CONFIG_REQUEST_FAIL,
  CONFIG_REQUEST_SUCCESS,
  SET_PAGE_CONFIG
} from 'actions/configActions';
import configService from 'services/ConfigService';

function* configWorker() {
  const configItemJSON = localStorage.getItem('config');
  const configItem = configItemJSON && JSON.parse(configItemJSON);
  if (
    configItem &&
    Date.now() < configItem.fetchDate + configItem.global.TTL * 1000
  ) {
    console.log('Fresh Config found, no call to backend');
    console.log(configItem);
    yield put({
      type: CONFIG_REQUEST_SUCCESS,
      payload: configItem
    });
    if (Object.keys(configItem.pages).length) {
      yield put({
        type: SET_PAGE_CONFIG,
        payload: configItem.pages
      });
    }
  } else {
    console.log('Fresh Config NOT found, making call to backend');
    localStorage.removeItem('config');
    try {
      const data = yield call(configService.fetchConfig);
      yield put({
        type: CONFIG_REQUEST_SUCCESS,
        payload: data
      });
      if (Object.keys(data.pages).length) {
        yield put({
          type: SET_PAGE_CONFIG,
          payload: data.pages
        });
      }
    } catch (error) {
      yield put({ type: CONFIG_REQUEST_FAIL, error });
    }
  }
  /*
  try {
    const data = yield call(configService.fetchConfig, payload);
    yield put({
      type: CONFIG_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CONFIG_REQUEST_FAIL, error });
  }
*/
}

function* configWatcher() {
  yield takeLatest(CONFIG_REQUEST, configWorker);
}

const configSaga = [configWatcher()];

export default configSaga;
