import { SandwichTheme } from './Sandwich.theme.types'

const sandwichLocal: SandwichTheme = {
  appearance: {
    primary: {
      level: {
        1: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt2',
          suffix2Appearance: 'alt2',
          mainTextWeight: 1,
          currencyWeight: 1,
          signWeight: 1,
          subTextWeight: 3,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
        2: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt2',
          suffix2Appearance: 'alt2',
          mainTextWeight: 3,
          currencyWeight: 3,
          signWeight: 3,
          subTextWeight: 2,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
      },
    },
    secondary: {
      level: {
        1: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'secondary',
          suffix2Appearance: 'alt2',
          mainTextWeight: 1,
          currencyWeight: 1,
          signWeight: 1,
          subTextWeight: 3,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
        2: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'secondary',
          suffix2Appearance: 'alt2',
          mainTextWeight: 3,
          currencyWeight: 3,
          signWeight: 3,
          subTextWeight: 2,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
      },
    },
    alt1: {
      level: {
        1: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt1',
          suffix2Appearance: 'alt2',
          mainTextWeight: 1,
          currencyWeight: 1,
          signWeight: 1,
          subTextWeight: 3,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
        2: {
          mainAppearance: 'primary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt1',
          suffix2Appearance: 'alt2',
          mainTextWeight: 3,
          currencyWeight: 3,
          signWeight: 3,
          subTextWeight: 2,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
      },
    },
    alt2: {
      level: {
        1: {
          mainAppearance: 'secondary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt2',
          suffix2Appearance: 'alt2',
          mainTextWeight: 1,
          currencyWeight: 1,
          signWeight: 1,
          subTextWeight: 3,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
        2: {
          mainAppearance: 'secondary',
          mainSuffixAppearance: 'alt2',
          prefixAppearance: 'alt2',
          suffixAppearance: 'alt2',
          suffix2Appearance: 'alt2',
          mainTextWeight: 3,
          currencyWeight: 3,
          signWeight: 3,
          subTextWeight: 2,
          inverse: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'primary',
            prefixAppearance: 'primary',
            suffixAppearance: 'primary',
            suffix2Appearance: 'primary',
          },
        },
      },
    },
  },
  size: {
    supportingText: {
      1: {
        element: 'paragraph',
        size: 1,
      },
      2: {
        element: 'paragraph',
        size: 2,
      },
      3: {
        element: 'paragraph',
        size: 2,
      },
      4: {
        element: 'heading',
        size: 1,
      },
      5: {
        element: 'heading',
        size: 2,
      },
    },
    currency: {
      1: {
        element: 'paragraph',
        size: 1,
      },
      2: {
        element: 'heading',
        size: 1,
      },
      3: {
        element: 'heading',
        size: 2,
      },
      4: {
        element: 'heading',
        size: 2,
      },
      5: {
        element: 'heading',
        size: 3,
      },
    },
    sign: {
      1: {
        element: 'paragraph',
        size: 2,
      },
      2: {
        element: 'heading',
        size: 1,
      },
      3: {
        element: 'heading',
        size: 2,
      },
      4: {
        element: 'heading',
        size: 2,
      },
      5: {
        element: 'heading',
        size: 3,
      },
    },
  },
}

export default sandwichLocal
