import { css, FlattenSimpleInterpolation } from 'styled-components'

import createMixin from '@vfuk/core-mixins/dist/utils/createMixin'
import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { MarketingShoutTheme } from './MarketingShout.theme.types'

import { MarketingShoutProps } from '../MarketingShout.types'
import { CornerStyleMixin } from '../mixins/cornerStyle.types'

const marketingShoutTheme = (ws10Theme: Theme): MarketingShoutTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: ws10Theme.color.secondary6.default,
        textColor: ws10Theme.color.monochrome1.default,
      },
    },
    cornerStyle: createMixin(
      (theme: Theme, align: MarketingShoutProps['align'], backgroundColor: string): FlattenSimpleInterpolation =>
        css`
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          ${align === 'left' &&
          css`
            padding-right: 32px;
            &:after {
              border-right: 32px solid ${backgroundColor};
              border-radius: 32px 0px;
            }
          `}

          ${align === 'right' &&
          css`
            padding-left: 32px;
            &:after {
              border-left: 32px solid ${backgroundColor};
              border-radius: 0px 32px;
            }
          `}
        `,
    ) as CornerStyleMixin,
  }
}

export default marketingShoutTheme
