import { DateTextInputTheme } from './DateTextInput.theme.types'

const dateTextInputLocal: DateTextInputTheme = {
  yearInputWidth: {
    sm: '68px',
    mdAndAbove: '80px',
  },
}

export default dateTextInputLocal
