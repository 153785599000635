import React, { ReactElement, FC } from 'react'

import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import LinkWithIcon from '@vfuk/core-link-with-icon'

import * as Styled from './styles/ColumnModalTemplate.style'

import { ColumnModalTemplateProps } from './ColumnModalTemplate.types'

const ColumnModalTemplate: FC<ColumnModalTemplateProps> = ({
  heading,
  leftContent,
  rightContent,
  headerContent,
  separatorText,
  text,
  links,
}: ColumnModalTemplateProps): ReactElement => {
  const hasBottomContent = Boolean(text)

  return (
    <Styled.ColumnModalTemplate>
      <Heading text={heading.text} level={heading.level ? heading.level : 1} justify='center' size={3} />
      <If condition={headerContent}>
        <Styled.HeaderContentContainer>{headerContent}</Styled.HeaderContentContainer>
      </If>
      <Styled.ColumnsContainer>
        <Styled.Column withoutMarginBottom={!hasBottomContent} oneColumn={!rightContent}>
          {leftContent}
        </Styled.Column>
        <If condition={rightContent}>
          <If condition={separatorText}>
            <Styled.SeparatorTextContainer withoutMarginBottom={!hasBottomContent}>
              <Paragraph>{separatorText}</Paragraph>
            </Styled.SeparatorTextContainer>
          </If>
          <Styled.Column withoutMarginBottom={!hasBottomContent} oneColumn={!rightContent}>
            {rightContent}
          </Styled.Column>
        </If>
      </Styled.ColumnsContainer>
      <If condition={text}>
        <Styled.BodyTextContainer>
          <Paragraph justify='center'>{text}</Paragraph>
        </Styled.BodyTextContainer>
      </If>
      <If condition={links?.length}>
        <Styled.LinksContainer>
          {links!.map((link) => {
            return (
              <Styled.LinkWrapper key={link.id || link.text.toLowerCase().replace(' ', '-')}>
                <LinkWithIcon icon={{ name: 'chevron-right' }} {...link} />
              </Styled.LinkWrapper>
            )
          })}
        </Styled.LinksContainer>
      </If>
    </Styled.ColumnModalTemplate>
  )
}

export default ColumnModalTemplate
