import React, { Fragment, ReactElement, FC } from 'react'

import * as Styled from './styles/HelpText.style'

import { HelpTextProps } from './HelpText.types'

const HelpText: FC<HelpTextProps> = ({ helpText, formElementId, helpLink, state }: HelpTextProps): ReactElement => {
  const helpTextId = `${formElementId}-help-text`

  return (
    <Fragment>
      <Styled.HelpList>
        <Choose>
          <When condition={Array.isArray(helpText)}>
            {(helpText as string[]).map((text, index) => {
              return (
                <Styled.HelpText id={`${helpTextId}-${index}`} key={index} state={state}>
                  {text}
                </Styled.HelpText>
              )
            })}
          </When>
          <Otherwise>
            <Styled.HelpText state={state} id={helpTextId}>
              {helpText}
            </Styled.HelpText>
          </Otherwise>
        </Choose>

        <If condition={helpLink}>
          <Styled.HelpText
            state={state}
            href={helpLink!.href}
            customRouterProps={helpLink!.customRouterProps}
            onClick={helpLink!.onClick}
            onMouseDown={helpLink!.onMouseDown}
            onMouseUp={helpLink!.onMouseUp}
            onTouchStart={helpLink!.onTouchStart}
            onTouchEnd={helpLink!.onTouchEnd}
            htmlAttributes={helpLink!.htmlAttributes}
          >
            {helpLink!.text}
          </Styled.HelpText>
        </If>
      </Styled.HelpList>
    </Fragment>
  )
}

export default HelpText
