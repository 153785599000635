import React, { ReactElement } from 'react'
import isEmpty from 'lodash/isEmpty'

import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import Button from '@vfuk/core-button'
import Link from '@vfuk/core-link'

import * as Styled from './styles/Snippet.style'

import { SnippetProps } from './Snippet.types'

export default ({
  headingText,
  text,
  appearance = 'primary',
  inverse = false,
  justify = 'left',
  button,
  link,
}: SnippetProps): ReactElement => {
  return (
    <Styled.Snippet inverse={inverse} justify={justify}>
      <Styled.SnippetTextWrapper hasButton={!isEmpty(button)}>
        <If condition={headingText}>
          <Heading size={1} inverse={inverse} level={2} weight={3} text={headingText} noMargin />
        </If>
        <Paragraph inverse={inverse}>{text}</Paragraph>
      </Styled.SnippetTextWrapper>
      <If condition={!isEmpty(button)}>
        <Button
          appearance={appearance}
          inverse={inverse}
          text={button!.text}
          onClick={button?.onClick}
          onMouseDown={button?.onMouseDown}
          onMouseUp={button?.onMouseUp}
          onTouchStart={button?.onTouchStart}
          onTouchEnd={button?.onTouchEnd}
          href={button?.href}
          htmlAttributes={button?.htmlAttributes}
          customRouterProps={button?.customRouterProps}
        />
      </If>
      <If condition={isEmpty(button) && !isEmpty(link)}>
        <Link
          showUnderline
          inverse={inverse}
          text={link!.text}
          onClick={link?.onClick}
          onMouseDown={link?.onMouseDown}
          onMouseUp={link?.onMouseUp}
          onTouchStart={link?.onTouchStart}
          onTouchEnd={link?.onTouchEnd}
          href={link?.href}
          htmlAttributes={link?.htmlAttributes}
          customRouterProps={link?.customRouterProps}
        />
      </If>
    </Styled.Snippet>
  )
}
