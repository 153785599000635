const getMaxValue = (array: number[]): number[] => {
  return array?.map((heights) => (Array.isArray(heights) ? Math.max(...heights) : 0))
}

const getMaxHeight = (refArray: number[][][]): number[][] => {
  const maxHeightArray: number[][] = []
  for (let refIndex = 0; refIndex < refArray.length; refIndex++) {
    for (let secIndex = 0; secIndex < refArray[refIndex].length; secIndex++) {
      const maxHeight = getMaxValue(refArray[refIndex][secIndex])
      if (maxHeight) maxHeightArray.push(maxHeight)
    }
  }
  return maxHeightArray
}

export default getMaxHeight
