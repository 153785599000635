import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { RadioTheme } from './Radio.theme.types'

const radioLocal = (theme: Theme): RadioTheme => {
  return {
    borderColor: theme.color.monochrome3.default,
    checked: {
      backgroundColor: theme.color.secondary1.default,
      borderColor: theme.color.secondary1.default,
    },
    state: {
      disabled: {
        color: theme.color.monochrome5.disabled,
        backgroundColor: theme.color.monochrome2.default,
        borderColor: theme.color.monochrome5.disabled,
        checked: {
          backgroundColor: theme.color.monochrome3.default,
        },
      },
    },
  }
}

export default radioLocal
