import React, { PureComponent, ReactNode } from 'react'

import FunctionalCarousel from '@vfuk/core-functional-carousel'

import { CardCarouselProps } from './CardCarousel.types'

export default class CardCarousel extends PureComponent<CardCarouselProps> {
  public static defaultProps: Partial<CardCarouselProps> = {
    arrows: {
      position: 'overlap',
    },
    pager: {
      inverse: false,
    },
  }

  public render(): ReactNode {
    return (
      <FunctionalCarousel
        slidesToShow={this.props.slidesToShow}
        slidesToScroll={this.props.slidesToScroll}
        id={this.props.id}
        srName={this.props.srName}
        arrows={{
          position: this.props.arrows!.position,
          onClick: this.props.arrows!.onClick,
        }}
        pager={{
          position: 'outside',
          inverse: this.props.pager!.inverse,
          onClick: this.props.pager!.onClick,
        }}
        infiniteLoop={this.props.infiniteLoop}
        allowOverflow={this.props.allowOverflow}
        elevatedCards={this.props.elevatedCards}
      >
        {this.props.children}
      </FunctionalCarousel>
    )
  }
}
