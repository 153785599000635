/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import localThemes from '@vfuk/core-button/dist/themes/Button.theme'
import { advancedSpacing, iconAppearance, respondTo, spacing } from '@vfuk/core-mixins'

import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { LoadingSpinner } from '@vfuk/core-loading-spinner/dist/styles/LoadingSpinner.style'

import { StyledButtonWithIconProps } from './ButtonWithIcon.style.types'

export const ButtonWithIcon = styled(Interaction)((props: StyledButtonWithIconProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: 0.4s all ease;
    line-height: 12px;
    width: 100%;
    height: 44px;
    min-width: 152px;
    ${props.icon.justify === 'right' &&
    css`
      flex-direction: row-reverse;
    `}

    font-weight: ${props.theme.fontWeight[2]};
    color: ${localTheme.appearance[props.appearance].color};
    background: ${localTheme.appearance[props.appearance].backgroundColor};
    border-radius: ${localTheme.borderRadius};
    ${advancedSpacing('padding', [3, 5, 3, 5])};

    ${Icon} {
      transition: 0.4s all ease;
    }

    border: ${props.theme.border.style[1]} ${localTheme.borderWidth} ${localTheme.appearance[props.appearance].borderColor};

    ${respondTo.md(css`
      ${props.width === 'auto' &&
      css`
        width: auto;
      `}
    `)}

    ${props.state &&
    css`
      opacity: ${localTheme.appearance[props.appearance].state[props.state].opacity};
      color: ${localTheme.appearance[props.appearance].state[props.state].color};
      background: ${localTheme.appearance[props.appearance].state[props.state].backgroundColor};

      border: ${props.theme.border.style[1]} ${localTheme.borderWidth}
        ${localTheme.appearance[props.appearance].state[props.state].borderColor};
    `}

    ${props.state === 'disabled' &&
    css`
      cursor: default;

      ${Icon} {
        svg {
          ${iconAppearance(localTheme.appearance[props.appearance].state[props.state].color)};
        }
      }

      ${LoadingSpinner} {
        svg {
          & > * {
            stroke: ${localTheme.appearance[props.appearance].state[props.state].color};
          }
        }
      }
    `}

    ${!props.loading &&
    css`
      ${props.icon.justify !== 'right' &&
      css`
        ${Icon} {
          ${spacing('margin-right', 2)}
        }
      `}

      ${props.icon.justify === 'right' &&
      css`
        ${Icon} {
          ${spacing('margin-left', 2)}
        }
      `}
    `}

    ${props.state !== 'disabled' &&
    css`
      &:hover {
        color: ${localTheme.appearance[props.appearance].hover.color};
        background: ${localTheme.appearance[props.appearance].hover.backgroundColor};
        border-color: ${localTheme.appearance[props.appearance].hover.borderColor};

        ${Icon} {
          * {
            ${iconAppearance(localTheme.appearance[props.appearance].hover.color)};
          }
        }

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance].hover.color};
            }
          }
        }

        ${props.state &&
        css`
          color: ${localTheme.appearance[props.appearance].state[props.state].hover.color};
          background: ${localTheme.appearance[props.appearance].state[props.state].hover.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance].state[props.state].hover.borderColor};

          ${Icon} {
            * {
              ${iconAppearance(localTheme.appearance[props.appearance].state[props.state].hover.color)};
            }
          }

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance].state[props.state].hover.color};
              }
            }
          }
        `}
      }
    `}

    ${props.state !== 'disabled' &&
    localTheme.appearance[props.appearance].pressed &&
    css`
      &:active {
        background: ${localTheme.appearance[props.appearance].pressed?.backgroundColor};
        border-color: ${localTheme.appearance[props.appearance].pressed?.borderColor};
        color: ${localTheme.appearance[props.appearance].pressed?.color};

        ${Icon} {
          * {
            ${iconAppearance(localTheme.appearance[props.appearance].pressed!.color)};
          }
        }

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance].pressed?.color};
            }
          }
        }

        ${props.state &&
        css`
          background: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.borderColor};
          color: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.color};
        `}
      }
    `}

    ${props.inverse &&
    css`
      color: ${localTheme.appearance[props.appearance].inverse.color};
      background: ${localTheme.appearance[props.appearance].inverse.backgroundColor};
      border-color: ${localTheme.appearance[props.appearance].inverse.borderColor};

      ${props.state &&
      css`
        opacity: ${localTheme.appearance[props.appearance].inverse.state[props.state].opacity};
        color: ${localTheme.appearance[props.appearance].inverse.state[props.state].color};
        background: ${localTheme.appearance[props.appearance].inverse.state[props.state].backgroundColor};
        border-color: ${localTheme.appearance[props.appearance].inverse.state[props.state].borderColor};
      `}

      ${props.state === 'disabled' &&
      css`
        cursor: default;

        ${Icon} {
          svg {
            ${iconAppearance(localTheme.appearance[props.appearance].inverse.state[props.state].color)};
          }
        }

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance].inverse.state[props.state].color};
            }
          }
        }
      `}

      ${props.state !== 'disabled' &&
      css`
        &:hover {
          color: ${localTheme.appearance[props.appearance].inverse.hover.color};
          background: ${localTheme.appearance[props.appearance].inverse.hover.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance].inverse.hover.borderColor};

          ${props.state &&
          css`
            color: ${localTheme.appearance[props.appearance].state[props.state].hover.color};
            background: ${localTheme.appearance[props.appearance].state[props.state].hover.backgroundColor};
            border-color: ${localTheme.appearance[props.appearance].state[props.state].hover.borderColor};
          `}

          ${Icon} {
            * {
              ${iconAppearance(localTheme.appearance[props.appearance].inverse.hover.color)};
            }
          }

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance].inverse.hover.color};
              }
            }
          }
        }
      `}

      ${props.state !== 'disabled' &&
      localTheme.appearance[props.appearance].inverse.pressed &&
      css`
        &:active {
          background: ${localTheme.appearance[props.appearance].inverse.pressed?.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance].inverse.pressed?.borderColor};
          color: ${localTheme.appearance[props.appearance].inverse.pressed?.color};

          ${Icon} {
            * {
              ${iconAppearance(localTheme.appearance[props.appearance].inverse.pressed!.color)};
            }
          }

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance].inverse.pressed?.color};
              }
            }
          }

          ${props.state &&
          css`
            background: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.backgroundColor};
            border-color: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.borderColor};
            color: ${localTheme.appearance[props.appearance].state[props.state]?.pressed?.color};
          `}
        }
      `}
    `}
  `
})

export const HiddenText = styled.span((): FlattenSimpleInterpolation => {
  return css`
    visibility: hidden;
  `
})

export const LoadingIcon = styled.div((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})
/* eslint-enable complexity, sonarjs/cognitive-complexity */
