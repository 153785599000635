import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/MarketingShout.style'

import { MarketingShoutProps } from './MarketingShout.types'

const MarketingShout: FC<MarketingShoutProps> = ({
  appearance = 'primary',
  absolutePosition = false,
  align = 'left',
  weight = 2,
  text,
  children,
}: MarketingShoutProps): ReactElement => {
  return (
    <Styled.MarketingShoutContainer absolutePosition={absolutePosition!} align={align!}>
      <Styled.MarketingShout appearance={appearance!} absolutePosition={absolutePosition!} align={align!}>
        <Styled.MarketingShoutText appearance={appearance!} align={align!} weight={weight!}>
          <Choose>
            <When condition={text}>{text}</When>
            <Otherwise>{children}</Otherwise>
          </Choose>
        </Styled.MarketingShoutText>
      </Styled.MarketingShout>
    </Styled.MarketingShoutContainer>
  )
}

export default MarketingShout
