import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { elevation, responsiveCalc, responsivePosition, spacing } from '@vfuk/core-mixins'

import { SimpleGrid, SimpleGridColumn } from '@vfuk/core-simple-grid/dist/styles/SimpleGrid.style'

import { StyledSideTrayRendererProps } from './SideTrayRenderer.style.types'

type SideTrayRendererProps = Pick<StyledSideTrayRendererProps, 'zIndex' | 'topOffset'>
export const SideTrayRenderer = styled.div<SideTrayRendererProps>(
  (props: SideTrayRendererProps): FlattenSimpleInterpolation => css`
    position: fixed;
    top: ${props.topOffset};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: ${props.zIndex};
  `,
)

type ContainerProps = Pick<StyledSideTrayRendererProps, 'theme'>
export const Container = styled.div<ContainerProps>(
  (props: ContainerProps): FlattenSimpleInterpolation => css`
    width: 100%;
    height: 100%;
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;
    display: flex;
    position: relative;

    ${SimpleGrid} {
      width: 100%;
      pointer-events: none;
      position: relative;
      z-index: 10;
    }

    ${SimpleGridColumn} {
      position: relative;
    }
  `,
)

type SideTrayProps = Pick<StyledSideTrayRendererProps, 'theme' | 'side'>
export const SideTray = styled.div<SideTrayProps>(
  (props: SideTrayProps): FlattenSimpleInterpolation => css`
    background: ${props.theme.color.monochrome1.default};
    position: relative;
    width: 100%;
    z-index: 10;
    ${elevation(true)};
    border-top-left-radius: ${props.theme.border.radius[2]};
    border-bottom-left-radius: ${props.theme.border.radius[2]};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    pointer-events: auto;
    ${spacing('padding', 4)};

    ${props.side === 'left' &&
    css`
      border-top-right-radius: ${props.theme.border.radius[2]};
      border-bottom-right-radius: ${props.theme.border.radius[2]};

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 100%;
        left: -10000px;
        bottom: 0;
        background: ${props.theme.color.monochrome1.default};
      }
    `}

    ${props.side === 'right' &&
    css`
      &::before {
        content: '';
        display: block;
        left: 100%;
        right: -10000px;
        position: absolute;
        top: 0;
        bottom: 0;
        background: ${props.theme.color.monochrome1.default};
      }
    `}
  `,
)

type CloseButtonProps = Pick<StyledSideTrayRendererProps, 'theme' | 'animationDuration' | 'side'>
export const CloseButton = styled.div<CloseButtonProps>(
  (props: CloseButtonProps): FlattenSimpleInterpolation => css`
    position: absolute;
    ${responsivePosition('top', 4)};
    z-index: 5;
    pointer-events: auto;
    transition: ${props.animationDuration / 1000}s all ease;
    ${responsiveCalc(props.side!, '100%', '+', 4)};
  `,
)
