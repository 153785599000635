import { css, FlattenSimpleInterpolation } from 'styled-components'

import createMixin from '@vfuk/core-mixins/dist/utils/createMixin'

import { Animation } from './animations.types'

const slideFromRight: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes slideFromRight {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 150,
  },
}

export default slideFromRight
