import React, { ReactElement, FC } from 'react'
import FunctionalStepper from '@vfuk/core-functional-stepper'

import { StepperProps } from './Stepper.types'

const Stepper: FC<StepperProps> = ({ id, inverse, currentStep, steps, hideControls }: StepperProps): ReactElement => {
  return <FunctionalStepper id={id} inverse={inverse} currentStep={currentStep} steps={steps} hideControls={hideControls} />
}

export default Stepper
