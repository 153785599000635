import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { IconButton } from '@vfuk/core-icon-button/dist/styles/IconButton.style'
import { TextInput } from '@vfuk/core-text-input/dist/styles/TextInput.style'
import { Animate } from '@vfuk/core-animate/dist/styles/Animate.style'
import { respondTo, spacing } from '@vfuk/core-mixins'

import localThemes from '@vfuk/core-icon-button/dist/themes/IconButton.theme'

import { StyledSearchTextInputProps } from './SearchTextInput.style.types'

export const SearchWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
  `,
)

export const Input = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${TextInput} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      ${spacing('padding-left', 3)};
    }
  `,
)

export const SearchInput = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    align-items: center;
    flex-grow: 1;
  `,
)

export const InteractionIcons = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${Animate} {
      display: flex;
      align-items: center;
    }

    ${spacing('padding-right', 3)};
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
  `,
)

export const SearchIcon = styled.span((props: StyledSearchTextInputProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  const borderSize = props.hasState ? 4 : 2
  const mobileBorderSize = props.hasState ? 2 : 0

  return css`
    ${IconButton} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: ${mobileBorderSize + localTheme.size[props.size!].sm.buttonSize}px;

      ${respondTo.md(css`
        height: ${borderSize + localTheme.size[props.size!].md.buttonSize}px;
      `)}
    }
  `
})
