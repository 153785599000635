import { HighlightLevelKeys, AppearanceKeys } from '../../ContainerWithLabel.types'

interface HighlightProp {
  level?: HighlightLevelKeys
  appearance?: AppearanceKeys
}

interface HighlightResult {
  level: HighlightLevelKeys
  appearance: AppearanceKeys
}

function getHighlight(label: string | undefined, appearance: AppearanceKeys, highlight?: HighlightProp): undefined | HighlightResult {
  if (label) {
    return {
      appearance,
      level: highlight?.level ? highlight.level : 1,
    }
  }

  let result

  if (highlight && highlight.level && highlight.appearance) {
    result = highlight as HighlightResult
  }

  return result
}

export default getHighlight
