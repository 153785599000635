import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { HorizontalBarChartTheme } from './HorizontalBarChart.theme.types'

const HorizontalBarChartLocal = (theme: Theme): HorizontalBarChartTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.primary1.default,
        inverse: {
          backgroundColor: theme.inverseColor.primary1.default,
        },
      },
      secondary: {
        backgroundColor: theme.color.secondary1.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary1.default,
        },
      },
      alt1: {
        backgroundColor: theme.color.secondary4.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary4.default,
        },
      },
      alt2: {
        backgroundColor: theme.color.secondary2.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary2.default,
        },
      },
      alt3: {
        backgroundColor: theme.color.secondary3.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary3.default,
        },
      },
      alt4: {
        backgroundColor: theme.color.secondary5.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary5.default,
        },
      },
      alt5: {
        backgroundColor: theme.color.secondary6.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary6.default,
        },
      },
    },
    state: {
      success: {
        backgroundColor: theme.color.success.default,
        inverse: {
          backgroundColor: theme.inverseColor.success.default,
        },
      },
      warn: {
        backgroundColor: theme.color.warn.default,
        inverse: {
          backgroundColor: theme.inverseColor.warn.default,
        },
      },
      error: {
        backgroundColor: theme.color.error.default,
        inverse: {
          backgroundColor: theme.inverseColor.error.default,
        },
      },
    },
  }
}

export default HorizontalBarChartLocal
