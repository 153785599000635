import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { respondTo, spacing } from '@vfuk/core-mixins'

export const Advert = styled(Interaction)(
  (): FlattenSimpleInterpolation => css`
    text-align: left;
  `,
)

export const AdvertContent = styled.div(
  (): FlattenSimpleInterpolation => css`
    height: 100%;
    display: flex;
    flex-direction: column;

    ${respondTo.md(css`
      justify-content: space-between;
      flex-direction: row;
    `)}
  `,
)

export const AdvertBody = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${spacing('margin', 3)};
  `,
)

export const AdvertImage = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    max-height: 100px;
    flex-shrink: 0;

    img {
      height: 100%;
      object-fit: cover;

      ${respondTo.md(css`
        clip-path: polygon(6% 0%, 100% 0%, 100% 100%, 0% 100%);
        position: absolute;
      `)}
    }

    ${respondTo.md(css`
      width: 200px;
      max-height: initial;
    `)}

    ${respondTo.lg(css`
      width: 350px;
    `)}
  `,
)
