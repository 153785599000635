import React, { PureComponent, ReactNode } from 'react'

import Heading from '@vfuk/core-heading'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import Pill from '@vfuk/core-pill'

import ChooseButtonType from './components/ChooseButtonType'

import * as Styled from './styles/FunctionalCardPanel.style'

import { FunctionalCardPanelProps, CustomButton } from './FunctionalCardPanel.types'

export class FunctionalCardPanel extends PureComponent<FunctionalCardPanelProps> {
  public static defaultProps: Partial<FunctionalCardPanelProps> = {
    noPadding: false,
  }

  public render(): ReactNode {
    return (
      <Styled.FunctionalCardPanel
        onClick={this.props.onClick}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
        customRouterProps={this.props.customRouterProps}
        href={this.props.href}
        target={this.props.htmlAttributes?.target}
        rel={this.props.htmlAttributes?.rel}
        dataAttributes={this.props.htmlAttributes?.dataAttributes}
      >
        <Styled.CardBody noPadding={this.props.noPadding}>
          <Styled.LinkCardSection>
            <If condition={this.props.iconLeft?.name}>
              <Styled.IconWrapper>
                <Icon name={this.props.iconLeft!.name} size={5} group={this.props.iconLeft!.group} />
              </Styled.IconWrapper>
            </If>
            <Styled.InfoWrapper>
              <Styled.InformationSection>
                <Styled.HeadingAreaWrapper>
                  <Styled.HeadingWrapper>
                    <Heading text={this.props.headingText} size={1} weight={3} noMargin />
                  </Styled.HeadingWrapper>
                  <If condition={this.props.pill}>
                    <Styled.PillWrapper>
                      <Pill {...this.props.pill!} />
                    </Styled.PillWrapper>
                  </If>
                </Styled.HeadingAreaWrapper>
                <If condition={this.props.text}>
                  <Styled.TextWrapper>
                    <Paragraph size={1} weight={2} justify='left'>
                      {this.props.text}
                    </Paragraph>
                  </Styled.TextWrapper>
                </If>
              </Styled.InformationSection>
              <If condition={this.props.secondaryText}>
                <Styled.SecondaryTextWrapper>
                  <Paragraph size={1} weight={2} justify='right'>
                    {this.props.secondaryText}
                  </Paragraph>
                </Styled.SecondaryTextWrapper>
              </If>
            </Styled.InfoWrapper>
            <If condition={this.props.iconRight?.name}>
              <Styled.RightIconSection>
                <Icon name={this.props.iconRight!.name} size={5} appearance='secondary' group='system' />
              </Styled.RightIconSection>
            </If>
            <If condition={this.props.footnote && !this.props.button}>
              <Styled.FootnoteWrapper>
                <Paragraph size={1} weight={2} justify='right'>
                  {this.props.footnote}
                </Paragraph>
              </Styled.FootnoteWrapper>
            </If>
          </Styled.LinkCardSection>
          <If condition={this.props.button}>
            <Styled.ButtonWrapper>
              <ChooseButtonType button={this.props.button as CustomButton} />
            </Styled.ButtonWrapper>
          </If>
        </Styled.CardBody>
        <Styled.ChildWrapper>{this.props?.children}</Styled.ChildWrapper>
      </Styled.FunctionalCardPanel>
    )
  }
}

export default FunctionalCardPanel
