import { Dictionary } from '../../dictionary.types'

const es: Dictionary = {
  Optional: 'Opcional',
  Required: 'Obligatorio',
  Show: 'Mostrar',
  Hide: 'Ocultar',
  Search: 'Buscar',
  Clear: 'Limpiar',
  'No results found': 'No se encontraron resultados para {{value}}',
  'Go back': 'Volver',
  Carousel: 'Carrusel',
  Edit: 'Editar',
  Item: '(0)[Elemento];(1-inf)[Elementos];',
  'Go to': 'Ir a',
  Back: 'Atrás',
  'Go to the previous page': 'Ir a la página anterior',
  'Go to the next page': 'Ir a la siguiente página',
  Play: 'Reproducir',
  Pause: 'Pausar',
  'Show more': 'Mostrar más',
  Next: 'Siguiente',
  'Clear filters': 'Reiniciar filtros',
  'Sort by': 'Ordernar por',
  Confirm: 'Confirmar',
  Cancel: 'Cancelar',
  Retry: 'Intentar de nuevo',
  Undo: 'Deshacer',
  Apply: 'Aplicar',
  'Clear all': 'Reiniciar',
  Error: 'Error',
  Complete: 'Completo',
  Incomplete: 'Incompleto',
  Loading: 'Cargando',
  Close: 'Cerrar',
  Reset: 'Reiniciar',
  'Find out more': 'Saber más',
  Total: 'Total',
  Iteration: '{{iterator}} de {{array}}',
  'Use your current location': 'Usa tu localización actual',
  'Zoom in': 'Acercar',
  'Navigates to': 'Navegar a {{direction}}',
  'Filter options': 'Opciones de filtrado',
  Filter: '(0)[Filtro];(1-inf)[Filtros];',
  Applied: 'Aplicado',
  'Language name': 'Español',
  'More info': 'Más información',
}

export default es
