import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacingKeys } from '@vfuk/core-themes/dist/constants/spacing.types'
import { respondTo } from '@vfuk/core-mixins'

import { StyledSearchInputProps } from './SearchInput.style.types'

function calculateTextInputPadding(props: StyledSearchInputProps): FlattenSimpleInterpolation {
  const paddingLevel: spacingKeys = 2
  const localIconTheme = props.theme.getIconProperties('system')
  let paddingMultiplier = 2
  let iconWidthMobile = localIconTheme.size[2].sm
  let iconWidthDesktop = localIconTheme.size[2].lg

  if (props.hasText) {
    paddingMultiplier = 4
    iconWidthMobile = localIconTheme.size[2].sm * 2
    iconWidthDesktop = localIconTheme.size[2].lg * 2
  }

  return css`
    padding-right: ${props.theme.spacing.responsive[paddingLevel].sm * paddingMultiplier + iconWidthMobile}px;

    ${respondTo.md(css`
      padding-right: ${props.theme.spacing.responsive[paddingLevel].md * paddingMultiplier + iconWidthDesktop}px;
    `)}

    ${respondTo.lg(css`
      padding-right: ${props.theme.spacing.responsive[paddingLevel].lg * paddingMultiplier + iconWidthDesktop}px;
    `)}

    ${respondTo.xl(css`
      padding-right: ${props.theme.spacing.responsive[paddingLevel].xl * paddingMultiplier + iconWidthDesktop}px;
    `)}
  `
}

export const SearchInput = styled.div<StyledSearchInputProps>(
  (props): FlattenSimpleInterpolation => css`
    width: 100%;

    ${props.width === 'default' &&
    css`
      max-width: 432px;
    `}

    [class*='TextInput-'] {
      ${calculateTextInputPadding(props)}
    }

    [class*='IconWrapper'] {
      display: none;
    }
  `,
)
