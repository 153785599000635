import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'

import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'

import * as Styled from './styles/BottomTrayRenderer.style'

import { BottomTrayRendererProps } from './BottomTrayRenderer.types'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'

export class BottomTrayRenderer extends Renderer<BottomTrayRendererProps, RendererState> {
  public static defaultProps: Partial<BottomTrayRendererProps> = {
    overlayBlur: false,
    enableOverlay: false,
    isClosable: true,
  }

  public render(): ReactNode {
    const animatedDuration = this.props.animate ? 200 : 0
    return createPortal(
      <Styled.BottomTrayRenderer zIndex={this.props.zIndex}>
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['slideFromBottom'] : ['none'],
            duration: animatedDuration,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? ['slideToBottom'] : ['none'],
            duration: animatedDuration,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.BottomTrayRendererContent zIndex={this.props.zIndex}>
            <FocusLock shards={this.props.focusEnabledRefs}>
              <If condition={this.props.isClosable}>
                <CloseButton srName={this.props.srName} onClick={this.getOnCloseHandler(OverlayCloseSource.BOTTOMTRAY_CROSS_CLICK)} />
              </If>
              {this.props.children}
            </FocusLock>
          </Styled.BottomTrayRendererContent>
        </Animate>
        <If condition={this.props.enableOverlay}>
          <Animate
            show={this.state.show}
            enter={{
              animations: this.props.animate ? ['fadeIn'] : ['none'],
              duration: this.props.animate ? 400 : 0,
            }}
            exit={{
              animations: this.props.animate ? ['fadeOut'] : ['none'],
              duration: animatedDuration,
            }}
          >
            <Overlay
              show
              onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.BOTTOMTRAY_OUTSIDE_CLICK) : undefined}
              position='fixed'
              zIndex={0}
              blur={this.props.overlayBlur}
            />
          </Animate>
        </If>
      </Styled.BottomTrayRenderer>,
      document.body,
    )
  }
}

export default BottomTrayRenderer
