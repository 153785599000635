import React, { PureComponent, ReactNode } from 'react'

import SelectInputWithMenu from '@vfuk/core-select-input-with-menu'
import { SelectInputWithMenuProps } from '@vfuk/core-select-input-with-menu/dist/SelectInputWithMenu.types'
import ColorSwatch from '@vfuk/core-color-swatch'

import * as Styled from './styles/ColorSelect.style'

import { ColorSelectProps } from './ColorSelect.types'

export default class ColorSelect extends PureComponent<ColorSelectProps> {
  public static defaultProps: Partial<ColorSelectProps> = {}

  get mappedOptions(): SelectInputWithMenuProps['options'] {
    return this.props.options.map((option) => {
      // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
      const children = option.color ? <ColorSwatch color={option.color} available={!option.disabled} /> : <Styled.ColorSwatchPlaceholder />
      const mappedOption = {
        ...option,
        children,
      }
      delete mappedOption.color

      return mappedOption
    })
  }

  public render(): ReactNode {
    return (
      <SelectInputWithMenu
        id={this.props.id}
        value={this.props.value}
        state={this.props.state}
        options={this.mappedOptions}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        placeholder={this.props.placeholder}
        dataAttributes={this.props.dataAttributes}
        describedBy={this.props.describedBy}
      >
        <Styled.ColorSwatchPlaceholder />
      </SelectInputWithMenu>
    )
  }
}
