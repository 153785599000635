import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledButtonGroupProps } from './ButtonGroup.style.types'

export const ButtonGroup = styled.div(
  (props: StyledButtonGroupProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    ${spacing('gap', 4)};

    ${respondTo.md(css`
      flex-direction: row;
      justify-content: center;
    `)}

    ${respondTo.lg(css`
      ${props.mediaPosition !== 'center' &&
      css`
        justify-content: normal;
      `}
    `)}
  `,
)
