import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledInteractionProps } from './Interaction.style.types'

export const Button = styled.button<StyledInteractionProps>((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  background: transparent;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: ${props.theme.fontWeight[2]};

  ${props.theme.mixins.applyFocus()}

  ${props.isFullSize && css`
    width: 100%;
    height: 100%;
  `}
`)

export const Interaction = styled.div<StyledInteractionProps>((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  text-decoration: none;
  color: inherit;
  display: inline-block;
  font-weight: ${props.theme.fontWeight[2]};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
  }

  ${props.isFullSize && css`
    width: 100%;
    height: 100%;
  `}
`)

export const BlankInteraction = styled.div<StyledInteractionProps>((props: StyledInteractionProps): FlattenSimpleInterpolation => css`
  text-decoration: none;
  color: inherit;
  font-weight: ${props.theme.fontWeight[2]};

  ${props.isFullSize && css`
    width: 100%;
    height: 100%;
  `}
`)
