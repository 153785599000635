/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Interaction } from '@vfuk/core-interaction/dist/styles/Interaction.style'
import ListItem from '@vfuk/core-list-item'
import { spacing, typography } from '@vfuk/core-mixins'

import { StyledStateTextProps } from './StateText.style.types'

import localThemes from '../../../themes/FieldWrapper.theme'

export const StateText = styled(ListItem)((props: Pick<StyledStateTextProps, 'theme' | 'state'>): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    ${typography.paragraph(1, false)};

    ${props.state === 'error' &&
    css`
      color: ${localTheme.state.error.color};
    `}

    ${Interaction} {
      border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome5.default};
      display: inline-block;
      width: auto;

      ${props.state === 'error' &&
      css`
        color: ${localTheme.state.error.color};
      `}
    }
  `
})

export const StateTextContainer = styled.div(
  (props: Pick<StyledStateTextProps, 'theme' | 'state' | 'multipleStateText'>): FlattenSimpleInterpolation => {
    const localTheme = localThemes(props.theme)
    return css`
      display: flex;
      ${typography.paragraph(1, false)};
      ${spacing('margin-top', 2)};

      ${!props.multipleStateText &&
      css`
        align-items: center;
      `}

      svg {
        ${spacing('margin-right', 1)};

        ${props.multipleStateText &&
        css`
          margin-top: 5px;
        `}
      }

      ${props.state === 'error' &&
      css`
        color: ${localTheme.state.error.color};
      `}
    `
  },
)
