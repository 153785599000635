import React, { PureComponent, ReactNode } from 'react'

import Radio from '@vfuk/core-radio'

import * as Styled from './styles/RadioButtonListFieldSet.style'

import { RadioButtonListFieldSetProps } from './RadioButtonListFieldSet.types'

export default class RadioButtonListFieldSet extends PureComponent<RadioButtonListFieldSetProps> {
  private handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (this.props.radioButtons.onBlur) {
      this.props.radioButtons.onBlur(event)
    }
  }

  public render(): ReactNode {
    return (
      <fieldset id={this.props.radioButtons.id}>
        <Styled.Legend>{this.props.label}</Styled.Legend>
        <Styled.RadioButtonList hasLabel={this.props.showLabel} orientation={this.props.orientation} onBlur={this.handleOnBlur}>
          {this.props.radioButtons.items.map((radio, index: number) => {
            return (
              <Radio
                key={index}
                id={`${this.props.radioButtons.id}-${index}`}
                groupName={this.props.radioButtons.groupName}
                onChange={this.props.radioButtons.onChange}
                required={this.props.radioButtons.required}
                value={radio.value}
                state={radio.state}
                dataAttributes={radio.dataAttributes}
                checked={radio.value === this.props.radioButtons.checked}
                describedBy={this.props.describedBy}
              >
                {radio.label}
              </Radio>
            )
          })}
        </Styled.RadioButtonList>
      </fieldset>
    )
  }
}
