import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './Radio.ws10.theme'
import VOXI from './Radio.voxi.theme'

import { RadioTheme } from './Radio.theme.types'

const getLocalTheme = (theme: Theme): RadioTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI(theme)
    case 'WS2':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
