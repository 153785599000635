import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { TooltipTheme } from './Tooltip.theme.types'

const tooltipLocal = (theme: Theme): TooltipTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        color: theme.color.monochrome6.default,
      },
      secondary: {
        backgroundColor: theme.color.monochrome6.default,
        color: theme.color.monochrome1.default,
      },
    },
  }
}

export default tooltipLocal
