/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, spacing, typography } from '@vfuk/core-mixins'

import { StyledTabTitleProps } from './TabTitle.style.types'

import localThemes from '../../../themes/Tabs.theme'

type TabContainerProps = Pick<StyledTabTitleProps, 'theme' | 'tabWidth' | 'overflowActive'>
export const TabContainer = styled.div<TabContainerProps>(
  (props: TabContainerProps): FlattenSimpleInterpolation => css`
    display: flex;
    position: relative;
    align-items: stretch;
    justify-content: center;
    flex-shrink: 0;

    > button:first-child {
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }

    ${!props.overflowActive &&
    `
    ${
      props.tabWidth === 'stretch' &&
      `
      flex-grow: 1;
    `
    }
  `}
  `,
)

type TabTitleProps = Pick<StyledTabTitleProps, 'theme' | 'appearance' | 'inverse' | 'isActiveTab' | 'tabWidth' | 'overflowActive'>
export const TabTitle = styled.div<TabTitleProps>((props: TabTitleProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    color: ${localTheme.appearance[props.appearance!].textColor};
    ${advancedSpacing('padding', [3, 3, 2])};
    border-bottom: 4px ${props.theme.border.style[1]} transparent;
    white-space: nowrap;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      transform: translateY(-5px);
      left: 0;
      right: 0;
      height: 4px;
      border-top-left-radius: ${props.theme.border.radius[1]};
      border-top-right-radius: ${props.theme.border.radius[1]};
    }

    ${typography.heading(1, false)}

    ${props.tabWidth &&
    `
      ${advancedSpacing('padding', [3, 3, 2])};
    `}

    &:hover {
      font-weight: ${props.theme.fontWeight[3]};
    }

    ${!props.overflowActive &&
    `
      justify-content: center;
      flex-flow: column;
      &::after {
        content: attr(data-text);
        content: attr(data-text) / "";
        height: 0%;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: ${props.theme.fontWeight[3]};
        margin-top: -9px;

        @media speech {
          display: none;
        }
      }
    `}

    ${props.isActiveTab &&
    `
      color: ${localTheme.appearance[props.appearance!].textColor};
      font-weight: ${props.theme.fontWeight[3]};

      &::before {
        background: ${localTheme.appearance[props.appearance!].activeUnderlineColor};
      }
    `}

    ${props.inverse &&
    `
      color: ${localTheme.appearance[props.appearance!].inverse.textColor};

      ${
        props.isActiveTab &&
        `
        &::before {
          background: ${localTheme.appearance[props.appearance!].inverse.activeUnderlineColor};
        }
      `
      }
    `}
  `
})

type IconContainerProps = Pick<StyledTabTitleProps, 'isOverflowMenuOpen' | 'theme'>
export const IconContainer = styled.div<IconContainerProps>(
  (props: IconContainerProps): FlattenSimpleInterpolation => css`
    ${spacing('margin-left', 2)};
    transition: 0.2s ease-out;

    ${props.isOverflowMenuOpen &&
    `
    transform: rotate(180deg);
  `}
  `,
)
