import {
  LANGUAGE_REQUEST_SUCCESS,
  LANGUAGE_REQUEST_FAIL,
  LANGUAGE_LIST_REQUEST_SUCCESS,
  LANGUAGE_LIST_REQUEST_FAIL
} from 'actions/languageActions';

const initialState = {
  language: null,
  languageList: null
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case LANGUAGE_REQUEST_SUCCESS: {
      const { language } = payload;
      return {
        ...state,
        language
      };
    }
    case LANGUAGE_REQUEST_FAIL:
      return {
        ...state,
        error
      };
    case LANGUAGE_LIST_REQUEST_SUCCESS: {
      const { languages } = payload;
      console.log(`Retrieved languages: ${JSON.stringify(languages, null, 2)}`);
      return {
        ...state,
        languages
      };
    }
    case LANGUAGE_LIST_REQUEST_FAIL:
      return {
        ...state,
        error
      };
    default:
      return state;
  }
};
