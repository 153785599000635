import { RefObject } from 'react'

type EventType = MouseEvent | TouchEvent | KeyboardEvent

/**
 * Triggers a callback as and when a user interacts outside the ref
 * @param ref - Ref to the object that won't trigger the callback when interacted with
 * @param cb - Callback to run when clicked outside
 * @returns function that when run removes the event listeners
 */
function onClickOutside(ref: RefObject<HTMLElement>, cb: (event?: EventType) => void): () => void {
  const eventListeners = ['mousedown', 'touchstart', 'keydown']

  // Allowed keys are space and esc
  const allowedKeys = [32, 27]

  const handleEvent = (event: EventType): void => {
    if (!ref || !event.target) return

    // If its a keyboard event then check if its any keypress other than space
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event?.keyCode && !allowedKeys.includes(event.keyCode)) return

    // Do nothing if clicking ref's element or descendent elements
    if (ref.current && ref.current.contains(event.target as Node)) {
      return
    }

    // It has been clicked outside then call the callback
    cb(event)
  }

  // Add the event listeners
  eventListeners.forEach((eventListener) => {
    document.addEventListener(eventListener, handleEvent)
  })

  // Return function to remove the event listeners
  return (): void => {
    eventListeners.forEach((eventListener) => {
      document.removeEventListener(eventListener, handleEvent)
    })
  }
}

export default onClickOutside
