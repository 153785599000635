/* eslint-disable max-len */
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ButtonTheme, StateProps } from './Button.theme.types'

const buttonLocal = (theme: Theme): ButtonTheme => {
  const stateSuccess: StateProps = {
    color: theme.color.monochrome6.default,
    backgroundColor: theme.color.success.default,
    iconAppearance: 'primary',
    inverseIcon: false,
    borderColor: 'transparent',
    hover: {
      color: theme.color.monochrome6.default,
      backgroundColor: theme.color.success.hover,
      borderColor: 'transparent',
      iconAppearance: 'primary',
    },
  }

  const state = {
    success: stateSuccess,
    selected: stateSuccess,
  }

  return {
    appearance: {
      primary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.primary3.default,
        iconAppearance: 'primary',
        inverseIcon: true,
        borderColor: 'transparent',
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary3.hover,
          borderColor: 'transparent',
          iconAppearance: 'primary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome1.default,
            backgroundColor: theme.color.primary3.default,
            opacity: 0.3,
            iconAppearance: 'primary',
            inverseIcon: true,
            borderColor: 'transparent',
            hover: {
              color: theme.color.monochrome1.default,
              backgroundColor: theme.color.primary3.hover,
              borderColor: 'transparent',
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome1.default,
          backgroundColor: theme.inverseColor.secondary1.default,
          iconAppearance: 'primary',
          inverseIcon: false,
          borderColor: 'transparent',
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.secondary1.hover,
            borderColor: 'transparent',
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome1.default,
              backgroundColor: theme.inverseColor.secondary1.default,
              opacity: 0.3,
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: 'transparent',
              hover: {
                color: theme.inverseColor.monochrome1.default,
                backgroundColor: theme.inverseColor.secondary1.hover,
                borderColor: 'transparent',
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      secondary: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.primary3.default,
        hover: {
          color: theme.color.monochrome6.hover,
          backgroundColor: 'transparent',
          borderColor: theme.color.primary3.hover,
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.default,
            backgroundColor: 'transparent',
            opacity: 0.3,
            iconAppearance: 'primary',
            inverseIcon: false,
            borderColor: theme.color.primary3.default,
            hover: {
              color: theme.color.monochrome6.default,
              backgroundColor: 'transparent',
              borderColor: theme.color.primary3.hover,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.monochrome6.default,
          hover: {
            color: theme.inverseColor.monochrome6.hover,
            backgroundColor: 'transparent',
            borderColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.default,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: theme.inverseColor.monochrome6.default,
              hover: {
                color: theme.inverseColor.monochrome6.default,
                backgroundColor: 'transparent',
                borderColor: theme.inverseColor.monochrome6.default,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt1: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.primary3.default,
        hover: {
          color: theme.color.monochrome6.hover,
          backgroundColor: 'transparent',
          borderColor: theme.color.primary3.hover,
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.default,
            backgroundColor: 'transparent',
            opacity: 0.3,
            iconAppearance: 'primary',
            inverseIcon: false,
            borderColor: theme.color.primary3.default,
            hover: {
              color: theme.color.monochrome6.default,
              backgroundColor: 'transparent',
              borderColor: theme.color.primary3.hover,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.monochrome6.default,
          hover: {
            color: theme.inverseColor.monochrome6.hover,
            backgroundColor: 'transparent',
            borderColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.default,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: theme.inverseColor.monochrome6.default,
              hover: {
                color: theme.inverseColor.monochrome6.default,
                backgroundColor: 'transparent',
                borderColor: theme.inverseColor.monochrome6.default,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt2: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.primary3.default,
        hover: {
          color: theme.color.monochrome6.hover,
          backgroundColor: 'transparent',
          borderColor: theme.color.primary3.hover,
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.default,
            backgroundColor: 'transparent',
            opacity: 0.3,
            iconAppearance: 'primary',
            inverseIcon: false,
            borderColor: theme.color.primary3.default,
            hover: {
              color: theme.color.monochrome6.default,
              backgroundColor: 'transparent',
              borderColor: theme.color.primary3.hover,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.monochrome6.default,
          hover: {
            color: theme.inverseColor.monochrome6.hover,
            backgroundColor: 'transparent',
            borderColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.default,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              borderColor: theme.inverseColor.monochrome6.default,
              hover: {
                color: theme.inverseColor.monochrome6.default,
                backgroundColor: 'transparent',
                borderColor: theme.inverseColor.monochrome6.default,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
    },
    inverse: false,
    borderRadius: theme.border.radius[2],
    borderWidth: theme.border.width[1],
    focus: theme.color.focus.default,
  }
}

export default buttonLocal
