import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import isNumber from 'lodash/isNumber'

import { Container } from 'styled-bootstrap-grid'

import { StyledGridContainer } from './Grid.style.types'

// todo should come from theme
const springWidth = 1232

export const GridContainer = styled(Container)(
  (props: StyledGridContainer): FlattenSimpleInterpolation => css`
    ${isNumber(props.maxWidth) &&
    `
    max-width: ${props.maxWidth}px;
  `}

    ${props.maxWidth === 'spring' &&
    `
    max-width: ${springWidth}px;
  `}

  ${!props.center &&
    `
    margin: 0;
  `}
  `,
)
