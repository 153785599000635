import React, { FC } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'

import RangeSliderFieldSet from './components/RangeSliderFieldSet'

import { RangeSliderProps } from './RangeSlider.types'

const RangeSlider: FC<RangeSliderProps> = (props) => {
  return (
    <FieldWrapper
      label={props.srLabel.mainLabel}
      formElementId={props.id}
      state={props.state}
      stateText={props.stateText}
      showLabel={false}
    >
      <RangeSliderFieldSet {...props} />
    </FieldWrapper>
  )
}

export default RangeSlider
