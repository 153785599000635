import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import { StyledBlockProps, StyledContentWrapperProps } from './Block.style.types'

import localThemes from '../themes/Block.theme'

export const Block = styled.div((props: StyledBlockProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  const verticalPadding = localTheme.paddingLevel[props.paddingLevel!].vertical
  const horizontalPadding = localTheme.paddingLevel[props.paddingLevel!].horizontal

  return css`
    background: ${localTheme.appearance[props.appearance!].backgroundColor};
    ${advancedSpacing('padding', [verticalPadding, horizontalPadding])}

    ${props.removeBottomPadding &&
    css`
      padding-bottom: 0 !important;
    `}
  `
})

export const ContentWrapper = styled.div(
  (props: StyledContentWrapperProps): FlattenSimpleInterpolation => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;
  `,
)
