import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LANGUAGE_REQUEST,
  LANGUAGE_REQUEST_FAIL,
  LANGUAGE_REQUEST_SUCCESS,
  LANGUAGE_LIST_REQUEST,
  LANGUAGE_LIST_REQUEST_FAIL,
  LANGUAGE_LIST_REQUEST_SUCCESS
} from 'actions/languageActions';
import TranslationService from 'services/TranslationService';
import i18next from 'i18next';

function* languageWorker({ payload }) {
  try {
    const language = payload;
    const { data } = yield call(TranslationService.fetchTranslations, language);
    try {
      i18next.addResourceBundle(language, 'translation', data, true, true);
      i18next.changeLanguage(language);
    } catch (e) {
      console.log(e);
    }
    document.documentElement.setAttribute('lang', language);
    console.log(
      `Language set to ${language} after loading translations for ${JSON.stringify(
        Object.keys(data)
      )}`
    );

    yield put({
      type: LANGUAGE_REQUEST_SUCCESS,
      payload: {
        language: payload
      }
    });
  } catch (error) {
    yield put({ type: LANGUAGE_REQUEST_FAIL, error });
  }
}

function* languageWatcher() {
  yield takeLatest(LANGUAGE_REQUEST, languageWorker);
}

function* languageListWorker({ payload }) {
  try {
    const { data } = yield call(TranslationService.fetchLanguages, payload);
    yield put({
      type: LANGUAGE_LIST_REQUEST_SUCCESS,
      payload: {
        languages: data
      }
    });
  } catch (error) {
    yield put({ type: LANGUAGE_LIST_REQUEST_FAIL, error });
  }
}

function* languageListWatcher() {
  yield takeLatest(LANGUAGE_LIST_REQUEST, languageListWorker);
}

const languageSaga = [languageWatcher(), languageListWatcher()];

export default languageSaga;
