import { CheckJustificationsReturn, CardSandwichSlotProps } from '../../CardSandwichSlot.types'

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function checkVerticalJustification(
  priceToCheckText: CardSandwichSlotProps['primaryPrice']['text'],
  priceToCheckAgainstText: CardSandwichSlotProps['primaryPrice']['text'],
): CheckJustificationsReturn {
  const priceToCheckAgainstHasPrefixAndSuffix = priceToCheckAgainstText.prefix && priceToCheckAgainstText.suffix

  if (
    (priceToCheckAgainstText.prefix && !priceToCheckAgainstText.suffix) ||
    (priceToCheckAgainstHasPrefixAndSuffix && priceToCheckText.suffix)
  )
    return
  if (
    (!priceToCheckAgainstText.prefix && priceToCheckAgainstText.suffix) ||
    (priceToCheckAgainstHasPrefixAndSuffix && priceToCheckText.prefix)
  )
    return 'top'
  return 'center'
}
