import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledContentRendererProps } from './ContentRenderer.types'

export const ContentText = styled.div<StyledContentRendererProps>(
  (props): FlattenSimpleInterpolation => css`
    text-align: center;

    ${props.inverse &&
    css`
      p,
      span,
      a,
      table,
      li {
        color: ${props.theme.inverseColor.monochrome6.default};
      }

      a {
        text-decoration: underline;
      }

      table {
        tr {
          border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.inverseColor.monochrome6.default};
        }
      }
    `}

    ${(props.contentPosition === 'left' || props.contentPosition === 'right') &&
    css`
      text-align: left;
    `}
  `,
)
