import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, spacing, typography } from '@vfuk/core-mixins'
import { properties } from '@vfuk/core-mixins/dist/spacing/spacing.types'

import { HeadingContainerProps, StyledCardHeadingAndIconSlotProps } from './CardHeadingAndIconSlot.style.types'

export const CardHeadingAndIconSlot = styled.div<StyledCardHeadingAndIconSlotProps>(
  (props: StyledCardHeadingAndIconSlotProps): FlattenSimpleInterpolation => css`
    width: 100%;
    flex: 1;
    display: flex;
    ${advancedSpacing('padding', [2, 4])}

    &:first-child {
      ${spacing('padding-top', 3)}
    }

    &:last-child {
      ${spacing('padding-bottom', 3)}
    }

    ${props.justifyIcon === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
  `,
)

export const IconContainer = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
)

export const HeadingContainer = styled.div<HeadingContainerProps>(
  (props: HeadingContainerProps) => css`
    flex-grow: 1;

    ${props.hasIcon &&
    css`
      ${spacing(`margin-${props.justifyIcon}` as properties, 4)};
    `}
  `,
)

export const PreffixSuffixContainer = styled.div<StyledCardHeadingAndIconSlotProps>(
  (props: StyledCardHeadingAndIconSlotProps) => css`
    ${typography.paragraph(props.level === 2 ? 1 : 2, false)};
    color: ${props.theme.color.monochrome5.default};
  `,
)

export const LinkContainer = styled.div<StyledCardHeadingAndIconSlotProps>(
  (props: StyledCardHeadingAndIconSlotProps) => css`
    ${typography.paragraph(1, false)}
    color: ${props.theme.color.monochrome6.default};
  `,
)

export const IconsSeparatorText = styled.span<StyledCardHeadingAndIconSlotProps>(
  (props: StyledCardHeadingAndIconSlotProps) => css`
    color: ${props.theme.color.monochrome5.default};
    ${typography.paragraph(2, false)};
    font-weight: ${props.theme.fontWeight[3]};
    ${advancedSpacing('margin', [1, 0])};
  `,
)
