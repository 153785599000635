import { PartialDateTextInputProps } from '../inputErrorHandler/inputErrorHandler.types'

type propNamesKey = 'minDate' | 'maxDate' | 'includeDates' | 'excludeDates' | 'filterDate'

const createErrorMessages = (
  errorMessages: PartialDateTextInputProps['errorMessages'],
  valid: boolean,
  errorMsg: string,
  propName: propNamesKey,
): string | null => {
  // If the date is invalid and errorMessages is null, return null so that no errorMessages are shown
  if (!valid && errorMessages === null) return ''

  // If errorMessages is undefined, use the default errorMessages
  if (!valid && !errorMessages) return errorMsg

  // If the date is not valid (meaning it doesnt fall in the dates set in props[propName], and its not set to null in errorMessages, push the customErrorMessage in the errors array)
  if (!valid && errorMessages![propName] !== null) {
    return errorMessages![propName] ? errorMessages![propName] : errorMsg
  }

  // Will return null in the instance that props.errorMessages === null and date is valid
  return null
}

export default createErrorMessages
