import { FunctionalAvatarTheme } from './FunctionalAvatar.theme.types'

const functionalAvatarLocal: FunctionalAvatarTheme = {
  size: {
    1: {
      sm: 24,
      md: 24,
      lg: 24,
    },
    2: {
      sm: 32,
      md: 32,
      lg: 32,
    },
    3: {
      sm: 48,
      md: 48,
      lg: 48,
    },
    4: {
      sm: 64,
      md: 64,
      lg: 64,
    },
    5: {
      sm: 80,
      md: 80,
      lg: 88,
    },
    6: {
      sm: 88,
      md: 96,
      lg: 104,
    },
  },
}

export default functionalAvatarLocal
