import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledGalleryProps, StyledGalleryContainerProps } from './Gallery.style.types'

export const Gallery = styled.div<StyledGalleryProps>(
  (props: StyledGalleryProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    width: 100%;
    ${props.arrowsPosition === 'outside' &&
    css`
      width: 75%;
    `}

    img {
      object-fit: contain;
    }

    ${respondTo.md(css`
      flex-direction: column;
      justify-content: center;
      width: 100%;

      [class*='CarouselDotPagerList'] {
        display: none;
      }

      ${props.orientation === 'horizontal' &&
      css`
        align-items: flex-start;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;
      `}
    `)}
  `,
)

export const GalleryContainer = styled.div<StyledGalleryContainerProps>(
  (props: StyledGalleryContainerProps): FlattenSimpleInterpolation => css`
    width: 100%;
    ${spacing('padding', 2)}

    ${respondTo.md(css`
      padding: 0;

      ${props.orientation === 'horizontal' &&
      css`
        width: 70%;
      `}

      ${props.maxWidth &&
      css`
        max-width: ${props.maxWidth};
      `}
    `)}
  `,
)

export const PagerContainer = styled.div<StyledGalleryProps>(
  (props: StyledGalleryProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: ${props.theme.spacing.responsive[6].sm}px;

    ${props.orientation === 'horizontal' &&
    css`
      ${respondTo.md(css`
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;
        margin-right: ${props.theme.spacing.responsive[6].md}px;
        width: 30%;
      `)}

      ${respondTo.lg(css`
        margin-right: ${props.theme.spacing.responsive[6].lg}px;
      `)}

    ${respondTo.xl(css`
        margin-right: ${props.theme.spacing.responsive[6].xl}px;
      `)}
    `}
  `,
)

export const ImagePager = styled(Interaction)((props: StyledGalleryProps): FlattenSimpleInterpolation => {
  return css`
    max-height: 100px;
    max-width: 100px;
    ${spacing('padding', 2)}

    & img {
      object-fit: contain;
      height: calc(100px - ${props.theme.spacing.responsive[2].sm * 2}px);
      width: calc(100px - ${props.theme.spacing.responsive[2].sm * 2}px);
    }
  `
})
