import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import { StyledTopLabelsProps } from './TopLabels.style.types'

export const Labels = styled.legend((): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    width: 100%;
    justify-content: flex-start;

    ${advancedSpacing('margin', [0, 0, 2])}
    > span {
      width: 33.3%;
    }
  `
})

export const LeftLabel = styled.span((props: StyledTopLabelsProps): FlattenSimpleInterpolation => {
  return css`
    align-self: flex-start;
    margin-right: auto;

    ${props.isLeft &&
    !props.isRight &&
    css`
      margin-right: 0;
    `}
  `
})

export const CenterLabel = styled.span((props: StyledTopLabelsProps): FlattenSimpleInterpolation => {
  return css`
    text-align: center;
    align-self: center;
    margin: 0 auto;
    font-weight: ${props.theme.fontWeight[3]};

    /* Left and center */
    ${props.isLeft &&
    !props.isRight &&
    css`
      margin: 0;
      align-self: flex-start;
    `}

    /* Right and center */
    ${!props.isLeft &&
    props.isRight &&
    css`
      align-self: flex-end;
      margin: 0 -33.3% 0 auto;
    `}
  `
})

export const RightLabel = styled.span(
  (): FlattenSimpleInterpolation => css`
    text-align: right;
    align-self: auto;
    margin-left: auto;
  `,
)
