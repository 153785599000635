import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { LinkWithIconTheme } from './LinkWithIcon.theme.types'

const linkWithIconLocal = (theme: Theme): LinkWithIconTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.secondary7.default,
        hoverColor: theme.color.secondary7.hover,
        iconAppearance: 'secondary',
        inverse: {
          color: theme.inverseColor.secondary7.default,
          hoverColor: theme.inverseColor.secondary7.hover,
          iconAppearance: 'primary',
        },
      },
      secondary: {
        color: theme.color.monochrome6.default,
        hoverColor: theme.color.secondary7.hover,
        iconAppearance: 'primary',
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hoverColor: theme.inverseColor.secondary7.hover,
          iconAppearance: 'primary',
        },
      },
    },
  }
}

export default linkWithIconLocal
