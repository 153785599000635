import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './FunctionalCarousel.ws10.theme'
import WS2 from './FunctionalCarousel.ws2.theme'

import { FunctionalCarouselTheme } from './FunctionalCarousel.theme.types'

const getLocalTheme = (theme: Theme): FunctionalCarouselTheme => {
  switch (theme.name) {
    case 'WS2':
      return WS2(theme)
    case 'VOXI':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
