import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import LoadingSpinner from '@vfuk/core-loading-spinner'

import localThemes from './themes/IconButton.theme'

import * as Styled from './styles/IconButton.style'

import { IconButtonProps } from './IconButton.types'

export class IconButton extends PureComponent<IconButtonProps> {
  public static defaultProps: Partial<IconButtonProps> = {
    shape: 'square',
    size: 6,
    appearance: 'primary',
    htmlAttributes: {},
  }

  public render(): ReactNode {
    const localTheme = localThemes(this.props.theme!)
    const isDisabledOrLoading = this.props.state === 'disabled' || this.props.loading!

    return (
      <Styled.IconButton
        appearance={this.props.appearance}
        target={this.props.htmlAttributes!.target}
        type={this.props.htmlAttributes!.type}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        size={this.props.size}
        shape={this.props.shape}
        inverse={this.props.inverse}
        state={this.props.state}
        onInteraction={this.props.onClick}
        onMouseDown={this.props.onMouseDown}
        disabled={isDisabledOrLoading}
        label={this.props.srText}
        to={!isDisabledOrLoading ? this.props.to : undefined}
        href={!isDisabledOrLoading ? this.props.href : undefined}
        id={this.props.id}
      >
        <Choose>
          <When condition={this.props.loading}>
            <Styled.LoadingIcon>
              <Choose>
                <When condition={this.props.inverse}>
                  <LoadingSpinner
                    appearance={localTheme.appearance[this.props.appearance!].inverse.iconAppearance}
                    inverse={localTheme.appearance[this.props.appearance!].inverse.inverseIcon}
                    size={localTheme.size[this.props.size!].iconSize}
                  />
                </When>
                <Otherwise>
                  <LoadingSpinner
                    appearance={localTheme.appearance[this.props.appearance!].iconAppearance}
                    inverse={localTheme.appearance[this.props.appearance!].inverseIcon}
                    size={localTheme.size[this.props.size!].iconSize}
                  />
                </Otherwise>
              </Choose>
            </Styled.LoadingIcon>
          </When>
          <Otherwise>
            <Icon name={this.props.icon.name} size={localTheme.size[this.props.size!].iconSize} />
          </Otherwise>
        </Choose>
      </Styled.IconButton>
    )
  }
}

export default withTheme(IconButton)
