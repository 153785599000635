const LOGOUT = 'LOGOUT';
const INIT_LOGIN = 'INIT_LOGIN';
const LOGGED_IN = 'LOGGED_IN';
const LOGGED_IN_SUCCESSFULLY = 'LOGGED_IN_SUCCESSFULLY';
const LOGOUT_MESSAGE = 'LOGOUT_MESSAGE';
const SHOW_INACTIVITY_WARNING = 'SHOW_INACTIVITY_WARNING';
const HIDE_INACTIVITY_WARNING = 'HIDE_INACTIVITY_WARNING';
const CLEAR_LOGOUT_MESSAGE = 'CLEAR_LOGOUT_MESSAGE';
const LOGIN_ERROR = 'LOGIN_ERROR';
const SIGN_IN_PAGE_LOADED = "SIGN_IN_PAGE_LOADED";

const loggedIn = payload => ({
  type: LOGGED_IN,
  payload
});

const loginError = error => ({
  type: LOGIN_ERROR,
  error
});

const loggedOut = () => ({
  type: LOGOUT
});

const loggedInSuccessfully = () => ({
  type:LOGGED_IN_SUCCESSFULLY
})

const initLogin = () => ({
  type:INIT_LOGIN
})

const signInPageLoaded = () => ({
  type:SIGN_IN_PAGE_LOADED
})

export {
  INIT_LOGIN,
  LOGGED_IN,
  LOGGED_IN_SUCCESSFULLY,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SHOW_INACTIVITY_WARNING,
  HIDE_INACTIVITY_WARNING,
  initLogin,
  loggedIn,
  loggedInSuccessfully,
  loginError,
  loggedOut,
  signInPageLoaded
};
