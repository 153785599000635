import type { ReactNode, ReactElement } from 'react'
import React, { PureComponent } from 'react'
import { v4 as uuid } from 'uuid'
import Slider from 'react-slick'
import { withTranslation } from 'react-i18next'

import { withLocalTheme } from '@vfuk/core-themes'
import InteractiveIcon from '@vfuk/core-interactive-icon'
import Icon from '@vfuk/core-icon'
import MatchMedia from '@vfuk/core-match-media'
import { loadResources } from '@vfuk/core-language-packs'
import { getDataSelector } from '@vfuk/core-base-props'

import languages, { componentName } from './locales'

import { SliderSettings, SliderControls } from './utils'

import CarouselArrow from './components/CarouselArrow'
import CarouselDotPager from './components/CarouselDotPager'
import CarouselLoaderPager from './components/CarouselLoaderPager'

import 'slick-carousel/slick/slick.css'

import defaultTheme from './themes/FunctionalCarousel.theme'
import * as Styled from './styles/FunctionalCarousel.style'

import type { FunctionalCarouselProps, FunctionalCarouselState, FunctionalCarouselSettings } from './FunctionalCarousel.types'
import type { FunctionalCarouselTheme } from './themes/FunctionalCarousel.theme.types'

export class FunctionalCarousel extends PureComponent<FunctionalCarouselProps, FunctionalCarouselState> {
  // Separated all of the carousel functions into two util classes
  // SliderSettings contains all the functions to create a slider
  // SliderControls contains all the functions to control the slider
  constructor(props: FunctionalCarouselProps) {
    super(props)
    this.sliderSettings = new SliderSettings(this as FunctionalCarouselSettings)
    this.sliderControls = new SliderControls(this as FunctionalCarouselSettings)
  }

  public sliderRef: Slider | null

  private sliderSettings: SliderSettings

  private sliderControls: SliderControls

  public state: FunctionalCarouselState = {
    currentSlide: 0, // Index of the current slide
    slidesToShow: 0, // Number of slides to show at a time
    slidesToScroll: 0, // Number of slides to move on click
    pagersToShow: 0, // Number of pagers to show
    transitioning: false,
    playing: false,
    // Determines whether the slides change is controller by pager.
    // For instance the LoaderPager switch slide every 3000ms.
    slidesControlledByPager: true,
  }

  public static defaultProps: Partial<FunctionalCarouselProps> = {
    allowOverflow: false,
    elevatedCards: false,
    id: uuid(),
    infiniteLoop: false,
    startingSlide: 0,
    currentSlide: 0,
    transitionSpeed: 1500,
    slidesToScroll: {},
  }

  componentDidUpdate(prevProps: FunctionalCarouselProps): void {
    if (this.props.currentSlide !== prevProps.currentSlide) {
      this.sliderControls.goToPagerNumber(this.props.currentSlide as number)
    }
  }

  public setSliderRef = (slider: Slider | null): void => {
    this.sliderRef = slider
  }

  public handleSlideControlChange(controlled: boolean): void {
    this.setState({ slidesControlledByPager: controlled })
  }

  private isSlideActive(index: number): boolean {
    if (!Number.isInteger(this.state.currentSlide) || !this.state.slidesToScroll) return false
    return this.state.currentSlide === this.state.slidesToScroll * index
  }

  private isSlideVisible(index: number): boolean {
    if (!Number.isInteger(this.state.currentSlide) || !this.state.slidesToScroll) return false
    if (index < this.state.currentSlide) return false
    return index < this.state.currentSlide + this.state.slidesToShow
  }

  public render(): ReactNode {
    loadResources(this.props.i18n, componentName, languages)

    return (
      <Styled.CarouselWrapper
        allowOverflow={this.props.allowOverflow!}
        aria-label={this.props.srName}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={componentName}
        {...this.props.dataAttributes}
      >
        <Styled.Carousel
          pagerPosition={this.props.pager?.position || 'outside'}
          transitioning={this.state.transitioning}
          allowOverflow={this.props.allowOverflow!}
          elevatedCards={this.props.elevatedCards!}
          functionalCarouselTheme={this.props.localTheme!}
          theme={this.props.theme!}
        >
          <If condition={this.sliderSettings.showPreviousArrow && this.props.pager?.type !== 'loader'}>
            <CarouselArrow
              direction='previous'
              inverse={this.props.arrows?.inverse}
              position={this.props.arrows?.position}
              onClick={this.sliderControls.goToPreviousSlide}
              pagerPosition={this.props.pager?.position}
              shape={this.props.arrows?.shape}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'prev-arrow')}
            />
          </If>
          <If condition={this.props.autoPlay?.active}>
            <Styled.CarouselControls pagerPosition={this.props.pager?.position || 'outside'}>
              <Choose>
                <When condition={this.state.playing}>
                  <InteractiveIcon
                    srText={this.props.t!('Pause')}
                    name='pause-circle'
                    appearance='primary'
                    inverse={this.sliderSettings.invertedControls}
                    onClick={this.sliderControls.pause}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'pause-icon')}
                  />
                </When>
                <Otherwise>
                  <InteractiveIcon
                    srText={this.props.t!('Play')}
                    name='play-circle'
                    appearance='primary'
                    inverse={this.sliderSettings.invertedControls}
                    onClick={this.sliderControls.play}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'play-icon')}
                  />
                </Otherwise>
              </Choose>
            </Styled.CarouselControls>
          </If>
          <Slider {...this.sliderSettings.settings} ref={this.setSliderRef}>
            {React.Children.map(this.props.children, (child, index) => {
              if (this.isSlideVisible(index)) return child
              return React.cloneElement(child as React.ReactElement, {
                onClick: null,
                to: null,
                href: null,
              })
            })}
          </Slider>
          <If condition={this.sliderSettings.showNextArrow && this.props.pager?.type !== 'loader'}>
            <CarouselArrow
              direction='next'
              inverse={this.props.arrows?.inverse}
              position={this.props.arrows?.position}
              onClick={this.sliderControls.goToNextSlide}
              pagerPosition={this.props.pager?.position}
              shape={this.props.arrows?.shape}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'next-arrow')}
            />
          </If>
          <If condition={this.props.pager?.type !== 'loader'}>
            <Styled.CarouselDotPagerList
              position={this.props.pager?.position || 'outside'}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'pager-list')}
            >
              {new Array(this.state.pagersToShow).fill(0).map(
                (_, i: number): ReactElement => (
                  <CarouselDotPager
                    appearance={this.props.pager?.appearance}
                    key={i}
                    pagerCount={this.sliderSettings.slides.length}
                    inverse={!!this.props.pager?.inverse}
                    active={this.isSlideActive(i)}
                    index={i}
                    onClick={this.sliderControls.goToPagerNumber}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `dot-pager-${i}`)}
                  />
                ),
              )}
            </Styled.CarouselDotPagerList>
          </If>
          <If condition={this.props.pager?.titles?.length && this.props.pager.type === 'loader'}>
            <Styled.CarouselLoaderPager data-selector={getDataSelector(this.props.dataSelectorPrefix, 'loader-pager')}>
              <Styled.CarouselLoaderPagerList>
                <>
                  <If condition={this.props.pager?.showNavigation}>
                    <MatchMedia breakpoint='lg' andAbove>
                      <Styled.CarouselLoaderPagerArrow onClick={this.sliderControls.goToPreviousSlide}>
                        <Icon
                          name='chevron-left'
                          appearance='secondary'
                          size={2}
                          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'prev-icon')}
                        />{' '}
                        Prev
                      </Styled.CarouselLoaderPagerArrow>
                    </MatchMedia>
                  </If>
                  {this.props.pager?.titles?.map(
                    (title: string, i: number): ReactElement => (
                      <CarouselLoaderPager
                        key={i}
                        pagerCount={this.sliderSettings.slides.length}
                        inverse={this.props.pager!.inverse!}
                        active={this.isSlideActive(i)}
                        index={i}
                        title={title}
                        timeout={this.props.pager?.timeout || 3000}
                        onChange={this.sliderControls.goToPagerNumber}
                        autoplay={this.state.slidesControlledByPager}
                        onSlideControlChange={(controlled: boolean): void => this.handleSlideControlChange(controlled)}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'loader-pager')}
                      />
                    ),
                  )}
                  <If condition={this.props.pager?.showNavigation}>
                    <MatchMedia breakpoint='lg' andAbove>
                      <Styled.CarouselLoaderPagerArrow
                        onClick={this.sliderControls.goToNextSlide}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'loader-pager-arrow')}
                      >
                        {this.props.t!('Next')}
                        <Icon
                          name='chevron-right'
                          appearance='secondary'
                          size={2}
                          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'loader-next-icon')}
                        />
                      </Styled.CarouselLoaderPagerArrow>
                    </MatchMedia>
                  </If>
                </>
              </Styled.CarouselLoaderPagerList>
            </Styled.CarouselLoaderPager>
          </If>
        </Styled.Carousel>
      </Styled.CarouselWrapper>
    )
  }
}

export default withTranslation()(withLocalTheme<FunctionalCarouselProps, FunctionalCarouselTheme>(FunctionalCarousel, defaultTheme))
