import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { AnimationConfig } from '../Animate.types'

const getMaximumDuration = (animationCfg: AnimationConfig, theme: Theme): number => {
  const themeDuration = animationCfg.animations.reduce((accDuration, animationName): number => {
    const themeAnimation = theme.animations[animationName]
    if (themeAnimation && themeAnimation.config.duration > accDuration) {
      return themeAnimation.config.duration
    }
    return accDuration
  }, 0)
  const maxDuration = animationCfg.duration || themeDuration
  const maxDelay = animationCfg.delay || 0
  return maxDuration + maxDelay
}

export default getMaximumDuration
