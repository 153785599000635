import React, { PureComponent, ReactNode } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'

import CheckboxFieldSet from './components/CheckboxFieldSet'

import { CheckboxListProps } from './CheckboxList.types'

export default class CheckboxList extends PureComponent<CheckboxListProps> {
  public static defaultProps: Partial<CheckboxListProps> = {
    orientation: 'vertical',
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        label={this.props.fieldWrapper.label}
        showLabel={this.props.fieldWrapper.showLabel}
        state={this.props.state}
        helpText={this.props.fieldWrapper.helpText}
        helpLink={this.props.fieldWrapper.helpLink}
        subText={this.props.fieldWrapper.subText}
        stateText={this.props.fieldWrapper.stateText}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        renderAsLabel={false}
        formElementId={this.props.checkboxes.id}
        width={this.props.fieldWrapper.width}
      >
        <CheckboxFieldSet
          checkboxes={this.props.checkboxes}
          label={this.props.fieldWrapper.label}
          showLabel={this.props.fieldWrapper.showLabel}
          orientation={this.props.orientation}
        />
      </FieldWrapper>
    )
  }
}
