import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import i18next from 'i18next';
import AppRouter from 'routes/AppRouter';
import { store, persistor } from 'store/configureStore';
import Liveness from 'services/Liveness';

import { changeLanguage, getLanguageList } from 'actions/languageActions';
import { fetchConfig } from 'actions/configActions';
import { applicationMounted } from 'actions/applicationActions';

Liveness.setStore(store);
store.dispatch(getLanguageList());
store.dispatch(fetchConfig());

i18next
  .init({
    lng: 'en',
    resources: {}
  })
  .then(t => {
    console.log('Languages initialized with no resources');
    store.dispatch(changeLanguage('en'));
  });

const App = () => {
  useEffect(() => {
    store.dispatch(applicationMounted());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
};

export default App;
