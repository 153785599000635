import React, { PureComponent, ReactNode } from 'react'

import * as Styled from './styles/Radio.style'

import { RadioProps } from './Radio.types'

export default class Radio extends PureComponent<RadioProps> {
  public static defaultProps: Partial<RadioProps> = {
    required: false,
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onChange) {
      this.props.onChange(event)
    }
  }

  public render(): ReactNode {
    return (
      <Styled.RadioWrapper>
        <Styled.Radio
          type='radio'
          required={this.props.required}
          disabled={this.props.state === 'disabled'}
          id={this.props.id}
          onChange={this.handleOnChange}
          checked={this.props.checked}
          name={this.props.groupName}
          value={this.props.value}
          {...this.props.dataAttributes}
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
        />
        <Styled.LabelText htmlFor={this.props.id} disabled={this.props.state === 'disabled'} checked={this.props.checked}>
          <Choose>
            <When condition={this.props.srText}>
              <Styled.HiddenLabel>{this.props.srText}</Styled.HiddenLabel>
            </When>
            <Otherwise>{this.props.children}</Otherwise>
          </Choose>
        </Styled.LabelText>
        <If condition={this.props.srText}>
          <Styled.FullWidthWrapper>{this.props.children}</Styled.FullWidthWrapper>
        </If>
      </Styled.RadioWrapper>
    )
  }
}
