import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { iconAppearance, respondTo } from '@vfuk/core-mixins'

import { StyledToggleProps } from './Toggle.style.types'

import localTheme from '../theme/Toggle.theme'

type ToggleWrapperProps = Pick<StyledToggleProps, 'theme'>
export const ToggleWrapper = styled.div(
  (props: ToggleWrapperProps): FlattenSimpleInterpolation => css`
    position: relative;
    display: inline-block;
    padding: 1rem 0;

    &:focus-within {
      label::before {
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
        outline: 3px solid transparent;
      }
    }
  `,
)

export const LabelText = styled.label<StyledToggleProps>((props: StyledToggleProps): FlattenSimpleInterpolation => {
  const localThemes = localTheme(props.theme)

  return css`
    font-size: ${props.theme.typography.paragraph[2].fontMobile};
    ${`padding-${props.align}`}: ${props.hasChildren ? '3.125rem' : '2.5rem'};
    ${respondTo.md(css`
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
    `)}
    cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};

    &::before {
      box-sizing: border-box;
      border-radius: 100px;
      content: '';
      position: absolute;
      top: calc(50% - 0.75rem);
      ${props.align}: 0;
      height: 1.5rem;
      width: 2.5rem;
      background: ${localThemes.appearance[props.appearance].inactive.color};
      transition: background-color 0.1s ease;
      transition-delay: 0.1s;
    }

    ${props.isActive &&
    css`
      &::before {
        background-color: ${localThemes.appearance[props.appearance].active.color};
      }
    `}

    ${props.isDisabled &&
    css`
      &:before {
        background-color: ${localThemes.state.disabled.inactive.color};
      }
    `}

    ${props.isDisabled &&
    props.isActive &&
    css`
      &:before {
        background-color: ${localThemes.state.disabled.active.color};
      }
    `}
  `
})

type ToggleProps = Pick<StyledToggleProps, 'isDisabled' | 'align'>
export const Toggle = styled.input(
  (props: ToggleProps): FlattenSimpleInterpolation => css`
    position: absolute;
    top: calc(50% - 0.75rem);
    ${props.align}: 0;
    opacity: 0;
    margin: 0;
    width: 2.5rem;
    height: 1.5rem;
    font-size: 1rem;
    cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
  `,
)

type ToggleSliderProps = Omit<StyledToggleProps, 'hasChildren'>
export const ToggleSlider = styled.div<ToggleSliderProps>((props: ToggleSliderProps): FlattenSimpleInterpolation => {
  const localThemes = localTheme(props.theme)

  return css`
    position: absolute;
    top: calc(50% - 0.625rem);
    ${props.align}: ${props.align === 'left' ? '0.05rem' : '1.1875rem'};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${props.theme.color.monochrome1.default};
    border: 1px solid
      ${props.isDisabled ? localThemes.state.disabled.inactive.color : localThemes.appearance[props.appearance].inactive.color};
    border-radius: 50%;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;

    ${props.isActive &&
    css`
      border-color: ${props.isDisabled ? localThemes.state.disabled.active.color : localThemes.appearance[props.appearance].active.color};
      transform: translateX(1.125rem);
    `}
  `
})

type IconWrapperProps = Pick<StyledToggleProps, 'isDisabled' | 'isActive' | 'theme'>
export const IconWrapper = styled.span<IconWrapperProps>((props: IconWrapperProps): FlattenSimpleInterpolation => {
  const localThemes = localTheme(props.theme)
  return css`
    display: flex;
    align-items: center;
    svg {
      stroke-width: 1.5px;

      ${!props.isActive &&
      !props.isDisabled &&
      css`
        width: 12px;
        height: 12px;

        * {
          ${iconAppearance(localThemes.appearance.primary.inactive.color)};
        }
      `}

      ${!props.isActive &&
      props.isDisabled &&
      css`
        width: 12px;
        height: 12px;

        * {
          ${iconAppearance(localThemes.state.disabled.inactive.color)};
        }
      `}

      ${props.isActive &&
      !props.isDisabled &&
      css`
        width: 16px;
        height: 16px;

        * {
          ${iconAppearance(localThemes.appearance.primary.active.color)};
        }
      `}

      ${props.isActive &&
      props.isDisabled &&
      css`
        width: 16px;
        height: 16px;

        * {
          ${iconAppearance(localThemes.state.disabled.active.color)};
        }
      `}
    }
  `
})
