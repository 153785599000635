import React, { PureComponent, ReactNode, Fragment } from 'react'
import { withTheme } from 'styled-components'

import AnimationContainer from '@vfuk/core-animation-container'

import * as Styled from './styles/LoadingSpinner.style'

import { LoadingSpinnerProps } from './LoadingSpinner.types'
import localTheme from './themes/LoadingSpinner.theme'

export class LoadingSpinner extends PureComponent<LoadingSpinnerProps> {
  public static defaultProps: Partial<LoadingSpinnerProps> = {
    appearance: 'primary',
    size: 1,
    inverse: false,
  }

  public render(): ReactNode {
    const { svg } = localTheme(this.props.theme!)
    return (
      <Fragment>
        <AnimationContainer>
          <Styled.LoadingSpinner size={this.props.size!} appearance={this.props.appearance!} inverse={this.props.inverse!}>
            {svg}
          </Styled.LoadingSpinner>
        </AnimationContainer>
      </Fragment>
    )
  }
}

export default withTheme(LoadingSpinner)
