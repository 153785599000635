import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Heading.style'

import { HeadingProps } from './Heading.types'

const Heading: FC<HeadingProps> = ({
  level = 2,
  noMargin = false,
  weight = 1,
  inverse = false,
  appearance = 'primary',
  size,
  text,
  justify,
  children,
}: HeadingProps): ReactElement => {
  return (
    <Styled.Heading
      as={`h${level}` as React.ElementType}
      level={level!}
      size={size}
      justify={justify}
      appearance={appearance!}
      inverse={inverse!}
      weight={weight}
      noMargin={noMargin!}
    >
      <Choose>
        <When condition={text}>{text}</When>
        <Otherwise>{children}</Otherwise>
      </Choose>
    </Styled.Heading>
  )
}

export default Heading
