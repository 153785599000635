import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Heading } from '@vfuk/core-heading/dist/styles/Heading.style'
import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'

import { StyledCardBodyProps } from './CardBody.style.types'

export const CardBody = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${props.theme.mixins.spacing('padding', 3)}
`)

type ContentWrapperHeaderProps = Pick<StyledCardBodyProps, 'theme' | 'hasChevron'>
export const ContentWrapperHeader = styled.div((props: ContentWrapperHeaderProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('margin-bottom', 3)};

  ${props.hasChevron && css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row-reverse;
  `}

  ${Heading} {
    ${props.hasChevron && css`
      ${props.theme.mixins.spacing('margin-right', 3)};
    `}
  }

  ${Icon} {
    margin-left: auto;
  }
`)

type ContentWrapperProps = Pick<StyledCardBodyProps, 'theme'>
export const ContentWrapper = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  flex-grow: 1;
  ${props.theme.mixins.spacing('margin-bottom', 5)};

  &:last-child {
    margin-bottom: 0;
  }
`)

type ButtonWrapperProps = Pick<StyledCardBodyProps, 'theme' | 'centerButtons'>
export const ButtonWrapper = styled.div((props: ButtonWrapperProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('margin-bottom', 3)};
  ${props.theme.mixins.spacing('gap', 3)};
  display: flex;
  flex-direction: column;

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
    justify-content: ${props.centerButtons ? 'center' : 'left'};
  `)}
`)
