import React, { FC, ReactElement } from 'react'

import Button from '@vfuk/core-button'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import Heading from '@vfuk/core-heading'

import * as Styled from './styles/BannerBody.style'

import { BannerBodyProps } from './BannerBody.types'

const BannerBody: FC<BannerBodyProps> = ({
  justify = 'center',
  inverse = false,
  heading,
  text,
  primaryButton,
  secondaryButton,
  footnote,
}: BannerBodyProps): ReactElement => {
  return (
    <Styled.BannerBody justify={justify!} inverse={inverse!}>
      <Styled.BannerContent>
        <Heading level={heading.level} text={heading.text} weight={heading.weight} inverse={inverse!} size={3} />
        <If condition={text}>
          <Paragraph inverse={inverse!}>{text}</Paragraph>
        </If>
      </Styled.BannerContent>
      <If condition={primaryButton?.text || secondaryButton?.text}>
        <Styled.BannerButtons justify={justify!}>
          <If condition={primaryButton?.text}>
            <Button
              text={primaryButton!.text}
              onMouseDown={primaryButton!.onMouseDown}
              onMouseUp={primaryButton!.onMouseUp}
              onTouchStart={primaryButton!.onTouchStart}
              onTouchEnd={primaryButton!.onTouchEnd}
              href={primaryButton!.href}
              onClick={primaryButton!.onClick}
              appearance={primaryButton!.appearance}
              inverse={primaryButton!.inverse}
              htmlAttributes={primaryButton!.htmlAttributes}
              customRouterProps={primaryButton?.customRouterProps}
            />
          </If>
          <If condition={secondaryButton?.text}>
            <Button
              text={secondaryButton!.text}
              onMouseDown={secondaryButton!.onMouseDown}
              onMouseUp={secondaryButton!.onMouseUp}
              onTouchStart={secondaryButton!.onTouchStart}
              onTouchEnd={secondaryButton!.onTouchEnd}
              href={secondaryButton!.href}
              onClick={secondaryButton!.onClick}
              appearance={secondaryButton!.appearance}
              inverse={secondaryButton!.inverse}
              htmlAttributes={secondaryButton!.htmlAttributes}
              customRouterProps={secondaryButton?.customRouterProps}
            />
          </If>
        </Styled.BannerButtons>
      </If>
      <If condition={footnote}>
        <Styled.BannerFootnote>
          <If condition={footnote!.icon && footnote!.icon!.name}>
            <Icon
              name={footnote!.icon!.name}
              appearance={footnote!.icon!.appearance!}
              inverse={footnote!.icon!.inverse!}
              size={5}
              group={footnote!.icon!.group}
            />
          </If>
          <Paragraph size={1} inverse={inverse}>
            {footnote!.text}
          </Paragraph>
        </Styled.BannerFootnote>
      </If>
    </Styled.BannerBody>
  )
}

export default BannerBody
