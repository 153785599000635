import React, { ReactElement, FC, memo } from 'react'

import { v4 as uuid } from 'uuid'

import * as Styled from './styles/FunctionalPickerItem.style'

import { FunctionalPickerItemProps } from './FunctionalPickerItem.types'

const FunctionalPickerItem: FC<FunctionalPickerItemProps> = ({
  type = 'checkbox',
  groupName,
  value,
  checked,
  children,
  dataAttributes,
  id,
  inverse,
  level,
  onBlur,
  onChange,
  orientation = 'vertical',
  justify = 'center',
  srText,
  state,
  title,
  subTitle,
  footnote,
  size = 2,
}: FunctionalPickerItemProps): ReactElement => {
  const UUID = id || uuid()

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (state !== 'disabled' && onChange) {
      onChange(event)
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (state !== 'disabled' && onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.FunctionalPickerItemWrapper
      size={size}
      aria-label={srText}
      checked={checked}
      inverse={inverse}
      state={state}
      orientation={orientation}
    >
      <Styled.PickerInput
        type={type}
        id={UUID}
        name={groupName}
        value={value}
        checked={checked}
        {...dataAttributes}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        state={state}
        aria-disabled={state === 'disabled'}
      />
      <Styled.FunctionalPickerItemContainer orientation={orientation} justify={justify}>
        <If condition={children}>
          <Styled.ChildWrapper>{children}</Styled.ChildWrapper>
        </If>

        <Styled.TitleWrapper orientation={orientation} subTitle={subTitle} size={size} footnote={footnote} justify={justify}>
          <Styled.Title level={level} data-title={title}>
            {title}
          </Styled.Title>
          <If condition={subTitle || footnote}>
            <Styled.SubTitleWrapper orientation={orientation}>
              <If condition={subTitle}>
                <Styled.SubTitle data-subtitle={subTitle}>{subTitle}</Styled.SubTitle>
              </If>
              <If condition={footnote}>
                <Styled.Footnote data-footNote={footnote}>{footnote}</Styled.Footnote>
              </If>
            </Styled.SubTitleWrapper>
          </If>
        </Styled.TitleWrapper>
      </Styled.FunctionalPickerItemContainer>
    </Styled.FunctionalPickerItemWrapper>
  )
}

export default memo(FunctionalPickerItem)
