import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

export const IconCard = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    ${spacing('padding-bottom', 3)};
  `,
)
