import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/ContentImage.style'

import { ContentImageProps } from './ContentImage.types'

const ContentImage: FC<ContentImageProps> = ({ imagePosition, image }: ContentImageProps): ReactElement => (
  <Styled.ImageWrapper imagePosition={imagePosition} image={image} />
)

export default ContentImage
