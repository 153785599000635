import React, { ReactNode, PureComponent } from 'react'
import { withTheme } from 'styled-components'

import Animate from '@vfuk/core-animate'
import Icon from '@vfuk/core-icon'

import * as Styled from './styles/Toggle.style'

import { ToggleProps, ToggleState } from './Toggle.types'

import localTheme from './theme/Toggle.theme'

export class Toggle extends PureComponent<ToggleProps> {
  public static defaultProps: Partial<ToggleProps> = {
    appearance: 'primary',
    showIcons: true,
    align: 'right',
  }

  public state: ToggleState = {
    hasAnimated: this.props.isActive,
  }

  private handleAnimationDone = (): void => {
    this.setState({
      hasAnimated: !this.state.hasAnimated,
    })
  }

  private handleEvent = (event: React.ChangeEvent<HTMLInputElement>, callback: ToggleProps['onChange'] | ToggleProps['onBlur']): void => {
    if (this.props.state !== 'disabled' && callback) callback(event)
  }

  public render(): ReactNode {
    const localAppearance = localTheme(this.props.theme!).appearance[this.props.appearance!]
    const additionalToggleProps = !this.props.children ? { 'aria-label': this.props.srText } : {}

    return (
      <Styled.ToggleWrapper>
        <Styled.LabelText
          htmlFor={this.props.id}
          isActive={this.props.isActive!}
          appearance={this.props.appearance!}
          isDisabled={this.props.state === 'disabled'}
          hasChildren={!!this.props.children}
          align={this.props.align!}
        >
          <If condition={this.props.children && !this.props.srText}>{this.props.children}</If>
          <Styled.ToggleSlider
            align={this.props.align!}
            appearance={this.props.appearance!}
            isActive={this.props.isActive!}
            isDisabled={this.props.state === 'disabled'}
          >
            <If condition={this.props.showIcons}>
              <Animate
                show={this.props.isActive && this.state.hasAnimated}
                enter={{
                  animations: ['fadeIn'],
                  duration: 100,
                }}
                exit={{
                  animations: ['fadeOut'],
                  duration: 100,
                  onDone: this.handleAnimationDone,
                }}
              >
                <Styled.IconWrapper isActive={this.props.isActive!} isDisabled={this.props.state === 'disabled'}>
                  <Icon name={localAppearance.active.icon} inverse={this.props.state === 'disabled'} size={1} isResponsive={false} />
                </Styled.IconWrapper>
              </Animate>
              <Animate
                show={!this.props.isActive && !this.state.hasAnimated}
                enter={{
                  animations: ['fadeIn'],
                  duration: 100,
                }}
                exit={{
                  animations: ['fadeOut'],
                  duration: 100,
                  onDone: this.handleAnimationDone,
                }}
              >
                <Styled.IconWrapper isActive={this.props.isActive!} isDisabled={this.props.state === 'disabled'}>
                  <Icon name={localAppearance.inactive.icon} inverse={this.props.state === 'disabled'} size={1} isResponsive={false} />
                </Styled.IconWrapper>
              </Animate>
            </If>
          </Styled.ToggleSlider>
        </Styled.LabelText>
        <If condition={this.props.srText}>{this.props.children}</If>
        <Styled.Toggle
          id={this.props.id}
          checked={this.props.isActive}
          isDisabled={this.props.state === 'disabled'}
          align={this.props.align!}
          type='checkbox'
          name={this.props.name}
          required={this.props.required}
          onChange={(event): void => {
            this.handleEvent(event, this.props.onChange)
          }}
          onBlur={(event): void => {
            this.handleEvent(event, this.props.onBlur)
          }}
          value={this.props.value}
          {...this.props.dataAttributes}
          {...additionalToggleProps}
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
        />
      </Styled.ToggleWrapper>
    )
  }
}

export default withTheme(Toggle)
