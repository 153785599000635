import React, { PureComponent, ReactNode } from 'react'

import FunctionalCarousel from '@vfuk/core-functional-carousel'
import Image from '@vfuk/core-image'
import MatchMedia from '@vfuk/core-match-media'
import { withTranslation } from 'react-i18next'

import * as Styled from './styles/Gallery.style'

import { GalleryProps, GalleryState } from './Gallery.types'

export class Gallery extends PureComponent<GalleryProps, GalleryState> {
  private initialTransitionSpeed: number

  public static defaultProps: Partial<GalleryProps> = {
    arrows: {
      position: 'outside',
      shape: 'square',
    },
  }

  constructor(props: GalleryProps) {
    super(props)
    this.initialTransitionSpeed = this.props.transitionSpeed || 0
    this.state = {
      currentSlide: 0,
      transitionSpeed: this.initialTransitionSpeed,
      transitioning: false,
    }
  }

  private get transitionSpeed(): number | undefined {
    if (!this.initialTransitionSpeed) return
    return this.state.transitionSpeed
  }

  private handleGoToPagerNumber = (index: number): void => {
    const transitionSpeed = this.initialTransitionSpeed + (Math.abs(index - this.state.currentSlide) - 1) * 100
    if (!this.state.transitioning && index !== this.state.currentSlide) {
      this.setState(
        {
          currentSlide: index,
          transitioning: true,
          transitionSpeed,
        },
        () => {
          setTimeout(() => {
            this.setState({ transitioning: false })
          }, transitionSpeed)
        },
      )
    }
  }

  public render(): ReactNode {
    return (
      <Styled.Gallery orientation={this.props.orientation} arrowsPosition={this.props.arrows?.position}>
        <Styled.GalleryContainer orientation={this.props.orientation} maxWidth={this.props.maxWidth}>
          <FunctionalCarousel
            arrows={this.props.arrows}
            currentSlide={this.state.currentSlide}
            id={this.props.id}
            pager={this.props.pager}
            slidesToShow={{ xl: 1 }}
            srName={this.props.srName}
            transitionSpeed={this.transitionSpeed}
          >
            {this.props.children}
          </FunctionalCarousel>
        </Styled.GalleryContainer>
        <If condition={this.props.thumbs}>
          <MatchMedia breakpoint='md' andAbove>
            <Styled.PagerContainer orientation={this.props.orientation}>
              {this.props.thumbs?.map((thumb, index) => (
                <Styled.ImagePager
                  onClick={(): void => this.handleGoToPagerNumber(index)}
                  key={`thumbnail${index}`}
                  label={`${this.props.t!('Go to')} ${thumb.altText}`}
                >
                  <Image
                    alt={thumb.altText}
                    sm={{
                      src: thumb.src,
                    }}
                  />
                </Styled.ImagePager>
              ))}
            </Styled.PagerContainer>
          </MatchMedia>
        </If>
      </Styled.Gallery>
    )
  }
}

export default withTranslation()(Gallery)
