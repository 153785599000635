import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { IconButtonTheme } from './IconButton.theme.types'

const iconButtonLocal = (theme: Theme): IconButtonTheme => {
  const stateSuccess = {
    backgroundColor: theme.color.success.default,
    iconColor: theme.color.monochrome1.default,
    hover: {
      backgroundColor: theme.color.success.hover,
      iconColor: theme.color.monochrome1.default,
    },
    pressed: {
      backgroundColor: theme.color.success.pressed,
      iconColor: theme.color.monochrome1.default,
    },
    inverse: {
      backgroundColor: theme.inverseColor.success.default,
      iconColor: theme.color.monochrome1.default,
      hover: {
        backgroundColor: theme.inverseColor.success.hover,
        iconColor: theme.color.monochrome1.default,
      },
      pressed: {
        backgroundColor: theme.inverseColor.success.pressed,
        iconColor: theme.color.monochrome1.default,
      },
    },
  }
  const state = {
    success: stateSuccess,
    selected: stateSuccess,
  }
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.primary1.default,
        iconColor: theme.color.monochrome6.default,
        border: 'transparent',
        iconAppearance: 'primary',
        loadingSpinnerAppearance: 'primary',
        inverseIcon: false,
        hover: {
          backgroundColor: theme.color.primary1.hover,
          iconColor: theme.color.monochrome1.default,
          border: 'transparent',
        },
        pressed: {
          backgroundColor: theme.color.primary1.pressed,
          iconColor: theme.color.monochrome1.default,
          border: 'transparent',
        },
        inverse: {
          backgroundColor: theme.inverseColor.primary1.default,
          iconColor: theme.color.monochrome6.default,
          border: 'transparent',
          iconAppearance: 'primary',
          inverseIcon: false,
          hover: {
            backgroundColor: theme.inverseColor.primary1.hover,
            iconColor: theme.inverseColor.monochrome6.default,
            border: 'transparent',
          },
          pressed: {
            backgroundColor: theme.inverseColor.primary1.pressed,
            iconColor: theme.inverseColor.monochrome6.default,
            border: 'transparent',
          },
        },
      },
      secondary: {
        backgroundColor: theme.color.monochrome6.default,
        iconColor: theme.color.primary1.default,
        border: 'transparent',
        iconAppearance: 'secondary',
        loadingSpinnerAppearance: 'secondary',
        inverseIcon: false,
        hover: {
          backgroundColor: theme.color.monochrome6.hover,
          iconColor: theme.color.primary1.default,
          border: 'transparent',
        },
        pressed: {
          backgroundColor: theme.color.monochrome6.pressed,
          iconColor: theme.color.monochrome6.default,
          border: 'transparent',
        },
        inverse: {
          backgroundColor: theme.inverseColor.monochrome6.default,
          iconColor: theme.color.monochrome6.default,
          border: 'transparent',
          iconAppearance: 'secondary',
          loadingSpinnerAppearance: 'primary',
          inverseIcon: false,
          hover: {
            backgroundColor: theme.inverseColor.monochrome6.hover,
            iconColor: theme.inverseColor.primary1.default,
            border: 'transparent',
          },
          pressed: {
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            iconColor: theme.inverseColor.primary1.default,
            border: 'transparent',
          },
        },
      },
      alt1: {
        backgroundColor: theme.color.monochrome1.default,
        iconColor: theme.color.monochrome6.default,
        border: theme.color.monochrome6.default,
        iconAppearance: 'primary',
        loadingSpinnerAppearance: 'primary',
        inverseIcon: false,
        hover: {
          backgroundColor: theme.color.monochrome6.hover,
          iconColor: theme.color.monochrome1.default,
          border: theme.color.monochrome6.hover,
        },
        pressed: {
          backgroundColor: theme.color.monochrome6.pressed,
          iconColor: theme.color.monochrome1.default,
          border: theme.color.monochrome6.pressed,
        },
        inverse: {
          backgroundColor: theme.inverseColor.monochrome1.default,
          iconColor: theme.inverseColor.monochrome6.default,
          border: theme.inverseColor.monochrome6.default,
          iconAppearance: 'secondary',
          loadingSpinnerAppearance: 'secondary',
          inverseIcon: false,
          hover: {
            backgroundColor: theme.inverseColor.monochrome6.hover,
            iconColor: theme.inverseColor.monochrome1.default,
            border: theme.inverseColor.monochrome6.hover,
          },
          pressed: {
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            iconColor: theme.inverseColor.monochrome1.default,
            border: theme.inverseColor.monochrome6.pressed,
          },
        },
      },
      alt2: {
        backgroundColor: theme.color.monochrome1.default,
        iconColor: theme.color.monochrome6.default,
        border: theme.color.primary1.default,
        iconAppearance: 'primary',
        loadingSpinnerAppearance: 'primary',
        inverseIcon: false,
        hover: {
          backgroundColor: theme.color.primary1.hover,
          iconColor: theme.color.monochrome1.default,
          border: theme.color.primary1.hover,
        },
        pressed: {
          backgroundColor: theme.color.primary1.pressed,
          iconColor: theme.color.monochrome1.default,
          border: theme.color.primary1.pressed,
        },
        inverse: {
          backgroundColor: theme.inverseColor.monochrome1.default,
          iconColor: theme.inverseColor.monochrome6.default,
          border: theme.inverseColor.primary1.default,
          iconAppearance: 'secondary',
          loadingSpinnerAppearance: 'secondary',
          inverseIcon: false,
          hover: {
            backgroundColor: theme.inverseColor.primary1.hover,
            iconColor: theme.inverseColor.monochrome6.default,
            border: theme.inverseColor.primary1.hover,
          },
          pressed: {
            backgroundColor: theme.inverseColor.primary1.pressed,
            iconColor: theme.inverseColor.monochrome6.default,
            border: theme.inverseColor.primary1.pressed,
          },
        },
      },
    },
    state: { ...state },
    size: {
      1: {
        iconSize: 1,
        loadingSpinnerSize: 1,
        sm: {
          buttonSize: 24,
        },
        md: {
          buttonSize: 24,
        },
      },
      2: {
        iconSize: 2,
        loadingSpinnerSize: 2,
        sm: {
          buttonSize: 32,
        },
        md: {
          buttonSize: 32,
        },
      },
      3: {
        iconSize: 2,
        loadingSpinnerSize: 2,
        sm: {
          buttonSize: 36,
        },
        md: {
          buttonSize: 36,
        },
      },
      4: {
        iconSize: 3,
        loadingSpinnerSize: 3,
        sm: {
          buttonSize: 44,
        },
        md: {
          buttonSize: 44,
        },
      },
      5: {
        iconSize: 3,
        loadingSpinnerSize: 3,
        sm: {
          buttonSize: 48,
        },
        md: {
          buttonSize: 48,
        },
      },
      6: {
        iconSize: 4,
        loadingSpinnerSize: 4,
        sm: {
          buttonSize: 56,
        },
        md: {
          buttonSize: 60,
        },
      },
    },
  }
}

export default iconButtonLocal
