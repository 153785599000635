import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { responsiveSizing, spacing } from '@vfuk/core-mixins'

import { StyledProgressBarProps } from './ProgressBar.style.types'

import localThemes from '../themes/ProgressBar.theme'

type StyledProgressContainer = Pick<StyledProgressBarProps, 'inverse' | 'topText'>
export const ProgressBarContainer = styled.div<StyledProgressContainer>(
  (props: StyledProgressContainer): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-end;
    ${spacing('gap', 3)};

    ${!props.topText?.left &&
    !props.topText?.right &&
    css`
      align-items: center;
    `}
  `,
)

export const ProgressBarSection = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${spacing('gap', 2)};
  `,
)

type StyledProgressBarTrack = Pick<StyledProgressBarProps, 'theme' | 'inverse'>
export const ProgressBarTrack = styled.div<StyledProgressBarTrack>(
  (props: StyledProgressBarTrack): FlattenSimpleInterpolation => css`
    display: flex;
    width: 100%;
    ${responsiveSizing('height', 2)}
    overflow: hidden;
    background-color: ${props.theme.color.monochrome3.default};
    border-radius: ${props.theme.border.radius[3]};

    ${props.inverse &&
    css`
      border-color: ${props.theme.inverseColor.monochrome3.default};
    `}
  `,
)

type StyledProgressBar = Pick<StyledProgressBarProps, 'theme' | 'inverse' | 'width' | 'appearance'>
export const ProgressBar = styled.div<StyledProgressBar>((props: StyledProgressBar): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme!)
  return css`
    display: inline-flex;
    width: ${props.width}%;
    flex-direction: column;
    justify-content: center;
    background-color: ${localTheme.appearance[props.appearance!].backgroundColor};
    border-right: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color.monochrome2.default};

    ${props.inverse &&
    css`
      background-color: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
      border-color: ${props.theme.inverseColor.monochrome2.default};
    `}

    ${props.width >= 100 &&
    css`
      border: none;
    `}
  `
})

export const TopText = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: space-between;
    ${spacing('gap', 2)}
  `,
)

export const IconWithBackgroundWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: inline-flex;
  `,
)
