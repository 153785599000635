import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { InitialsAvatarTheme } from './InitialsAvatar.theme.types'

const initialsAvatarLocal = (theme: Theme): InitialsAvatarTheme => {
  return {
    backgroundColor: theme.color.secondary1.default,
    textColor: theme.color.monochrome1.default,
    size: {
      1: {
        sm: theme.typography.paragraph[1].fontMobile,
        md: theme.typography.paragraph[1].fontTablet,
        lg: theme.typography.paragraph[1].fontDesktop,
      },
      2: {
        sm: theme.typography.paragraph[1].fontMobile,
        md: theme.typography.paragraph[1].fontTablet,
        lg: theme.typography.paragraph[1].fontDesktop,
      },
      3: {
        sm: theme.typography.heading[2].fontMobile,
        md: theme.typography.heading[1].fontTablet,
        lg: theme.typography.heading[1].fontDesktop,
      },
      4: {
        sm: theme.typography.heading[4].fontMobile,
        md: theme.typography.heading[2].fontTablet,
        lg: theme.typography.heading[2].fontDesktop,
      },
      5: {
        sm: theme.typography.heading[5].fontMobile,
        md: theme.typography.heading[4].fontTablet,
        lg: theme.typography.heading[3].fontDesktop,
      },
      6: {
        sm: theme.typography.heading[5].fontMobile,
        md: theme.typography.heading[4].fontTablet,
        lg: theme.typography.heading[3].fontDesktop,
      },
    },
  }
}

export default initialsAvatarLocal
