import React, { FC } from 'react'

import IconWithBackground from '@vfuk/core-icon-with-background'
import IconButton from '@vfuk/core-icon-button'

import * as Styled from './styles/SliderIcon.style'

import { SliderIconProps } from './SliderIcon.types'

export const SliderIcon: FC<SliderIconProps> = ({ min, max, value, onChange, state, increment, icon, position, srLabel }) => {
  const handleIconButtonChange = (): void => {
    if (Array.isArray(value)) return

    if (position === 'right' && value < max) {
      onChange(value + increment!, 'rightIcon')
    }

    if (position === 'left' && value > min) {
      onChange(value - increment!, 'leftIcon')
    }
  }

  const isButtonDisabled = (): 'disabled' | undefined => {
    if (state === 'disabled') return 'disabled'

    if (position === 'left' && value <= min) return 'disabled'

    if (position === 'right' && value >= max) return 'disabled'

    return undefined
  }

  return (
    <Styled.IconWrapper position={position}>
      <Choose>
        <When condition={icon.interactive}>
          <IconButton
            icon={{ name: icon.name }}
            shape='square'
            size={2}
            appearance='alt1'
            state={isButtonDisabled()}
            onClick={handleIconButtonChange}
            srText={position === 'left' ? srLabel.leftLabel! : srLabel.rightLabel!}
          />
        </When>
        <Otherwise>
          <IconWithBackground icon={{ name: icon.name }} shape='square' size={2} appearance='alt3' />
        </Otherwise>
      </Choose>
    </Styled.IconWrapper>
  )
}

export default SliderIcon
