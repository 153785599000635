import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { backgroundImage, respondTo } from '@vfuk/core-mixins'

import { StyledFunctionalAvatarProps } from './FunctionalAvatar.style.types'

import localTheme from '../themes/FunctionalAvatar.theme'

export const FunctionalAvatar = styled(Interaction)((props: StyledFunctionalAvatarProps): FlattenSimpleInterpolation => {
  return css`
    position: relative;
    display: inline-flex;
    width: ${localTheme.size[props.size!].sm}px;
    height: ${localTheme.size[props.size!].sm}px;
    border-radius: 100%;
    transition: 0.4s all ease;

    ${respondTo.md(css`
      width: ${localTheme.size[props.size!].md}px;
      height: ${localTheme.size[props.size!].md}px;
    `)}

    ${respondTo.lg(css`
      width: ${localTheme.size[props.size!].lg}px;
      height: ${localTheme.size[props.size!].lg}px;
    `)}

    ${props.backgroundImage &&
    css`
      ${backgroundImage(props.backgroundImage)}
    `}

    ${props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      transition: 0.4s all ease;
    }

    ${(props.onClick || props.customRouterProps || props.href) &&
    css`
      &:hover::after {
        background: ${props.theme.color.opacity4.default};
      }

      &:active::after {
        background: ${props.theme.color.opacity5.default};
      }
    `}
  `
})

export const ChildrenWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
)
