import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { HeadingTheme } from './Heading.theme.types'

const headingLocal = (theme: Theme): HeadingTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.primary2.default,
        inverse: {
          color: theme.inverseColor.primary2.default,
        },
      },
      secondary: {
        color: theme.color.monochrome1.default,
        inverse: {
          color: theme.inverseColor.monochrome4.default,
        },
      },
    },
  }
}

export default headingLocal
