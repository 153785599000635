import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import WS10 from './Button.ws10.theme'
import VOXI from './Button.voxi.theme'

import { ButtonTheme } from './Button.theme.types'

const getLocalTheme = (theme: Theme): ButtonTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI(theme)
    case 'WS2':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
