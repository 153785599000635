import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { loggedOut } from 'actions/authenticationActions';

const Logout = ({ logout }) => {
  useEffect(() => {
    logout();
  });

  return <Redirect to="/" />;
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(loggedOut())
});

export default connect(null, mapDispatchToProps)(Logout);
