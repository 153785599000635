import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { HeadingTheme } from './Heading.theme.types'

const headingLocal = (theme: Theme): HeadingTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
        },
      },
      secondary: {
        color: theme.color.monochrome5.default,
        inverse: {
          color: theme.inverseColor.monochrome5.default,
        },
      },
    },
  }
}

export default headingLocal
