import React, { PureComponent, ReactNode } from 'react'

import { FunctionalTabPanel } from '@vfuk/core-functional-tabs'

import * as Styled from './styles/Tab.style'

import { TabProps } from './Tab.types'

export class Tab extends PureComponent<TabProps> {
  public static defaultProps: Partial<TabProps> = {
    isLazy: true,
  }

  public render(): ReactNode {
    return (
      <Styled.TabContainer>
        <FunctionalTabPanel tabId={this.props.id} isLazy={this.props.isLazy}>
          {this.props.children}
        </FunctionalTabPanel>
      </Styled.TabContainer>
    )
  }
}
