import React, { PureComponent, ReactNode } from 'react'

import capitalizeFirstCharacter from '@vfuk/core-helpers/dist/capitalizeFirstCharacter'

import { TimerBlockProps } from './TimerBlock.types'

import * as Styled from './styles/TimerBlock.style'

export default class TimerBlock extends PureComponent<TimerBlockProps> {
  public render(): ReactNode {
    const remainingTime = this.props.remainingTime.toString()
    return (
      <Styled.TimerBlock>
        <Choose>
          <When condition={this.props.remainingTime < 10}>
            <Styled.TimerNumber inverse={this.props.inverse}>0</Styled.TimerNumber>
            <Styled.TimerNumber inverse={this.props.inverse}>{this.props.remainingTime}</Styled.TimerNumber>
          </When>
          <Otherwise>
            <Styled.TimerNumber inverse={this.props.inverse}>{remainingTime.charAt(0)}</Styled.TimerNumber>
            <Styled.TimerNumber inverse={this.props.inverse}>{remainingTime.charAt(1)}</Styled.TimerNumber>
          </Otherwise>
        </Choose>
        <If condition={this.props.showUnit}>
          <Styled.TimerUnit>{capitalizeFirstCharacter(this.props.unit)}</Styled.TimerUnit>
        </If>
      </Styled.TimerBlock>
    )
  }
}
