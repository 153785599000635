import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import { StyledBottomTrayRendererProps } from './BottomTrayRenderer.style.types'

export const BottomTrayRenderer = styled.div<StyledBottomTrayRendererProps>(
  (props: StyledBottomTrayRendererProps): FlattenSimpleInterpolation => css`
    display: block;
    inset: 0;
    z-index: ${props.zIndex};
  `,
)

export const BottomTrayRendererContent = styled.div<StyledBottomTrayRendererProps>(
  (props: StyledBottomTrayRendererProps): FlattenSimpleInterpolation => css`
    min-height: 162px;
    position: fixed;
    inset: auto 0 0;
    z-index: ${props.zIndex + 1};
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    background-color: ${props.theme.color.monochrome1.default};
    color: ${props.theme.color.monochrome6.default};
    border-top-left-radius: ${props.theme.border.radius[2]};
    border-top-right-radius: ${props.theme.border.radius[2]};
    box-shadow: 0px 2px 8px 0 rgb(0 0 0 / 16%);
    ${advancedSpacing('padding', [10, 5, 5])}
  `,
)
