import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { CheckBoxTheme } from './CheckBox.theme.types'

const checkBoxLocal = (theme: Theme): CheckBoxTheme => {
  return {
    iconGroup: 'voxi',
    iconName: 'skinny-tick',
    indeterminateIconName: 'skinny-dash',
    borderColor: theme.color.primary3.default,
    state: {
      checked: {
        backgroundColor: theme.color.success.default,
      },
    },
  }
}

export default checkBoxLocal
