import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { FunctionalCarouselTheme } from './FunctionalCarousel.theme.types'

const getSharedThemeValues = (theme: Theme): Omit<FunctionalCarouselTheme, 'cardBoxShadowOffset'> => {
  return {
    pagerDot: {
      appearance: {
        primary: {
          backgroundColor: theme.color.monochrome3.default,
          active: {
            backgroundColor: theme.color.primary1.default,
          },
          hover: {
            backgroundColor: theme.color.monochrome4.default,
          },
          inverse: {
            backgroundColor: theme.inverseColor.monochrome3.default,
            active: {
              backgroundColor: theme.inverseColor.primary1.default,
            },
            hover: {
              backgroundColor: theme.inverseColor.monochrome4.default,
            },
          },
        },
        secondary: {
          backgroundColor: theme.color.monochrome3.default,
          active: {
            backgroundColor: theme.color.secondary1.default,
          },
          hover: {
            backgroundColor: theme.color.monochrome4.default,
          },
          inverse: {
            backgroundColor: theme.inverseColor.monochrome3.default,
            active: {
              backgroundColor: theme.inverseColor.secondary1.default,
            },
            hover: {
              backgroundColor: theme.inverseColor.monochrome4.default,
            },
          },
        },
      },
    },
    pagerLoader: {
      bar: {
        color: theme.color.primary1.default,
        backgroundColor: theme.color.monochrome3.default,
      },
    },
  }
}

export default getSharedThemeValues
