import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/SelectInputContent.style'

import { SelectInputContentProps } from './SelectInputContent.types'

const SelectInputContent: FC<SelectInputContentProps> = ({ children, text }: SelectInputContentProps): ReactElement => {
  return (
    <Styled.TextContainer>
      <If condition={children}>
        <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>
      </If>
      <Styled.Text>{text}</Styled.Text>
    </Styled.TextContainer>
  )
}

export default SelectInputContent
