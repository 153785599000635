import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledCheckboxProps } from './Checkbox.style.types'

import localThemes from '../themes/CheckBox.theme'

type CheckboxProps = Pick<StyledCheckboxProps, 'theme' | 'indeterminate' | 'state'>
export const Checkbox = styled.div((props: CheckboxProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    ${spacing('margin-right', 3)}
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${localTheme.borderColor};
    text-align: center;
    background-color: ${props.theme.color.monochrome1.default};

    border-radius: ${props.theme.border.radius[1]};

    & svg {
      display: none;
    }

    ${props.indeterminate &&
    css`
      background-color: ${props.state === 'disabled' ? props.theme.color.monochrome3.default : props.theme.color.secondary1.default};
      border: none;
      & svg {
        display: inline;
        ${props.state === 'disabled' &&
        css`
          stroke: ${props.theme.color.monochrome4.default};
        `}
      }
    `}
  `
})

type CheckboxLabelProps = Pick<StyledCheckboxProps, 'theme' | 'checked' | 'state'>
export const CheckboxLabel = styled.label((props: CheckboxLabelProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: flex;
    align-items: center;
    font-size: ${props.theme.typography.paragraph[2].fontMobile};

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
      color: ${props.theme.color.monochrome5.disabled};

      ${Checkbox} {
        background-color: ${props.theme.color.monochrome2.default};
        border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome5.disabled};
      }
    `}

    ${props.state &&
    props.state !== 'disabled' &&
    css`
      color: ${props.theme.color[props.state].default};
      ${Checkbox} {
        color: ${props.theme.color[props.state].default};
        border-color: ${props.theme.color[props.state].default};
      }
    `}

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:focus ~ ${Checkbox} {
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
        outline: 3px solid transparent;
      }

      &:checked ~ ${Checkbox} {
        background-color: ${props.state === 'disabled' ? props.theme.color.monochrome3.default : localTheme.state.checked.backgroundColor};
        border: none;
        & svg {
          display: inline;
          ${props.state === 'disabled' &&
          css`
            stroke: ${props.theme.color.monochrome4.default};
          `}
        }
      }
    }

    ${respondTo.md(css`
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
    `)}
  `
})
