import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { advancedSpacing, respondTo, spacing } from '@vfuk/core-mixins'

import { StyledFunctionalCardPanelProps } from './FunctionalCardPanel.style.types'

export const FunctionalCardPanel = styled(Interaction)(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    border-radius: ${props.theme.border.radius[2]};
    overflow: hidden;
    width: 100%;
  `,
)

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('padding-right', 3)};
    display: flex;
    align-items: flex-start;

    ${respondTo.md(css`
      align-items: center;
    `)}
  `,
)

export const InfoWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${respondTo.md(css`
      flex-wrap: nowrap;
    `)}
  `,
)

export const InformationSection = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    justify-content: center;
  `,
)

export const PillWrapper = styled.div(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    display: flex;
    padding-top: ${props.theme.spacing.responsive[2].sm}px;
    flex-shrink: 0;

    ${respondTo.md(css`
      padding-top: 0;
    `)}
  `,
)

export const ChildWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [0, 3])};
  `,
)

export const CardBody = styled.div(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    ${!props.noPadding &&
    css`
      ${spacing('padding', 3)};
    `}

    ${respondTo.md(css`
      flex-direction: row;
    `)}
  `,
)

export const ButtonWrapper = styled.div(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    width: 100%;
    padding-top: ${props.theme.spacing.responsive[2].sm}px;
    flex-grow: 1;
    flex-shrink: 0;

    ${respondTo.md(css`
      padding-top: 0;
      padding-left: ${props.theme.spacing.responsive[5].md}px;
      width: auto;
    `)}

    ${respondTo.lg(css`
      padding-left: ${props.theme.spacing.responsive[5].lg}px;
    `)}

  ${respondTo.xl(css`
      padding-left: ${props.theme.spacing.responsive[5].xl}px;
    `)}
  `,
)

export const FootnoteWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    ${spacing('padding-left', 3)};
  `,
)

export const SecondaryTextWrapper = styled.div(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-start;
    margin-left: auto;
    margin-top: ${props.theme.spacing.responsive[2].sm}px;
    width: 100%;

    ${respondTo.md(css`
      padding-left: ${props.theme.spacing.responsive[3].md}px;
      margin-top: 0;
      width: unset;
      align-items: center;
    `)}

    ${respondTo.lg(css`
      padding-left: ${props.theme.spacing.responsive[3].lg}px;
    `)};

    ${respondTo.xl(css`
      padding-left: ${props.theme.spacing.responsive[3].xl}px;
    `)};
  `,
)

export const LinkCardSection = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    width: 100%;

    ${respondTo.md(css`
      justify-content: flex-start;
    `)}
  `,
)

export const HeadingAreaWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `,
)

export const HeadingWrapper = styled.div(
  (props: StyledFunctionalCardPanelProps): FlattenSimpleInterpolation => css`
    text-align: left;
    width: 100%;

    ${respondTo.md(css`
      width: unset;
      padding-right: ${props.theme.spacing.responsive[3].md}px;
    `)}

    ${respondTo.lg(css`
      padding-right: ${props.theme.spacing.responsive[3].lg}px;
    `)};

    ${respondTo.xl(css`
      padding-right: ${props.theme.spacing.responsive[3].xl}px;
    `)};
  `,
)

export const RightIconSection = styled.div(
  (): FlattenSimpleInterpolation => css`
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    ${spacing('padding-left', 3)};

    ${respondTo.md(css`
      align-items: center;
    `)}
  `,
)

export const TextWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('padding-top', 2)};
  `,
)
