import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { respondTo } from '@vfuk/core-mixins'

import { StyledColorSwatchProps } from './ColorSwatch.style.types'

import localThemes from '../themes/ColorSwatch.theme'

export const ColorSwatch = styled.span((props: StyledColorSwatchProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: inline-block;
    position: relative;
    background: ${props.color};
    border-radius: 50%;
    width: ${localTheme.size[props.size].swatch.sm}px;
    height: ${localTheme.size[props.size].swatch.sm}px;

    ${props.lowContrast &&
    css`
      border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome4.default};

      ${props.inverse &&
      css`
        border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.inverseColor.monochrome4.default};
      `}
    `}

    ${respondTo.md(css`
      width: ${localTheme.size[props.size].swatch.md}px;
      height: ${localTheme.size[props.size].swatch.md}px;
    `)}

    ${respondTo.lg(css`
      width: ${localTheme.size[props.size].swatch.lg}px;
      height: ${localTheme.size[props.size].swatch.lg}px;
    `)}

    ${Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `
})
