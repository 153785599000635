import React, { PureComponent, ReactNode } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'

import RadioButtonListFieldSet from './components/RadioButtonListFieldSet'

import { RadioButtonListProps } from './RadioButtonList.types'

export default class RadioButtonList extends PureComponent<RadioButtonListProps> {
  public static defaultProps: Partial<RadioButtonListProps> = {
    orientation: 'vertical',
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        label={this.props.fieldWrapper.label}
        showLabel={this.props.fieldWrapper.showLabel}
        state={this.props.state}
        helpText={this.props.fieldWrapper.helpText}
        helpLink={this.props.fieldWrapper.helpLink}
        subText={this.props.fieldWrapper.subText}
        stateText={this.props.fieldWrapper.stateText}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        width={this.props.fieldWrapper.width}
        renderAsLabel={false}
        formElementId={this.props.radioButtons.id}
      >
        <RadioButtonListFieldSet
          label={this.props.fieldWrapper.label}
          showLabel={this.props.fieldWrapper.showLabel}
          radioButtons={this.props.radioButtons}
          orientation={this.props.orientation}
        />
      </FieldWrapper>
    )
  }
}
