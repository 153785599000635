import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSpanProps } from './Span.style.types'

import localThemes from '../themes/Span.theme'

export const Span = styled.span<StyledSpanProps>((props: StyledSpanProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    color: ${localTheme.appearance[props.appearance].color};

    ${props.inverse &&
    `
      color: ${localTheme.appearance[props.appearance].inverse.color};
    `}

    ${props.weight &&
    `
      font-weight: ${props.theme.fontWeight[props.weight]};
    `}
  `
})
