import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo, spacing } from '@vfuk/core-mixins'

import { StyledCardInteractionSlotProps } from './CardInteractionSlot.style.types'

export const CardInteractionSlot = styled.div(
  (props: StyledCardInteractionSlotProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;

    ${advancedSpacing('padding', [0, 2])}

    ${respondTo.md(css`
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding-left: ${props.theme.spacing.responsive[3].md}px;
      padding-right: ${props.theme.spacing.responsive[3].md}px;
    `)}
  `,
)

export const LinkWithIconContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('padding', 2)}
  `,
)

export const ButtonsContainer = styled.div(
  (props: StyledCardInteractionSlotProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;

    & [class*='ButtonWrapper']:last-of-type {
      order: -1;
    }

    + ${LinkWithIconContainer} {
      align-self: center;
    }

    ${respondTo.md(css`
      flex-direction: row;
      flex-wrap: wrap-reverse;
      align-items: baseline;

      ${props.fullWidth && `flex:1;`}

      & [class*=ButtonWrapper] {
        ${props.fullWidth && `flex:1;`}
      }

      & [class*='ButtonWrapper']:last-of-type {
        order: initial;
      }
    `)}
  `,
)

export const ButtonWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('margin', [2])}
  `,
)
