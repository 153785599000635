import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { srOnly } from '@vfuk/core-mixins'

export const ClickableTab = styled.button`
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;

  & > * {
    cursor: pointer;
  }
`

export const InvisibleSpan = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
