import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { FieldWrapperTheme } from './FieldWrapper.theme.types'

const fieldWrapperLocal = (theme: Theme): FieldWrapperTheme => {
  return {
    state: {
      error: {
        color: theme.color.error.default,
      },
    },
  }
}

export default fieldWrapperLocal
