import React, { ReactElement, FC } from 'react'
import Paragraph from '@vfuk/core-paragraph'
import Icon from '@vfuk/core-icon'

import * as Styled from './styles/FilterList.style'

import { FilterListProps } from './FilterList.types'

const FilterList: FC<FilterListProps> = ({
  label,
  groupName,
  filterItems,
  selected,
  size = 2,
  appearance = 'primary',
  inverse = false,
  onChange,
  onBlur,
}: FilterListProps): ReactElement => {
  const inputType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, state?: string): void => {
    if (state !== 'disabled' && onChange) {
      onChange(event)
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>, state?: string): void => {
    if (state !== 'disabled' && onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.FilterList>
      <Styled.Legend>{label}</Styled.Legend>
      {filterItems?.map((filterItem) => {
        const isSelected = inputType === 'radio' ? selected === filterItem.value : selected.includes(filterItem.value)
        return (
          <Styled.FilterItem
            key={filterItem.id}
            checked={isSelected}
            appearance={appearance}
            state={filterItem.state}
            size={size}
            inverse={inverse}
            aria-label={filterItem.srText || filterItem.title}
            imgSet={filterItem.imageSet}
          >
            <Styled.FilterInput
              name={groupName}
              value={filterItem.value}
              checked={isSelected}
              type={inputType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChangeHandler(e, filterItem.state)}
              onBlur={(e: React.FocusEvent<HTMLInputElement>): void => onBlurHandler(e, filterItem.state)}
              {...filterItem.dataAttributes}
              state={filterItem.state}
              aria-disabled={filterItem.state === 'disabled'}
            />
            <If condition={filterItem.imageSet?.src}>
              <Styled.Span size={size} imgSet={filterItem.imageSet} checked={isSelected} aria-label={filterItem.imageSet?.alt} role='img' />
            </If>
            <If condition={filterItem.icon?.name}>
              <Icon
                name={filterItem.icon?.name}
                group={filterItem.icon?.group}
                inverse={isSelected ? !inverse : inverse}
                appearance={'primary'}
                size={size}
              />
            </If>
            <Paragraph size={size}>{filterItem.title}</Paragraph>
          </Styled.FilterItem>
        )
      })}
    </Styled.FilterList>
  )
}

export default FilterList
