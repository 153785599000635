import React, { FC } from 'react'

import * as Styled from './styles/TopLabels.style'
import { TopLabelsProps } from './TopLabels.types'

const TopLabels: FC<TopLabelsProps> = ({ labels }) => (
  <Styled.Labels>
    <If condition={labels?.left}>
      <Styled.LeftLabel isRight={!!labels?.right} isLeft={!!labels?.left}>
        {labels?.left}
      </Styled.LeftLabel>
    </If>
    <If condition={labels?.center}>
      <Styled.CenterLabel isRight={!!labels?.right} isLeft={!!labels?.left}>
        {labels?.center}
      </Styled.CenterLabel>
    </If>
    <If condition={labels?.right}>
      <Styled.RightLabel>{labels?.right}</Styled.RightLabel>
    </If>
  </Styled.Labels>
)

export default TopLabels
