import React, { FC, useState, useEffect } from 'react'

import TextInput from '@vfuk/core-text-input'

import * as Styled from './styles/SliderTextInput.style'

import { SliderTextInputProps } from './SliderTextInput.types'

import isTextInputValueIncorrect from './utils/isTextInputValueIncorrect'

export const SliderTextInput: FC<SliderTextInputProps> = ({
  onInvalidInputEntry,
  allowNonIncrementValue,
  min,
  max,
  increment,
  value,
  prefix,
  suffix,
  id,
  state,
  onChange,
  textInputPosition,
  srLabel,
}) => {
  const [textInputValue, setTextInputValue] = useState<string>('')
  const arrayIndex: number = textInputPosition === 'left' ? 0 : 1
  const trigger = 'textInput'

  useEffect((): void => {
    if (Array.isArray(value)) {
      setTextInputValue(`${value[arrayIndex]}`)
      return
    }
    setTextInputValue(`${value}`)
  }, [value])

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value
    const numericInputValue = Number(inputValue)
    if (inputValue.length > 4) return
    // Update the local state of the text input regardless of whether valid or not
    setTextInputValue(inputValue)

    // If there is an invalid entry then call onInvalidInputEntry function
    if (
      onInvalidInputEntry &&
      isTextInputValueIncorrect(numericInputValue, allowNonIncrementValue, min, max, increment, value, textInputPosition)
    ) {
      onInvalidInputEntry(numericInputValue)
      return
    }

    // If the original value set is an array, then need to create a modification of the original array
    if (Array.isArray(value)) {
      const newValueArray = [...value]
      newValueArray[arrayIndex] = numericInputValue
      onChange(newValueArray, trigger)
      return
    }

    // Otherwise fire normal on change
    onChange(numericInputValue, trigger)
  }

  return (
    <Styled.TextInputContainer textInputPosition={textInputPosition} prefix={prefix} suffix={suffix}>
      <If condition={prefix}>
        <Styled.TextInputPrefix textInputPosition={textInputPosition} prefix={prefix}>
          {prefix}
        </Styled.TextInputPrefix>
      </If>
      <Styled.HiddenLabel htmlFor={id}>{srLabel}</Styled.HiddenLabel>
      <TextInput value={textInputValue} onChange={handleTextInputChange} type='number' id={id} state={state} />
      <If condition={suffix}>
        <Styled.TextInputPrefix textInputPosition={textInputPosition} suffix={suffix}>
          {suffix}
        </Styled.TextInputPrefix>
      </If>
    </Styled.TextInputContainer>
  )
}

export default SliderTextInput
