import React, { FC, ReactElement } from 'react'

import Button from '@vfuk/core-button'
import Heading from '@vfuk/core-heading'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'

import * as Styled from './styles/CardBody.style'

import { CardBodyProps } from './CardBody.types'

const CardBody: FC<CardBodyProps> = ({
  primaryButton,
  secondaryButton,
  heading,
  chevron,
  paragraph,
  centerButtons = false,
}: CardBodyProps): ReactElement => {
  return (
    <Styled.CardBody>
      <Styled.ContentWrapper>
        <Styled.ContentWrapperHeader hasChevron={!!chevron}>
          <If condition={chevron}>
            <Icon {...chevron} name='chevron-right' size={3} group='system' />
          </If>
          <Heading {...heading} weight={1} />
        </Styled.ContentWrapperHeader>
        <If condition={paragraph}>
          <Paragraph inverse={paragraph!.inverse}>{paragraph!.text}</Paragraph>
        </If>
      </Styled.ContentWrapper>
      <If condition={primaryButton && primaryButton.text}>
        <Styled.ButtonWrapper centerButtons={centerButtons!}>
          <Button {...primaryButton!} />
          <If condition={secondaryButton && secondaryButton.text}>
            <Button {...secondaryButton!} />
          </If>
        </Styled.ButtonWrapper>
      </If>
    </Styled.CardBody>
  )
}

export default CardBody
