import React, { PureComponent } from 'react'

import * as Styled from './styles/GridRow.style'

import { GridRowProps } from './GridRow.types'

export default class Grid extends PureComponent<GridRowProps> {
  public static defaultProps: Partial<GridRowProps> = {
    disableFlex: false,
    noGutters: false,
  }

  public render(): JSX.Element {
    return (
      <Styled.GridRow
        alignItems={this.props.align}
        mdAlignItems={this.props.alignMd}
        lgAlignItems={this.props.alignLg}
        xlAlignItems={this.props.alignXl}
        justifyContent={this.props.justify}
        mdJustifyContent={this.props.justifyMd}
        lgJustifyContent={this.props.justifyLg}
        xlJustifyContent={this.props.justifyXl}
        direction={this.props.direction}
        directionMd={this.props.directionMd}
        directionLg={this.props.directionLg}
        directionXl={this.props.directionXl}
        noGutters={this.props.noGutters}
        disableFlex={this.props.disableFlex}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        className={this.props.className}
      >
        {this.props.children}
      </Styled.GridRow>
    )
  }
}
