import React, { PureComponent, ReactNode } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'

import { withTranslation } from 'react-i18next'

import { FieldWrapperProps } from '@vfuk/core-field-wrapper/dist/FieldWrapper.types'

import SearchTextInput from './components/SearchTextInput'

import * as Styled from './styles/SearchInput.style'

import { SearchInputProps } from './SearchInput.types'

export class SearchInput extends PureComponent<SearchInputProps> {
  private handleSubmit = (event: React.FormEvent): void => {
    if (!this.props.searchIconButton?.onClick) return
    event.preventDefault()
    this.props.searchIconButton.onClick(event)
  }

  public render(): ReactNode {
    return (
      <Styled.SearchInput hasText={!!this.props.textInput.value} width={this.props.fieldWrapper.width!}>
        <form onSubmit={this.handleSubmit}>
          <FieldWrapper
            label={this.props.fieldWrapper.label}
            width={this.props.fieldWrapper.width!}
            formElementId={this.props.textInput.id}
            helpText={this.props.fieldWrapper.helpText}
            stateText={this.props.fieldWrapper.stateText}
            showLabel={this.props.fieldWrapper.showLabel}
            validateOn={this.props.fieldWrapper.validateOn}
            state={this.props.state as FieldWrapperProps['state']}
            helpLink={this.props.fieldWrapper.helpLink}
          >
            <SearchTextInput
              label={this.props.fieldWrapper.label}
              textInput={this.props.textInput}
              state={this.props.state}
              searchIconButton={this.props.searchIconButton}
              onClear={this.props.onClear}
              handleSubmit={this.handleSubmit}
              t={this.props.t}
            />
          </FieldWrapper>
        </form>
      </Styled.SearchInput>
    )
  }
}

export default withTranslation()(SearchInput)
