import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import { StyledCardSandwichSlotProps, StyledCardSandwichSlotPropsTheme } from './CardSandwichSlot.style.types'

export const CardSandwichSlot = styled.div(
  (props: StyledCardSandwichSlotPropsTheme): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 4])}
    color: ${props.theme.color.monochrome4.default};
  `,
)

type SandwichContainerProps = Pick<StyledCardSandwichSlotProps, 'theme' | 'verticalAlign' | 'alignSecondaryPrice' | 'hasSubText'>
export const SandwichContainer = styled.div(
  (props: SandwichContainerProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: ${props.verticalAlign === 'top' ? 'flex-start' : 'flex-end'};

    ${props.hasSubText &&
    css`
      ${spacing('margin-bottom', 2)}
    `}

    ${props.alignSecondaryPrice === 'right' &&
    css`
      justify-content: space-between;
    `}
  `,
)

type SandwichWrapperProps = Pick<StyledCardSandwichSlotProps, 'theme' | 'hasSecondaryPrice' | 'verticalJustification'>
export const PriceWrapper = styled.span(
  (props: SandwichWrapperProps): FlattenSimpleInterpolation => css`
    ${props.verticalJustification &&
    css`
      align-self: ${props.verticalJustification === 'top' ? 'flex-start' : 'center'};
    `}

    &:not(:only-child) {
      ${props.hasSecondaryPrice &&
      css`
        ${spacing('margin-right', 7)}
      `}
    }
  `,
)
