import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { BlockTheme } from './Block.theme.types'

const blockLocal = (theme: Theme): BlockTheme => {
  return {
    paddingLevel: {
      0: {
        vertical: 0,
        horizontal: 0,
      },
      1: {
        vertical: 6,
        horizontal: 4,
      },
      2: {
        vertical: 10,
        horizontal: 4,
      },
    },
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
      },
      secondary: {
        backgroundColor: theme.color.monochrome5.default,
      },
      alt1: {
        backgroundColor: `linear-gradient(${theme.color.gradient1.default})`,
      },
      alt2: {
        backgroundColor: theme.color.monochrome2.default,
      },
    },
  }
}

export default blockLocal
