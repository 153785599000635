import React, { PureComponent, ReactNode } from 'react'

import ContainerWithLabel from '@vfuk/core-container-with-label'
import Paragraph from '@vfuk/core-paragraph'
import Heading from '@vfuk/core-heading'
import Button from '@vfuk/core-button'
import IconWithBackground from '@vfuk/core-icon-with-background'

import * as Styled from './styles/IconAndImageCard.style'

import { IconAndImageCardProps } from './IconAndImageCard.types'

export class IconAndImageCard extends PureComponent<IconAndImageCardProps> {
  public static defaultProps: Partial<IconAndImageCardProps> = {
    appearance: 'primary',
  }

  handleOnCardClick(): void {
    if (this.props.onCardClick) this.props.onCardClick()
  }

  get getHandleOnCardClick(): IconAndImageCardProps['onCardClick'] {
    if (this.props.state !== 'disabled' && !this.props.button?.text && this.props.onCardClick) return this.handleOnCardClick
  }

  render(): ReactNode {
    return (
      <Styled.ContainerHolder>
        <ContainerWithLabel
          appearance={this.props.appearance}
          label={this.props.label}
          onClick={this.getHandleOnCardClick}
          paddingLevel={0}
        >
          <Styled.BackgroundImage state={this.props.state} backgroundImage={this.props.image} hasButton={!!this.props.button?.text}>
            <If condition={this.props.imageText}>
              <Heading size={5} level={3} weight={3} justify='center' text={this.props.imageText} noMargin />
            </If>
            <Styled.IconWithBackgroundWrapper>
              <IconWithBackground {...this.props.iconWithBackground} appearance={this.props.appearance} />
            </Styled.IconWithBackgroundWrapper>
          </Styled.BackgroundImage>
          <Styled.Content>
            <Styled.TextContent>
              <If condition={this.props.text.prefix}>
                <Paragraph justify='left'>{this.props.text.prefix}</Paragraph>
              </If>
              <Heading size={2} level={this.props.imageText ? 4 : 2} weight={3} justify='left' text={this.props.text.main} />
              <If condition={this.props.text.suffix}>
                <Paragraph justify='left'>{this.props.text.suffix}</Paragraph>
              </If>
            </Styled.TextContent>
            <If condition={this.props.button?.text}>
              <Button {...this.props.button!} state={this.props.state} appearance={this.props.appearance} />
            </If>
          </Styled.Content>
        </ContainerWithLabel>
      </Styled.ContainerHolder>
    )
  }
}

export default IconAndImageCard
