import React, { PureComponent, ReactNode } from 'react'

import Checkbox from '@vfuk/core-checkbox'
import ListItem from '@vfuk/core-list-item'

import * as Styled from './styles/CheckboxFieldSet.style'

import { CheckboxFieldSetProps } from './CheckboxFieldSet.types'

export default class CheckboxFieldSet extends PureComponent<CheckboxFieldSetProps> {
  private handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (this.props.checkboxes.onBlur) {
      this.props.checkboxes.onBlur(event)
    }
  }

  public render(): ReactNode {
    return (
      <fieldset id={this.props.checkboxes.id} onBlur={this.handleOnBlur}>
        <Styled.Legend>{this.props.label}</Styled.Legend>
        <Styled.CheckboxList hasLabel={this.props.showLabel} orientation={this.props.orientation}>
          {this.props.checkboxes.items.map((checkbox, index: number) => {
            const allChecked = checkbox.checkboxes?.items.every((element) => element.checked) || false
            const isIndeterminate = checkbox.checkboxes?.items.some((element) => element.checked) && !allChecked
            return (
              <ListItem key={index}>
                <Checkbox
                  id={`${this.props.checkboxes.id}-${index}`}
                  name={this.props.checkboxes.groupName}
                  onChange={this.props.checkboxes.onChange}
                  required={this.props.checkboxes.required}
                  value={checkbox.value}
                  dataAttributes={checkbox.dataAttributes}
                  checked={checkbox.checkboxes?.items.length ? allChecked : checkbox.checked || false}
                  indeterminate={isIndeterminate}
                  state={checkbox.state}
                  describedBy={this.props.describedBy}
                >
                  {checkbox.label}
                </Checkbox>
                <If condition={checkbox.checkboxes}>
                  <Styled.ChildrenCheckboxes orientation={this.props.orientation}>
                    {checkbox.checkboxes!.items.map((childCheckbox, childIndex: number) => {
                      return (
                        <Styled.ChildCheckboxWrapper key={childIndex}>
                          <Checkbox
                            id={`${this.props.checkboxes.id}-${childIndex}-nested`}
                            name={this.props.checkboxes.groupName}
                            onChange={checkbox.checkboxes!.onChange}
                            required={this.props.checkboxes.required}
                            value={childCheckbox.value}
                            dataAttributes={childCheckbox.dataAttributes}
                            checked={childCheckbox.checked || false}
                            state={checkbox.state}
                            describedBy={this.props.describedBy}
                          >
                            {childCheckbox.label}
                          </Checkbox>
                        </Styled.ChildCheckboxWrapper>
                      )
                    })}
                  </Styled.ChildrenCheckboxes>
                </If>
              </ListItem>
            )
          })}
        </Styled.CheckboxList>
      </fieldset>
    )
  }
}
