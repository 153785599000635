import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import WS10 from './Container.ws10.theme'
import VOXI from './Container.voxi.theme'

import { ContainerTheme } from './Container.theme.types'

const getLocalTheme = (theme: Theme): ContainerTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI(theme)
    case 'WS2':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
