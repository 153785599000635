import { ColorSwatchTheme } from './ColorSwatch.theme.types'

const colorSwatchLocal: ColorSwatchTheme = {
  size: {
    1: {
      swatch: {
        sm: 16,
        md: 16,
        lg: 16,
      },
      icon: 1,
    },
    2: {
      swatch: {
        sm: 20,
        md: 20,
        lg: 20,
      },
      icon: 1,
    },
    3: {
      swatch: {
        sm: 20,
        md: 24,
        lg: 24,
      },
      icon: 2,
    },
    4: {
      swatch: {
        sm: 24,
        md: 32,
        lg: 32,
      },
      icon: 3,
    },
    5: {
      swatch: {
        sm: 32,
        md: 36,
        lg: 36,
      },
      icon: 4,
    },
  },
}

export default colorSwatchLocal
