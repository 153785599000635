import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

export const LinkCard = styled(Interaction)(
  (): FlattenSimpleInterpolation => css`
    text-align: left;
  `,
)

export const LinkCardContent = styled.div(
  (): FlattenSimpleInterpolation => css`
    height: 100%;
  `,
)
