import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { responsivePosition, responsiveSizing, spacing } from '@vfuk/core-mixins'

import { StyledCardHeaderProps } from './CardHeader.style.types'

export const CardHeader = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-top', 6)};

    svg {
      ${responsiveSizing('width', 11)};
      ${responsiveSizing('height', 11)};
    }
  `,
)

export const PillWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    ${responsivePosition('top', 3)};
    ${responsivePosition('right', 3)};
  `,
)

export const MarketingShoutWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 0;
    left: 0;
  `,
)

export const SecondaryImageWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 136px;
    height: 80px;
    overflow: hidden;
  `,
)

export const ImageWrapper = styled.div(
  (props: StyledCardHeaderProps): FlattenSimpleInterpolation => css`
    overflow: hidden;
    border-radius: ${props.theme.border.radius[2]} ${props.theme.border.radius[2]} 0 0;
  `,
)
