import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { TabsTheme } from './Tabs.theme.types'

const tabsLocal = (theme: Theme): TabsTheme => {
  return {
    appearance: {
      primary: {
        textColor: theme.color.monochrome5.default,
        activeUnderlineColor: theme.color.primary1.default,
        inverse: {
          textColor: theme.inverseColor.monochrome5.default,
          activeUnderlineColor: theme.inverseColor.primary1.default,
        },
      },
      secondary: {
        textColor: theme.color.monochrome5.default,
        activeUnderlineColor: theme.color.monochrome5.default,
        inverse: {
          textColor: theme.inverseColor.monochrome5.default,
          activeUnderlineColor: theme.inverseColor.monochrome5.default,
        },
      },
    },
  }
}

export default tabsLocal
