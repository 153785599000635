import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Span from '@vfuk/core-span'
import { AppearanceKeys as SpanAppearanceKeys } from '@vfuk/core-span/dist/themes/Span.theme.types'

import * as Styled from './styles/Sandwich.style'
import { SandwichProps } from './Sandwich.types'

import localTheme from './themes/Sandwich.theme'

class Sandwich extends PureComponent<SandwichProps> {
  public static defaultProps: Partial<SandwichProps> = {
    appearance: 'primary',
    negative: false,
    level: 1,
    inverse: false,
    justify: 'left',
    htmlAttributes: {},
  }

  private getAppearance(
    text: 'prefixAppearance' | 'mainAppearance' | 'mainSuffixAppearance' | 'suffixAppearance' | 'suffix2Appearance',
  ): SpanAppearanceKeys {
    const appearance = localTheme.appearance[this.props.appearance!].level[this.props.level!]
    if (this.props.inverse) {
      return appearance.inverse[text]
    }
    return appearance[text]
  }

  public render(): ReactNode {
    const appearance = localTheme.appearance[this.props.appearance!].level[this.props.level!]
    return (
      <Styled.Sandwich
        justify={this.props.justify!}
        {...this.props.htmlAttributes!.dataAttributes}
        noPrefix={!this.props.text.prefix && !this.props.text.suffix}
      >
        <If condition={this.props.text.prefix}>
          <Styled.TextWrapper
            size={this.props.size}
            appearance={this.getAppearance('prefixAppearance')}
            weight={appearance.subTextWeight}
            inverse={this.props.inverse}
            display='block'
            textType='supportingText'
          >
            {this.props.text.prefix}
          </Styled.TextWrapper>
        </If>

        <Styled.MainContent size={this.props.size}>
          <If condition={this.props.negative}>
            <Styled.TextWrapper
              appearance={this.getAppearance('mainAppearance')}
              display='inline'
              inverse={this.props.inverse}
              weight={appearance.signWeight}
              size={this.props.size}
              textType='sign'
            >
              -
            </Styled.TextWrapper>
          </If>
          <If condition={this.props.unit}>
            <Styled.TextWrapper
              appearance={this.getAppearance('mainAppearance')}
              display='inline'
              inverse={this.props.inverse}
              weight={appearance.currencyWeight}
              size={this.props.size}
              textType='currency'
            >
              {this.props.unit}
            </Styled.TextWrapper>
          </If>
          <Span
            appearance={this.getAppearance('mainAppearance')}
            weight={appearance.mainTextWeight}
            display='inline'
            inverse={this.props.inverse}
          >
            {this.props.text.main}
          </Span>
          <If condition={this.props.text.mainSuffix}>
            <Styled.TextWrapper
              appearance={this.getAppearance('mainSuffixAppearance')}
              display='inline'
              inverse={this.props.inverse}
              weight={appearance.subTextWeight}
              size={this.props.size}
              textType='supportingText'
            >
              {` ${this.props.text.mainSuffix}`}
            </Styled.TextWrapper>
          </If>
        </Styled.MainContent>

        <If condition={this.props.text.suffix}>
          <Styled.TextWrapper
            size={this.props.size}
            appearance={this.getAppearance('suffixAppearance')}
            weight={appearance.subTextWeight}
            display='block'
            inverse={this.props.inverse}
            textType='supportingText'
          >
            {this.props.text.suffix}
          </Styled.TextWrapper>
        </If>

        <If condition={this.props.text.suffix2}>
          <Styled.TextWrapper
            size={this.props.size}
            appearance={this.getAppearance('suffix2Appearance')}
            weight={appearance.subTextWeight}
            display='block'
            inverse={this.props.inverse}
            textType='supportingText'
          >
            {this.props.text.suffix2}
          </Styled.TextWrapper>
        </If>
      </Styled.Sandwich>
    )
  }
}

export default withTheme(Sandwich)
