/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, applyFocus, opacity, spacing, typography } from '@vfuk/core-mixins'

import { StyledFunctionalPickerItemProps } from './FunctionalPickerItem.style.types'

export const ChildWrapper = styled.span((): FlattenSimpleInterpolation => [])

type StyledFootnote = Pick<StyledFunctionalPickerItemProps, 'theme'>
export const Footnote = styled.span<StyledFootnote>(
  (props: StyledFootnote): FlattenSimpleInterpolation => css`
    ${typography.paragraph(1, false)};

    &::after {
      content: attr(data-footNote);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.theme.fontWeight[3]};

      @media speech {
        display: none;
      }
    }
  `,
)

type TitleWrapperProps = Pick<StyledFunctionalPickerItemProps, 'orientation' | 'size' | 'justify' | 'footnote' | 'subTitle'>
export const TitleWrapper = styled.span<TitleWrapperProps>(
  (props: TitleWrapperProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 4, 4])};
    text-align: center;

    &:only-child {
      ${spacing('padding', 4)};
    }

    ${props.size === 1 &&
    css`
      &:only-child {
        ${advancedSpacing('padding', [1, 2])};
      }
    `}

    ${props.footnote &&
    css`
      width: 100%;

      ${!props.subTitle &&
      props.orientation === 'horizontal' &&
      css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      `}
    `}

  ${props.orientation === 'horizontal' &&
    css`
      text-align: left;
      ${advancedSpacing('padding', [2, 4, 2, 2])};
    `}

  ${props.justify === 'left' &&
    props.orientation === 'vertical' &&
    css`
      text-align: left;
    `}
  `,
)

type StyledTitle = Pick<StyledFunctionalPickerItemProps, 'theme' | 'level'>
export const Title = styled.span<StyledTitle>(
  (props: StyledTitle): FlattenSimpleInterpolation => css`
    ${typography.heading(1, false)}

    ${props.level === 2 &&
    `
    ${typography.paragraph(1, false)}
    font-weight: ${props.theme.fontWeight[3]};
  `}

  &::after {
      content: attr(data-title);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.theme.fontWeight[3]};

      @media speech {
        display: none;
      }
    }
  `,
)

type StyledSubTitle = Pick<StyledFunctionalPickerItemProps, 'theme'>
export const SubTitle = styled.span<StyledSubTitle>(
  (props: StyledSubTitle): FlattenSimpleInterpolation => css`
    ${typography.paragraph(1, false)};

    &::after {
      content: attr(data-subtitle);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.theme.fontWeight[3]};

      @media speech {
        display: none;
      }
    }
  `,
)

type StyledSubTitleWrapper = Pick<StyledFunctionalPickerItemProps, 'orientation'>
export const SubTitleWrapper = styled.span<StyledSubTitleWrapper>(
  (props: StyledSubTitleWrapper): FlattenSimpleInterpolation => css`
    ${props.orientation === 'horizontal' &&
    css`
      display: flex;
      justify-content: space-between;
    `}
  `,
)

type StyledFunctionalPickerItemWrapper = Pick<
  StyledFunctionalPickerItemProps,
  'theme' | 'checked' | 'inverse' | 'state' | 'orientation' | 'size'
>
export const FunctionalPickerItemWrapper = styled.label<StyledFunctionalPickerItemWrapper>(
  (props: StyledFunctionalPickerItemWrapper): FlattenSimpleInterpolation => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props.theme.color.monochrome1.default};
    color: ${props.theme.color.monochrome6.default};
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome4.default};
    text-align: center;
    height: 100%;
    border-radius: ${props.theme.border.radius[2]};

    ${props.size === 1 &&
    css`
      border-radius: ${props.theme.border.radius[1]};
    `}

    ${applyFocus()}

  ${props.orientation === 'horizontal' &&
    css`
      justify-content: flex-start;
    `}

  ${props.state !== 'disabled' &&
    css`
      &:hover {
        border-color: ${props.theme.color.monochrome5.default};

        ${Title} {
          font-weight: ${props.theme.fontWeight[3]};
        }

        ${SubTitle} {
          font-weight: ${props.theme.fontWeight[3]};
        }

        ${Footnote} {
          font-weight: ${props.theme.fontWeight[3]};
        }
      }
    `}


  ${props.checked &&
    css`
      color: ${props.theme.color.secondary1.default};
      border-color: ${props.theme.color.secondary1.default};
      box-shadow: inset 0px 0px 0px 1px ${props.theme.color.secondary1.default};

      &:hover {
        border-color: ${props.theme.color.secondary1.default};
      }

      ${Title} {
        font-weight: ${props.theme.fontWeight[3]};
      }

      ${SubTitle} {
        font-weight: ${props.theme.fontWeight[3]};
      }

      ${Footnote} {
        font-weight: ${props.theme.fontWeight[3]};
      }
    `}

  ${props.inverse &&
    css`
      background: ${props.theme.inverseColor.monochrome1.default};
      color: ${props.theme.inverseColor.monochrome6.default};
      border-color: ${props.theme.inverseColor.monochrome4.default};

      ${props.state !== 'disabled' &&
      css`
        &:hover {
          border-color: ${props.theme.inverseColor.monochrome5.default};
        }
      `}

      ${props.checked &&
      css`
        color: ${props.theme.inverseColor.secondary1.default};
        border-color: ${props.theme.inverseColor.secondary1.default};
        box-shadow: inset 0px 0px 0px 1px ${props.theme.inverseColor.secondary1.default};

        &:hover {
          border-color: ${props.theme.inverseColor.secondary1.default};
        }
      `}
    `}


  ${props.state === 'disabled' &&
    css`
      background: ${props.theme.color.monochrome1.default};
      color: ${props.theme.color.monochrome6.default};
      border-color: ${props.theme.color.monochrome4.default};
      box-shadow: none;
      cursor: not-allowed;
      ${opacity(true)};

      ${props.inverse &&
      css`
        background: ${props.theme.inverseColor.monochrome1.default};
        color: ${props.theme.inverseColor.monochrome6.default};
        border-color: ${props.theme.inverseColor.monochrome4.default};
      `}
    `}
  `,
)

type StyledPickerInput = Pick<StyledFunctionalPickerItemProps, 'state'>
export const PickerInput = styled.input<StyledPickerInput>(
  (props: StyledPickerInput): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
    `}
  `,
)

type StyledFunctionalPickerItemContainer = Pick<StyledFunctionalPickerItemProps, 'orientation' | 'justify' | 'theme'>
export const FunctionalPickerItemContainer = styled.span<StyledFunctionalPickerItemContainer>(
  (props: StyledFunctionalPickerItemContainer): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${applyFocus()}

    ${props.orientation === 'horizontal' &&
    props.justify === 'left' &&
    css`
      justify-content: flex-start;
    `}

  ${props.orientation === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: center;
    `}

  ${props.orientation === 'vertical' &&
    props.justify === 'left' &&
    css`
      align-items: flex-start;
    `}
  `,
)
