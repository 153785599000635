import React, { PureComponent } from 'react'

import * as Styled from './styles/GridColumn.style'

import { GridColumnProps } from './GridColumn.types'

export default class Grid extends PureComponent<GridColumnProps> {
  public render(): JSX.Element {
    return (
      <Styled.GridColumn
        col={this.props.col || true}
        md={this.props.colMd}
        lg={this.props.colLg}
        xl={this.props.colXl}
        offset={this.props.offset}
        mdOffset={this.props.offsetMd}
        lgOffset={this.props.offsetLg}
        xlOffset={this.props.offsetXl}
        alignSelf={this.props.alignSelf}
        mdAlignSelf={this.props.alignSelfMd}
        lgAlignSelf={this.props.alignSelfLg}
        xlAlignSelf={this.props.alignSelfXl}
        autoMargin={this.props.autoMargin}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        className={this.props.className}
      >
        {this.props.children}
      </Styled.GridColumn>
    )
  }
}
