import React, { ReactElement } from 'react'

import Snippet from '@vfuk/core-snippet'
import Icon from '@vfuk/core-icon'

import * as Styled from './styles/IconSnippet.style'

import { IconSnippetProps } from './IconSnippet.types'

export default ({
  headingText,
  text,
  appearance = 'primary',
  inverse = false,
  justify = 'left',
  button,
  icon,
  link,
}: IconSnippetProps): ReactElement => {
  return (
    <Styled.IconSnippetContainer justify={justify}>
      <Choose>
        <When condition={icon.component}>{icon.component}</When>
        <When condition={icon.name}>
          <Icon name={icon.name!} isResponsive={false} size={icon.size || 5} inverse={inverse} group={icon.group! || 'system'} />
        </When>
      </Choose>
      <Snippet
        headingText={headingText}
        text={text}
        appearance={appearance}
        inverse={inverse}
        justify={justify}
        button={button}
        link={link}
      />
    </Styled.IconSnippetContainer>
  )
}
