import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { responsiveSizing, spacing } from '@vfuk/core-mixins'

import { StyledHorizontalBarChartProps } from './HorizontalBarChart.style.types'

type StyledProgressContainer = Pick<StyledHorizontalBarChartProps, 'inverse' | 'topText' | 'theme' | 'showLabels'>
export const HorizontalBarChartContainer = styled.div<StyledProgressContainer>(
  (props: StyledProgressContainer): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-start;
    ${spacing('gap', 3)}

    ${props.inverse &&
    css`
      color: ${props.theme.inverseColor.monochrome6.default};
    `}

  ${!props.topText?.left &&
    !props.topText?.right &&
    css`
      align-items: center;
    `}

  ${!props.topText?.left &&
    !props.topText?.right &&
    props.showLabels &&
    css`
      align-items: flex-start;
    `}
  `,
)

type StyledHorizontalBarChartSection = Pick<StyledHorizontalBarChartProps, 'theme' | 'topText' | 'showLabels'>
export const HorizontalBarChartSection = styled.div<StyledHorizontalBarChartSection>(
  (props: StyledHorizontalBarChartSection): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${spacing('gap', 2)}

    ${!props.topText?.left &&
    !props.topText?.right &&
    props.showLabels &&
    css`
      ${spacing('padding-top', 3)}
    `}
  `,
)

type StyledHorizontalBar = Pick<StyledHorizontalBarChartProps, 'theme' | 'inverse'>
export const HorizontalBar = styled.div<StyledHorizontalBar>(
  (props: StyledHorizontalBar): FlattenSimpleInterpolation => css`
    display: flex;
    width: 100%;
    ${responsiveSizing('height', 2)};
    overflow: hidden;
    background-color: ${props.theme.color.monochrome3.default};
    border-radius: ${props.theme.border.radius[3]};

    ${props.inverse &&
    css`
      border-color: ${props.theme.inverseColor.monochrome3.default};
    `}
  `,
)

type StyledProgressBar = Pick<StyledHorizontalBarChartProps, 'theme' | 'inverse' | 'width' | 'color'>
export const HorizontalBarChart = styled.div<StyledProgressBar>(
  (props: StyledProgressBar): FlattenSimpleInterpolation => css`
    display: inline-flex;
    width: ${props.width}%;
    flex-direction: column;
    justify-content: center;
    background-color: ${props.color};
    border-right: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color.monochrome2.default};

    ${props.inverse &&
    css`
      border-color: ${props.theme.inverseColor.monochrome2.default};
    `}
  `,
)

type StyledTopText = Pick<StyledHorizontalBarChartProps, 'theme' | 'topText'>
export const TopText = styled.div<StyledTopText>(
  (props: StyledTopText): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: space-between;
    ${spacing('gap', 2)}

    ${!props.topText?.left &&
    !props.topText?.right &&
    css`
      display: none;
    `}
  `,
)

type StyledIconWithBackgroundWrapper = Pick<StyledHorizontalBarChartProps, 'theme' | 'topText' | 'showLabels'>
export const IconWithBackgroundWrapper = styled.div<StyledIconWithBackgroundWrapper>(
  (props: StyledIconWithBackgroundWrapper): FlattenSimpleInterpolation => css`
    display: inline-flex;
    ${spacing('padding-top', 2)};

    ${!props.topText?.left &&
    !props.topText?.right &&
    props.showLabels &&
    css`
      ${spacing('padding-top', 0)};
    `}
  `,
)

type StyledLegends = Pick<StyledHorizontalBarChartProps, 'theme' | 'inverse' | 'topText' | 'icon'>
export const Legends = styled.div<StyledLegends>(
  (props: StyledLegends): FlattenSimpleInterpolation => css`
    display: flex;
    flex-wrap: wrap;
    ${spacing('gap', 3)}

    ${props.inverse &&
    css`
      color: ${props.theme.inverseColor.monochrome6.default};
    `}
  `,
)

export const Legend = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: inline-flex;
    align-items: center;
    ${spacing('gap', 1)}
  `,
)

type StyledLegendColor = Pick<StyledHorizontalBarChartProps, 'color'>
export const LegendColor = styled.label<StyledLegendColor>(
  (props: StyledLegendColor): FlattenSimpleInterpolation => css`
    ${responsiveSizing('width', 2)};
    ${responsiveSizing('height', 2)};
    background-color: ${props.color};
    border-radius: 50%;
  `,
)

type StyledLegendKey = Pick<StyledHorizontalBarChartProps, 'theme'>
export const LegendKey = styled.label<StyledLegendKey>(
  (props: StyledLegendKey): FlattenSimpleInterpolation => css`
    font-weight: ${props.theme.fontWeight[3]};
  `,
)
