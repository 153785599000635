import IframeResizer from 'iframe-resizer-react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledIframeWrapperProps } from './IframeWrapper.style.types'

export const Iframe = styled(IframeResizer)(
  (props: StyledIframeWrapperProps): FlattenSimpleInterpolation => css`
    border: 0;
    width: ${props.width};
    height: ${props.height};
  `,
)
