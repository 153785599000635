import dayjs from 'dayjs'

import { SplitDate } from '../../DateTextInput.types'

function toStringParts(date: Date | null | 'error'): SplitDate {
  if (!date && date !== 'error') {
    return {
      day: '',
      month: '',
      year: '',
    }
  }

  const [day, month, year] = dayjs(date).format('DD/MM/YYYY').split('/')

  return {
    day,
    month,
    year,
  }
}

export default toStringParts
