import React, { FC, memo } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import { useTranslation } from 'react-i18next'

import * as Styled from './styles/CloseButton.style'

import { CloseButtonProps } from './CloseButton.types'

const CloseButton: FC<CloseButtonProps> = ({ srName, onClick, inverse }) => {
  const { t } = useTranslation()

  return (
    <Styled.CloseButton>
      <InteractiveIcon name='close' srText={`${t('Close')} ${srName}`} inverse={inverse} onClick={onClick} size={5} isResponsive={false} />
    </Styled.CloseButton>
  )
}

export default memo(CloseButton)
