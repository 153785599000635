import React, { ReactNode, PureComponent, RefObject, KeyboardEvent } from 'react'

import { withTranslation } from 'react-i18next'

import * as Styled from './styles/FunctionalTab.style'
import { FunctionalTabProps } from './FunctionalTab.types'

import { TabsContext } from '../../contexts/TabsContext'
import { TabsContextType } from '../../contexts/TabsContext.types'

export class FunctionalTab extends PureComponent<FunctionalTabProps> {
  public static contextType = TabsContext

  public context: TabsContextType

  private readonly ref: RefObject<HTMLButtonElement> = React.createRef()

  public componentDidMount(): void {
    this.context.registerTab(this.props.tabId)
  }

  private handleClick = (): void => {
    this.context.setSelectedTabId(this.props.tabId)
  }

  private handleKeyPress = (event: KeyboardEvent): void => {
    this.context.setNextTab(event)
  }

  /**
   * Uses ref to set the focus when needed.
   */
  private setFocus(): void {
    if (!this.context.needFocus || !this.ref.current) return
    this.ref.current.focus()
  }

  /**
   * Returns the string to be used inside the invisible span for screen readers.
   */
  private getScreenReaderSpanContent(): string {
    return this.props.t!('Iteration', {
      iterator: `${this.props.screenReaderText ? `${this.props.screenReaderText}, ` : ''}tab ${this.context.tabHash[this.props.tabId] + 1}`,
      array: this.context.tabsNumberTotal,
    })
  }

  public render(): ReactNode {
    const isActiveTab = this.context.selectedTabId && this.context.selectedTabId === this.props.tabId
    if (isActiveTab) this.setFocus()
    return (
      <Styled.ClickableTab
        aria-selected={!!isActiveTab}
        aria-controls={`${this.props.tabId}-tab`}
        ref={this.ref}
        role='tab'
        id={this.props.tabId}
        tabIndex={isActiveTab ? 0 : -1}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyPress}
      >
        <Styled.InvisibleSpan>{this.getScreenReaderSpanContent()}</Styled.InvisibleSpan>
        {React.cloneElement(this.props.children, { isActiveTab })}
      </Styled.ClickableTab>
    )
  }
}

export default withTranslation()(FunctionalTab)
