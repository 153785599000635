import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { RadioTheme } from './Radio.theme.types'

const radioLocal = (theme: Theme): RadioTheme => {
  return {
    borderColor: theme.color.monochrome6.default,
    checked: {
      backgroundColor: theme.color.success.default,
      borderColor: theme.color.monochrome6.default,
    },
    state: {
      disabled: {
        color: theme.color.monochrome4.default,
        backgroundColor: theme.color.monochrome1.default,
        borderColor: theme.color.monochrome4.default,
        checked: {
          backgroundColor: theme.color.monochrome4.default,
        },
      },
    },
  }
}

export default radioLocal
