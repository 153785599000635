import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SHOW_INACTIVITY_WARNING,
  HIDE_INACTIVITY_WARNING
} from 'actions/authenticationActions';

const initialState = {};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case CLEAR_LOGOUT_MESSAGE:
    case LOGOUT: {
      localStorage.removeItem('auth');
      return {};
    }
    case LOGOUT_MESSAGE: {
      localStorage.removeItem('auth');
      return { error: payload };
    }
    case SHOW_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: true
      };
    }
    case HIDE_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: false
      };
    }
    case LOGIN_ERROR: {
      return error;
    }
    case LOGGED_IN: {
      localStorage.setItem('auth', JSON.stringify(action.payload));
      return payload;
    }
    default:
      return state;
  }
};

export default reducer;
