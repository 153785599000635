import animationFadeIn from './fadeIn'
import animationFadeOut from './fadeOut'
import animationScaleUp from './scaleUp'
import animationScaleDown from './scaleDown'
import animationSlideFromBottom from './slideFromBottom'
import animationSlideFromLeft from './slideFromLeft'
import animationSlideFromRight from './slideFromRight'
import animationSlideFromTop from './slideFromTop'
import animationSlideToBottom from './slideToBottom'
import animationSlideToLeft from './slideToLeft'
import animationSlideToRight from './slideToRight'
import animationSlideToTop from './slideToTop'
import animationNone from './none'
import { Animations } from './animations.types'

export const animations: Animations = {
  fadeIn: animationFadeIn,
  fadeOut: animationFadeOut,
  scaleUp: animationScaleUp,
  scaleDown: animationScaleDown,
  slideFromBottom: animationSlideFromBottom,
  slideFromLeft: animationSlideFromLeft,
  slideFromRight: animationSlideFromRight,
  slideFromTop: animationSlideFromTop,
  slideToBottom: animationSlideToBottom,
  slideToLeft: animationSlideToLeft,
  slideToRight: animationSlideToRight,
  slideToTop: animationSlideToTop,
  none: animationNone,
}
