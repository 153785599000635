import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, opacity, typography } from '@vfuk/core-mixins'

import { StyledRangeSliderProps } from './RangeSliderFieldSet.style.types'

import localThemes from '../../../themes/RangeSlider.theme'

export const RangeSlider = styled.div<StyledRangeSliderProps>((props: StyledRangeSliderProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    display: flex;
    align-items: center;
    justify-content: center;

    .rc-slider {
      display: flex;
      align-items: center;
      justify-content: center;

      ${props.state === 'disabled' &&
      css`
        ${opacity(true)};
      `}
    }

    .rc-slider-handle {
      background-color: ${localTheme.appearance[props.appearance!].color.default};
      border: none;
      width: 20px;
      height: 20px;
      top: 0;
      z-index: 1;
      box-shadow: none;

      &:active {
        background-color: ${localTheme.appearance[props.appearance!].color.pressed};
      }

      &:hover {
        background-color: ${localTheme.appearance[props.appearance!].color.hover};
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      }
    }

    .rc-slider-rail {
      top: 3px;
      border: 2px solid ${props.theme.color.monochrome3.default};
    }

    .rc-slider-track {
      height: 6px;
      top: 1px;
      background-color: ${localTheme.appearance[props.appearance!].color.default};
    }

    .rc-slider-dot {
      bottom: 3px;
      height: 2px;
      width: 2px;
      border: 1px solid ${props.theme.color.monochrome4.default};

      &:first-child {
        margin-left: 2px;
      }
    }

    .rc-slider-dot-active {
      bottom: 4px;
      border-color: ${props.theme.color.monochrome1.default};
      z-index: 0;
    }

    .rc-slider-tooltip {
      z-index: 2;
      top: -50px !important;
    }

    .rc-slider-tooltip-placement-top {
      padding: 0;
    }

    .rc-slider-tooltip-inner {
      height: inherit;
      background-color: ${props.theme.color.monochrome6.default};
      border-radius: ${props.theme.border.radius[2]};
      ${advancedSpacing('padding', [2, 3])}
      ${typography.paragraph(1, false)}
      white-space: nowrap;
    }

    .rc-slider-tooltip-arrow {
      border-top-color: ${props.theme.color.monochrome6.default};
      margin-left: -8px;
      border-width: 8px 8px 0;
      top: 34px;
    }

    .rc-slider-mark-text {
      font-size: ${props.theme.typography.paragraph[1].fontDesktop};
      color: ${props.theme.color.monochrome6.default};
      margin-left: -3px;
    }

    ${!!props.rangeLabels &&
    css`
      margin-bottom: 2rem;
    `}
  `
})

export const LeftIconWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    margin-right: 26px;
  `,
)

export const RightIconWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    margin-left: 20px;
  `,
)
