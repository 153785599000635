import React, { FC, ReactElement } from 'react'

import TextInput from '@vfuk/core-text-input'
import FieldWrapper from '@vfuk/core-field-wrapper'

import { TextInputWithLabelProps } from './TextInputWithLabel.types'

const TextInputWithLabel: FC<TextInputWithLabelProps> = ({ state, fieldWrapper, textInput }: TextInputWithLabelProps): ReactElement => {
  return (
    <FieldWrapper
      state={state}
      label={fieldWrapper.label}
      formElementId={textInput.id}
      showLabel={fieldWrapper.showLabel}
      helpText={fieldWrapper.helpText}
      stateText={fieldWrapper.stateText}
      subText={fieldWrapper.subText}
      validateOn={fieldWrapper.validateOn}
      width={fieldWrapper.width}
      helpLink={fieldWrapper.helpLink}
      optional={fieldWrapper.optional}
      required={fieldWrapper.required}
    >
      <TextInput
        state={state}
        autoComplete={textInput.autoComplete}
        dataAttributes={textInput.dataAttributes}
        id={textInput.id}
        name={textInput.name}
        onBlur={textInput.onBlur}
        onChange={textInput.onChange}
        onClick={textInput.onClick}
        placeholder={textInput.placeholder}
        required={textInput.required}
        type={textInput.type}
        value={textInput.value}
        pattern={textInput.pattern}
        spellcheck={textInput.spellcheck}
        minLength={textInput.minLength}
        maxLength={textInput.maxLength}
        onKeyDown={textInput.onKeyDown}
        beforeChange={textInput.beforeChange}
        infieldIcon={textInput.infieldIcon}
        domRef={textInput.domRef}
      />
    </FieldWrapper>
  )
}

export default TextInputWithLabel
