import React, { PureComponent } from 'react'

import * as Styled from './styles/FunctionalTabList.style'
import { FunctionalTabListProps } from './FunctionalTabList.types'

export class FunctionalTabList extends PureComponent<FunctionalTabListProps> {
  public render(): React.ReactNode {
    return (
      <Styled.StyledTabList role='tablist' justifyContent={this.props.justifyContent} maxWidth={this.props.maxWidth}>
        {this.props.children}
      </Styled.StyledTabList>
    )
  }
}
