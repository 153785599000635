import React, { FC, ReactElement } from 'react'
import { withTheme } from 'styled-components'

import Container from '@vfuk/core-container'
import DateCountdown from '@vfuk/core-date-countdown'
import Image from '@vfuk/core-image'
import Icon from '@vfuk/core-icon'
import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'

import * as Styled from './styles/Advert.style'

import { AdvertProps } from './Advert.types'
import localTheme from './themes/Advert.theme'

export const Advert: FC<AdvertProps> = ({
  appearance = 'primary',
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
  href,
  headingText,
  text,
  countdownTimer,
  image,
}: AdvertProps): ReactElement => {
  const advertAppearance = localTheme().appearance[appearance!]
  return (
    <Styled.Advert
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      customRouterProps={customRouterProps}
      href={href}
      label={headingText}
      fullSize
    >
      <Container appearance={advertAppearance.containerAppearance} paddingLevel={0} elevation={true} allowOverflow={false}>
        <Styled.AdvertContent>
          <Styled.AdvertBody>
            <Heading weight={3} size={2} inverse={advertAppearance.inverseText}>
              {headingText}
              <If condition={href || customRouterProps || onClick}>
                <Icon
                  name='chevron-right'
                  group='system'
                  size={3}
                  appearance={advertAppearance.iconAppearance}
                  inverse={advertAppearance.inverseIcon}
                />
              </If>
            </Heading>
            <If condition={text}>
              <Paragraph inverse={advertAppearance.inverseText}>{text}</Paragraph>
            </If>
            <If condition={countdownTimer}>
              <DateCountdown {...countdownTimer} size={1} inverse={advertAppearance.inverseCountdownTimer} />
            </If>
          </Styled.AdvertBody>
          <Styled.AdvertImage>
            <Image {...image} />
          </Styled.AdvertImage>
        </Styled.AdvertContent>
      </Container>
    </Styled.Advert>
  )
}

export default withTheme(Advert)
