import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { responsivePosition } from '@vfuk/core-mixins'

export const CloseButton = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    ${responsivePosition('top', 4)};
    ${responsivePosition('right', 4)};
    z-index: 3;
  `,
)
