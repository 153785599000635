import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export const YoutubeVideoWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
)
