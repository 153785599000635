import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { advancedSpacing, respondTo, responsiveSizing, typography } from '@vfuk/core-mixins'

import { StyledSearchSuggestionProps } from './SearchSuggestion.style.types'

export const SearchSuggestion = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const SuggestionList = styled(ListGroup)(
  (props: StyledSearchSuggestionProps): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background: ${props.theme.color.monochrome1.default};
    border-radius: 0 0 ${props.theme.border.radius[2]} ${props.theme.border.radius[2]};
    max-height: 0;
    overflow: auto;
    transition: 0.4s all ease;
    cursor: pointer;

    ${props.isOpen &&
    css`
      border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome3.default};
      transform: translateY(-${props.theme.border.radius[2]});
      padding: ${props.theme.border.radius[2]} 0 0;
      max-height: ${props.theme.spacing.responsive[8].sm * 8}px;
      border-top: none;

      ${respondTo.md(css`
        max-height: ${props.theme.spacing.responsive[8].md * 8}px;
      `)}

      ${respondTo.lg(css`
        max-height: ${props.theme.spacing.responsive[8].lg * 8}px;
      `)}

    ${respondTo.xl(css`
        max-height: ${props.theme.spacing.responsive[8].xl * 8}px;
      `)}
    `}
  `,
)

type SuggestionProps = Pick<StyledSearchSuggestionProps, 'theme'>
export const Suggestion = styled(ListItem)(
  (props: SuggestionProps): FlattenSimpleInterpolation => css`
    button {
      ${advancedSpacing('padding', [2, 3])};
      transition: 0.4s all ease;
      ${responsiveSizing('height', 8)};
      ${typography.paragraph(2, false)}
      background: ${props.theme.color.monochrome1.default};
      width: 100%;
      text-align: left;
      border: none;
      color: ${props.theme.color.monochrome6.default};
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        background: ${props.theme.color.secondary1.default};
        color: ${props.theme.color.monochrome1.default};
        outline: none;
        box-shadow: none;
      }
    }
  `,
)

export const Subtext = styled.div(
  (props: SuggestionProps): FlattenSimpleInterpolation => css`
    ${typography.paragraph(1, false)};
    margin-left: auto;
  `,
)
