import React, { ReactElement } from 'react'

import { OverlayControllerAbstract, OverlayTypes, FlyoutOptions, ModalOptions } from './OverlayController.types'

import { OverlayProviderPartial, OverlayState } from '../provider/OverlayProvider.types'

export class OverlayController implements OverlayControllerAbstract {
  private provider: OverlayProviderPartial

  public resetModalState = (): void => {
    this.provider.reset()
  }

  public setProvider(provider: OverlayProviderPartial): void {
    this.provider = provider
  }

  public show = (id: string): void => {
    this.provider.setStateForOverlay({
      id,
      isShowing: true,
    })
  }

  public hide = (id: string): void => {
    this.provider.removeOverlay(id)
  }

  /**
   * Methods to set the state to control the modal
   */

  public setIsClosable = (id: string, isClosable: boolean): void => {
    this.provider.setStateForOverlay({
      id,
      isClosable,
    })
  }

  public setOnClose = (id: string, cb: () => void): void => {
    this.provider.setStateForOverlay({
      id,
      onCloseCb: cb,
    })
  }

  public setInitialFocusId = (id: string, initialFocusId: string): void => {
    this.provider.setStateForOverlay({
      id,
      initialFocusId,
    })
  }

  public setFocusEnabledRefs = (id: string, focusEnabledRefs: OverlayState['focusEnabledRefs']): void => {
    this.provider.setStateForOverlay({
      id,
      focusEnabledRefs,
    })
  }

  public setAnimate = (id: string, animate: boolean): void => {
    this.provider.setStateForOverlay({
      id,
      animate,
    })
  }

  public setSrName = (id: string, srName: string): void => {
    this.provider.setStateForOverlay({
      id,
      srName,
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public setContent = <Props extends object>(
    id: string,
    Content: React.ComponentType<Props> | ReactElement,
    props: Props = {} as Props,
  ): void => {
    if (React.isValidElement(Content)) {
      this.provider.setStateForOverlay({
        id,
        content: Content,
      })
      return
    }
    this.provider.setStateForOverlay({
      id,
      content: <Content {...props} />,
    })
  }

  public setType = (id: string, type: OverlayTypes, renderOptions: FlyoutOptions | ModalOptions): void => {
    this.provider.setStateForOverlay({
      id,
      type,
      renderOptions,
    })
  }

  public setConfig = (id: string, config: Partial<OverlayState>): void => {
    this.provider.setStateForOverlay({
      id,
      ...config,
    })
  }
}

export default new OverlayController()
