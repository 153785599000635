import React, { ReactElement, FC } from 'react'
import { FunctionalContentBlock } from '@vfuk/core-functional-content-block'

import { ContentBlockProps } from './ContentBlock.types'

const ContentBlock: FC<ContentBlockProps> = ({
  heading,
  content,
  primaryButton,
  secondaryButton,
  footnote,
  appearance,
}: ContentBlockProps): ReactElement => (
  <FunctionalContentBlock
    appearance={appearance}
    heading={heading}
    content={content}
    primaryButton={primaryButton}
    secondaryButton={secondaryButton}
    footnote={footnote}
    mediaPosition='center'
  />
)

export default ContentBlock
