import React, { FC, ReactElement } from 'react'

import Heading from '@vfuk/core-heading'
import { useBreakpoints } from '@vfuk/core-match-media'

import { HeaderProps } from './Header.types'

const Header: FC<HeaderProps> = ({ heading, position, inverse }: HeaderProps): ReactElement => {
  const breakpoints = useBreakpoints()
  const justifyLeft = (position === 'left' || position === 'right') && breakpoints.lgAndAbove
  return (
    <Heading inverse={inverse} level={heading!.level} text={heading!.text} size={3} justify={justifyLeft ? 'left' : 'center'} noMargin />
  )
}

export default Header
