import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledIconProps } from './SliderIcon.style.types'

export const IconWrapper = styled.div<StyledIconProps>((props: StyledIconProps): FlattenSimpleInterpolation => {
  return css`
    ${props.position === 'left' &&
    css`
      margin-right: 26px;
    `}
    ${props.position === 'right' &&
    css`
      margin-left: 20px;
    `}
  `
})
