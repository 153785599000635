/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, opacity, typography } from '@vfuk/core-mixins'

import { StyledPillProps } from './Pill.style.types'

import localThemes from '../themes/Pill.theme'

export const Pill = styled.span<StyledPillProps>((props: StyledPillProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    ${advancedSpacing('padding', [1, 3])};
    background: ${localTheme.appearance[props.appearance!].background};
    color: ${localTheme.appearance[props.appearance!].color};
    border-radius: ${props.theme.border.radius[3]};
    ${typography.paragraph(1, false)};

    ${props.state &&
    props.state !== 'disabled' &&
    css`
      background: ${localTheme.state[props.state].background};
      color: ${localTheme.state[props.state].color};
    `}

    ${props.state === 'disabled' &&
    css`
      ${opacity(true)}
    `}
  `
})
