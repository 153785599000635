import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, backgroundImage, respondTo } from '@vfuk/core-mixins'

import { StyledContentImageProps } from './ContentImage.style.types'

export const ImageWrapper = styled.div<StyledContentImageProps>(
  (props): FlattenSimpleInterpolation => css`
    min-height: 332px;
    ${backgroundImage(props.image)};

    ${props.imagePosition === 'center' &&
    css`
      ${advancedSpacing('margin', [0, -4])}
    `}

    ${respondTo.md(css`
      min-height: 446px;
    `)}

  ${respondTo.lg(css`
      ${props.imagePosition === 'center' &&
      css`
        min-height: 394px;
      `}

      ${(props.imagePosition === 'left' || props.imagePosition === 'right') &&
      css`
        width: 50%;
      `}
    `)}
  `,
)
