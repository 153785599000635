const PAGE_MOUNTED = 'PAGE_MOUNTED';
const PAGE_UNMOUNTED = 'PAGE_UNMOUNTED';
const APPLICATION_MOUNTED = 'APPLICATION_MOUNTED';

const pageMounted = payload => ({
  type: PAGE_MOUNTED,
  payload
});

const pageUnmounted = payload => ({
  type: PAGE_UNMOUNTED,
  payload
});

const applicationMounted = () => ({
  type: APPLICATION_MOUNTED
});

export { pageMounted, pageUnmounted, applicationMounted };
