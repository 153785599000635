import { ModalRendererTheme } from './ModalRenderer.theme.types'

const modalRendererLocal: ModalRendererTheme = {
  size: {
    1: {
      sm: '100%',
      md: '400px',
      lg: '400px',
      xl: '400px',
    },
    2: {
      sm: '100%',
      md: '100%',
      lg: '640px',
      xl: '640px',
    },
    3: {
      sm: '100%',
      md: '100%',
      lg: '832px',
      xl: '832px',
    },
    4: {
      sm: '100%',
      md: '100%',
      lg: '100%',
      xl: '1024px',
    },
    5: {
      sm: '100%',
      md: '100%',
      lg: '100%',
      xl: '1232px',
    },
  },
  appearance: {
    primary: {
      containerAppearance: 'primary',
      inverseIcon: false,
    },
    secondary: {
      containerAppearance: 'primary',
      inverseIcon: false,
    },
  },
}

export default modalRendererLocal
