import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListItem from '@vfuk/core-list-item'
import ListGroup from '@vfuk/core-list-group'
import { respondTo, responsiveSizing, spacing, typography } from '@vfuk/core-mixins'

import { StyledFunctionalStepperProps } from './FunctionalStepper.style.types'

type FunctionalStepperProps = Pick<StyledFunctionalStepperProps, 'theme' | 'inverse'>
export const FunctionalStepper = styled.div(
  (props: FunctionalStepperProps): FlattenSimpleInterpolation => css`
    ${props.inverse &&
    css`
      color: ${props.theme.inverseColor.monochrome6.default};
    `}
  `,
)

export const Steps = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;
  `,
)

type StepContainerProps = Pick<StyledFunctionalStepperProps, 'theme' | 'state' | 'inverse'>
export const StepContainer = styled(ListItem)(
  (props: StepContainerProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    [class*='InteractionListItem'] {
      width: auto;

      &:focus {
        outline: none;
        box-shadow: none;

        /* Selects the class beginning with the following */
        [class|='FunctionalStepperstyle__Step'] {
          box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
          outline: 3px solid transparent;
        }
      }
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      margin-top: 0;
      background-color: ${props.state ? props.theme.color[props.state].default : props.theme.color.monochrome6.default};
      transition: background-color 0.3s ease;
      top: ${props.theme.spacing.responsive[8].sm / 2}px;
      left: calc(50% + ${props.theme.spacing.responsive[8].sm / 2}px);
      width: calc(100% - ${props.theme.spacing.responsive[8].sm}px);

      ${respondTo.md(css`
        top: ${props.theme.spacing.responsive[8].md / 2}px;
        left: calc(50% + ${props.theme.spacing.responsive[8].md / 2}px);
        width: calc(100% - ${props.theme.spacing.responsive[8].md}px);
      `)}

      ${respondTo.lg(css`
        top: ${props.theme.spacing.responsive[8].lg / 2}px;
        left: calc(50% + ${props.theme.spacing.responsive[8].lg / 2}px);
        width: calc(100% - ${props.theme.spacing.responsive[8].lg}px);
      `)}

    ${respondTo.xl(css`
        top: ${props.theme.spacing.responsive[8].xl / 2}px;
        left: calc(50% + ${props.theme.spacing.responsive[8].xl / 2}px);
        width: calc(100% - ${props.theme.spacing.responsive[8].xl}px);
      `)}

    ${props.inverse &&
      css`
        background-color: ${props.state ? props.theme.inverseColor[props.state].default : props.theme.inverseColor.monochrome6.default};
      `}
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  `,
)

export const StepWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
)

export const Step = styled.div((props: StyledFunctionalStepperProps): FlattenSimpleInterpolation => {
  const inverseInfluencedColor = props.inverse ? props.theme.color.monochrome1.default : props.theme.color.monochrome6.default

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${inverseInfluencedColor};
    border-radius: 50%;
    ${responsiveSizing('width', 8)};
    ${responsiveSizing('height', 8)};
    text-align: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    span {
      ${responsiveSizing('line-height', 8)};
    }

    ${props.isCurrentStep &&
    css`
      background-color: ${inverseInfluencedColor};
      color: ${props.inverse ? props.theme.color.monochrome6.default : props.theme.color.monochrome1.default};
      border: none;
    `}

    ${props.state &&
    css`
      border-color: ${props.theme.color[props.state].default};
    `}
  `
})

type TextWrapperProps = Pick<StyledFunctionalStepperProps, 'theme' | 'state' | 'inverse'>
export const TextWrapper = styled.div(
  (props: TextWrapperProps): FlattenSimpleInterpolation => css`
    display: none;
    width: 116px;
    ${typography.paragraph(2, false)}

    ${props.state &&
    css`
      color: ${props.theme.color[props.state].default};

      ${props.inverse &&
      css`
        color: ${props.theme.inverseColor[props.state].default};
      `}
    `}

  ${respondTo.md(css`
      display: block;
    `)}
  `,
)

export const StepperMobileControlsContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
  `,
)

export const StepperMobileControls = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    /* when controls are hidden the label should be centered */
    > :only-child {
      margin: 0 auto;
    }
  `,
)

export const ErrorListGroup = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    text-align: center;
    ${spacing('margin-top', 2)};
  `,
)

export const ErrorListItem = styled(ListItem)(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 2)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
)
