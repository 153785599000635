import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing, srOnly } from '@vfuk/core-mixins'

export const ColorWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    ${spacing('padding-top', 4)}
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const PickerItemWrapper = styled.span((): FlattenSimpleInterpolation => [])

export const Fieldset = styled.fieldset((): FlattenSimpleInterpolation => [])
