import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Step.style'

import { StepProps } from './Step.types'

const Step: FC<StepProps> = ({ inverse, isCompleted, isActive, onClick, title, srText }: StepProps): ReactElement => {
  return (
    <Styled.StepWrapper isCompleted={isCompleted} isActive={isActive} inverse={inverse}>
      <Styled.Step onClick={onClick} as={onClick ? 'button' : 'div'} hasHover={isCompleted}>
        <Styled.Circle isCompleted={isCompleted} isActive={isActive} inverse={inverse} />
        <Styled.StepTitle isActive={isActive} inverse={inverse} data-title={title}>
          {title}
          <If condition={srText}>
            <Styled.HiddenLabel>{srText}</Styled.HiddenLabel>
          </If>
        </Styled.StepTitle>
      </Styled.Step>
    </Styled.StepWrapper>
  )
}

export default Step
