import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo, responsiveSizing, spacing, srOnly, typography } from '@vfuk/core-mixins'

import { StyledHorizontalStepperProps } from './Step.style.types'

type StepWrapperProps = Pick<StyledHorizontalStepperProps, 'theme' | 'inverse' | 'isCompleted' | 'isActive'>
export const StepWrapper = styled.div<StepWrapperProps>(
  (props: StepWrapperProps): FlattenSimpleInterpolation => css`
    position: relative;
    display: flex;
    flex: 1;
    flex-shrink: 0;
    justify-content: center;
    text-align: center;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 50%;
      background: ${props.theme.color.monochrome3.default};
      transform: translateY(-50%);
      position: absolute;
      top: ${props.theme.spacing.responsive[4].sm / 2}px;

      ${respondTo.md(css`
        top: ${props.theme.spacing.responsive[4].md / 2}px;
      `)}

      ${respondTo.lg(css`
        top: ${props.theme.spacing.responsive[4].lg / 2}px;
      `)}

    ${respondTo.xl(css`
        top: ${props.theme.spacing.responsive[4].xl / 2}px;
      `)}

    ${props.inverse &&
      css`
        background: ${props.theme.inverseColor.monochrome3.default};
      `}
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    ${props.isCompleted &&
    css`
      &::before,
      &::after {
        background: ${props.theme.color.primary1.default};

        ${props.inverse &&
        css`
          background: ${props.theme.inverseColor.primary1.default};
        `}
      }
    `}

    ${props.isActive &&
    css`
      &::before {
        background: ${props.theme.color.primary1.default};

        ${props.inverse &&
        css`
          background: ${props.theme.inverseColor.primary1.default};
        `}

        right: calc(50% + ${props.theme.spacing.responsive[4].sm / 2}px + 3px);

        ${respondTo.md(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].md / 2}px + 3px);
        `)}

        ${respondTo.lg(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].lg / 2}px + 3px);
        `)}

      ${respondTo.xl(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].xl / 2}px + 3px);
        `)}
      }

      &::after {
        left: calc(50% + ${props.theme.spacing.responsive[4].sm / 2}px + 3px);

        ${respondTo.md(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].md / 2}px + 3px);
        `)}

        ${respondTo.lg(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].lg / 2}px + 3px);
        `)}

      ${respondTo.xl(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].xl / 2}px + 3px);
        `)}
      }
    `}
  `,
)

type CircleProps = Pick<StyledHorizontalStepperProps, 'theme' | 'isActive' | 'inverse' | 'isCompleted'>
export const Circle = styled.div<CircleProps>(
  (props: CircleProps): FlattenSimpleInterpolation => css`
    position: relative;
    background: ${props.theme.color.monochrome3.default};
    ${responsiveSizing('width', 4)};
    ${responsiveSizing('height', 4)};
    border-radius: 50%;

    ${props.isCompleted &&
    css`
      background: ${props.theme.color.primary1.default};

      ${props.inverse &&
      css`
        background: ${props.theme.inverseColor.primary1.default};
      `}
    `}

    ${props.isActive &&
    css`
      background: ${props.theme.color.primary1.default};

      ${props.inverse &&
      css`
        background: ${props.theme.inverseColor.primary1.default};
      `}

      &::before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color.primary1.default};
        border-radius: 50%;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
      }
    `}
  `,
)

type StepTitleProps = Pick<StyledHorizontalStepperProps, 'theme' | 'isActive' | 'inverse'>
export const StepTitle = styled.div<StepTitleProps>(
  (props: StepTitleProps): FlattenSimpleInterpolation => css`
    display: inline-block;
    transition: all 50ms linear;
    ${typography.paragraph(2, false)};
    font-family: ${props.theme.baseFont.fontFamily};
    font-weight: ${props.theme.fontWeight[1]};
    min-width: 70px;
    ${spacing('padding-top', 2)}

    ${respondTo.md(css`
      width: max-content;
    `)}

  &::after {
      display: block;
      content: attr(data-title);
      font-weight: ${props.theme.fontWeight[4]};
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    ${props.inverse &&
    css`
      color: ${props.theme.inverseColor.monochrome6.default};
    `}

    ${props.isActive &&
    css`
      font-weight: ${props.theme.fontWeight[3]};
    `}
  `,
)

export const HiddenLabel = styled.span(
  () => css`
    ${srOnly()}
  `,
)

type StepProps = Pick<StyledHorizontalStepperProps, 'theme' | 'hasHover'>
export const Step = styled.div<StepProps>(
  (props: StepProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    border: none;
    background: none;
    ${advancedSpacing('padding', [0, 2])};
    margin: 0;
    position: relative;
    z-index: 1;

    &:focus {
      outline: none;

      ${Circle} {
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      }
    }

    ${props.hasHover &&
    `
    cursor: pointer;

    &:hover {
      ${StepTitle} {
        font-weight: ${props.theme.fontWeight[3]};
      }
    }
    `}
  `,
)
