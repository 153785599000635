import React, { FC } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'
import SelectInput from '@vfuk/core-select-input'

import * as Styled from './styles/SelectInputWithLabel.style'

import { SelectInputWithLabelProps } from './SelectInputWithLabel.types'

const SelectInputWithLabel: FC<SelectInputWithLabelProps> = ({ state, fieldWrapper, selectInput }) => {
  return (
    <Styled.Container>
      <FieldWrapper
        state={state}
        helpText={fieldWrapper.helpText}
        label={fieldWrapper.label}
        formElementId={selectInput.id}
        showLabel={fieldWrapper.showLabel}
        subText={fieldWrapper.subText}
        stateText={fieldWrapper.stateText}
        validateOn={fieldWrapper.validateOn}
        width={fieldWrapper.width}
        helpLink={fieldWrapper.helpLink}
        optional={fieldWrapper.optional}
        required={fieldWrapper.required}
      >
        <SelectInput
          state={state!}
          id={selectInput.id}
          name={selectInput.name}
          onBlur={selectInput.onBlur}
          onChange={selectInput.onChange}
          options={selectInput.options}
          placeholder={selectInput.placeholder}
          required={selectInput.required}
          value={selectInput.value}
          dataAttributes={selectInput.dataAttributes}
          domRef={selectInput.domRef}
        />
      </FieldWrapper>
    </Styled.Container>
  )
}

export default SelectInputWithLabel
