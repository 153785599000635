import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-themes/dist/themes.types'
import { Divider } from '@vfuk/core-divider/dist/styles/Divider.style'
import { iconAppearance, responsiveCalc, spacing, typography } from '@vfuk/core-mixins'

import {
  StyledTextProps,
  StyledOverflowMenuItemProps,
  StyledDisabledTextContainerProp,
  StyledIconPlaceholderProps,
} from './OverflowMenuItem.style.types'

export const Text = styled.span<StyledTextProps>(
  () => css`
    ${typography.paragraph(1, false)};
  `,
)

const getStylesForActiveElement = (theme: Theme): FlattenSimpleInterpolation => {
  return css`
    background-color: ${theme.color.monochrome2.default};

    ${Text} {
      font-weight: ${theme.fontWeight[3]};
    }
  `
}

export const OverflowMenuItem = styled.li<StyledOverflowMenuItemProps>(
  (props): FlattenSimpleInterpolation => css`
    width: 100%;
    background-color: ${props.theme.color.monochrome1.default};
    transition: 0.4s all ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${spacing('padding', 3)};
    cursor: pointer;

    ${Divider} {
      position: absolute;
      bottom: 0;
      ${responsiveCalc('width', '100%', '-', 6)};
    }

    &:last-of-type ${Divider} {
      display: none;
    }

    &:hover {
      ${getStylesForActiveElement(props.theme)}
    }

    ${props.focused &&
    css`
      ${getStylesForActiveElement(props.theme)}

      &::before {
        outline: none;
        content: '';
        z-index: 1;
        border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.focus.default};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      &:first-child {
        &::before {
          border-radius: ${props.theme.border.radius[2]} ${props.theme.border.radius[2]} 0 0;
        }
      }

      &:last-child {
        &::before {
          border-radius: 0 0 ${props.theme.border.radius[2]} ${props.theme.border.radius[2]};
        }
      }
    `}

    ${props.active &&
    css`
      ${getStylesForActiveElement(props.theme)};
    `}

  ${props.disabled &&
    css`
      color: ${props.theme.color.disabled.default};
      background-color: ${props.theme.color.monochrome1.default};

      ${Text} {
        font-weight: ${props.theme.fontWeight[2]};
      }

      &:hover {
        background-color: ${props.theme.color.monochrome1.default};

        button {
          cursor: not-allowed;
        }

        ${Text} {
          font-weight: ${props.theme.fontWeight[2]};
        }
      }
    `}
  `,
)

export const OverflowMenuItemInnerWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
  `,
)

export const IconPlaceholder = styled.span<StyledIconPlaceholderProps>(
  (props): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 2)};
    display: inline-flex;
    align-items: center;

    ${props.isIcon &&
    props.disabled &&
    css`
      [class*='Icon'] {
        ${iconAppearance(props.theme.color.disabled.default, props.group)}
      }
    `}
  `,
)

export const DisabledTextContainer = styled.div<StyledDisabledTextContainerProp>(
  (props): FlattenSimpleInterpolation => css`
    ${spacing('padding-top', 1)};
    display: flex;

    & > p {
      color: ${props.theme.color.error.default};
    }
  `,
)

type HiddenIconPlaceholderProps = Pick<StyledIconPlaceholderProps, 'theme'>

export const HiddenIconPlaceholder = styled.span<HiddenIconPlaceholderProps>(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 2)};
    visibility: hidden;
    height: 0;
  `,
)
