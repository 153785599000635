import React, { FC, ReactElement } from 'react'
import Button from '@vfuk/core-button'

import * as Styled from './styles/ButtonGroup.style'

import { ButtonGroupProps } from './ButtonGroup.types'

const ButtonGroup: FC<ButtonGroupProps> = ({ primaryButton, secondaryButton, inverse, mediaPosition }: ButtonGroupProps): ReactElement => (
  <Styled.ButtonGroup mediaPosition={mediaPosition}>
    <If condition={primaryButton?.text}>
      <Button
        text={primaryButton!.text}
        href={primaryButton!.href}
        onClick={primaryButton!.onClick}
        onMouseDown={primaryButton!.onMouseDown}
        onMouseUp={primaryButton!.onMouseUp}
        onTouchStart={primaryButton!.onTouchStart}
        onTouchEnd={primaryButton!.onTouchEnd}
        appearance='primary'
        inverse={inverse}
        htmlAttributes={primaryButton!.htmlAttributes}
        customRouterProps={primaryButton?.customRouterProps}
      />
    </If>
    <If condition={secondaryButton?.text}>
      <Button
        text={secondaryButton!.text}
        href={secondaryButton!.href}
        onClick={secondaryButton!.onClick}
        onMouseDown={secondaryButton!.onMouseDown}
        onMouseUp={secondaryButton!.onMouseUp}
        onTouchStart={secondaryButton!.onTouchStart}
        onTouchEnd={secondaryButton!.onTouchEnd}
        appearance='secondary'
        inverse={inverse}
        htmlAttributes={secondaryButton!.htmlAttributes}
        customRouterProps={secondaryButton?.customRouterProps}
      />
    </If>
  </Styled.ButtonGroup>
)

export default ButtonGroup
