import React, { PureComponent, ReactNode } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'
import SelectInputWithMenu from '@vfuk/core-select-input-with-menu'

import { SelectInputWithMenuWithLabelProps, GetStateReturn } from './SelectInputWithMenuWithLabel.types'

class SelectInputWithMenuWithLabel extends PureComponent<SelectInputWithMenuWithLabelProps> {
  private getState = <T extends 'fieldWrapper' | 'selectInputWithMenu'>(type: T): GetStateReturn<T> | undefined => {
    if (type === 'fieldWrapper') return this.props.state !== 'disabled' ? (this.props.state as GetStateReturn<T>) : undefined

    if (type === 'selectInputWithMenu') return this.props.state !== 'info' ? (this.props.state as GetStateReturn<T>) : undefined
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        {...this.props.fieldWrapper}
        state={this.getState('fieldWrapper')}
        formElementId={this.props.selectInputWithMenu.id}
        renderAsLabel={false}
      >
        <SelectInputWithMenu {...this.props.selectInputWithMenu} state={this.getState('selectInputWithMenu')}>
          {this.props.selectInputWithMenu.children}
        </SelectInputWithMenu>
      </FieldWrapper>
    )
  }
}

export default SelectInputWithMenuWithLabel
