import React, { ReactElement, FC } from 'react'

import IFrameWrapper from '@vfuk/core-iframe-wrapper'

import * as Styled from './styles/YoutubeVideo.style'

import { YoutubeVideoProps } from './YoutubeVideo.types'

const YoutubeVideo: FC<YoutubeVideoProps> = ({
  videoId,
  id,
  title,
  autoplay = false,
  showControls = false,
  forceCaptions = false,
}: YoutubeVideoProps): ReactElement => {
  const url = new URL(`https://www.youtube.com/embed/${videoId}`)
  url.searchParams.set('controls', showControls ? '1' : '0')
  if (autoplay) url.searchParams.set('autoplay', '1')
  if (forceCaptions) url.searchParams.set('cc_load_policy', '1')

  return (
    <Styled.YoutubeVideoWrapper>
      <IFrameWrapper id={id} url={url.toString()} title={title} allow={['fullscreen']} />
    </Styled.YoutubeVideoWrapper>
  )
}

export default YoutubeVideo
