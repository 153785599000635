import React, { FocusEvent, FC, memo } from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

import * as Styled from './styles/RangeSliderFieldSet.style'

import TopLabels from '../TopLabels'
import SliderIcon from '../SliderIcon'
import SliderTextInput from '../SliderTextInput'

import { RangeSliderProps } from '../../RangeSlider.types'

export const SliderWithTooltip = createSliderWithTooltip(Slider)
export const RangeSliderWithTooltip = createSliderWithTooltip(Slider.Range)

export const RangeSliderFieldSet: FC<RangeSliderProps> = ({
  className,
  min,
  max,
  value,
  showDots,
  topLabels,
  rangeLabels,
  onChange,
  onBlur,
  onInvalidInputEntry,
  allowNonIncrementValue = false,
  id,
  srLabel,
  prefix,
  suffix,
  state,
  appearance = 'primary',
  increment = 1,
  showTextInput = false,
  icons,
}) => {
  const isDualSlider = Array.isArray(value)

  const handleOnBlur = (event: FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) onBlur(event)
  }

  const handleOnChange = (sliderValue: number | number[]): void => {
    onChange(sliderValue, 'slider')
  }

  const tipFormatter = (formatterValue: number): string => `${prefix || ''}${formatterValue}${suffix || ''}`

  return (
    <fieldset id={id} onBlur={handleOnBlur}>
      <If condition={topLabels}>
        <TopLabels labels={topLabels} />
      </If>
      <Styled.RangeSlider appearance={appearance} state={state} rangeLabels={rangeLabels}>
        <If condition={icons?.left}>
          <SliderIcon
            icon={icons!.left!}
            min={min}
            max={max}
            value={value}
            increment={increment}
            position='left'
            onChange={onChange}
            state={state}
            srLabel={srLabel}
          />
        </If>
        <If condition={showTextInput && isDualSlider}>
          <SliderTextInput
            onInvalidInputEntry={onInvalidInputEntry}
            allowNonIncrementValue={allowNonIncrementValue}
            min={min}
            max={max}
            increment={increment}
            value={value}
            prefix={prefix}
            suffix={suffix}
            id={`${id}-left-text-input`}
            state={state}
            onChange={onChange}
            textInputPosition='left'
            srLabel={srLabel.leftLabel!}
          />
        </If>
        <Choose>
          <When condition={isDualSlider}>
            <RangeSliderWithTooltip
              className={className}
              min={min}
              max={max}
              step={increment}
              value={value as number[]}
              dots={showDots}
              marks={rangeLabels}
              onChange={handleOnChange}
              disabled={state === 'disabled'}
              tipFormatter={tipFormatter}
              ariaLabelGroupForHandles={[srLabel.leftLabel!, srLabel.rightLabel!]}
            />
          </When>
          <Otherwise>
            <SliderWithTooltip
              className={className}
              min={min}
              max={max}
              step={increment}
              value={value as number}
              dots={showDots}
              marks={rangeLabels}
              onChange={handleOnChange}
              disabled={state === 'disabled'}
              tipFormatter={tipFormatter}
              ariaLabelForHandle={srLabel.mainLabel}
            />
          </Otherwise>
        </Choose>
        <If condition={icons?.right}>
          <SliderIcon
            icon={icons!.right!}
            min={min}
            max={max}
            value={value}
            increment={increment}
            position='right'
            onChange={onChange}
            state={state}
            srLabel={srLabel}
          />
        </If>
        <If condition={showTextInput}>
          <SliderTextInput
            onInvalidInputEntry={onInvalidInputEntry}
            allowNonIncrementValue={allowNonIncrementValue}
            min={min}
            max={max}
            increment={increment}
            value={value}
            prefix={prefix}
            suffix={suffix}
            id={`${id}-right-text-input`}
            state={state}
            onChange={onChange}
            textInputPosition='right'
            srLabel={isDualSlider ? srLabel.rightLabel! : srLabel.mainLabel}
          />
        </If>
      </Styled.RangeSlider>
    </fieldset>
  )
}

export default memo(RangeSliderFieldSet)
