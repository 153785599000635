import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { BlockTheme } from './Block.theme.types'

const blockLocal = (theme: Theme): BlockTheme => {
  return {
    paddingLevel: {
      0: {
        vertical: 0,
        horizontal: 0,
      },
      1: {
        vertical: 6,
        horizontal: 4,
      },
      2: {
        vertical: 10,
        horizontal: 4,
      },
    },
    appearance: {
      primary: {
        backgroundColor: theme.baseFont.backgroundColor,
      },
      secondary: {
        backgroundColor: theme.color.secondary1.default,
      },
      alt1: {
        backgroundColor: theme.color.secondary3.default,
      },
      alt2: {
        backgroundColor: theme.baseFont.backgroundColor,
      },
    },
  }
}

export default blockLocal
