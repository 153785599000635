import React, { FunctionComponent, PureComponent, ReactNode } from 'react'

import Divider from '@vfuk/core-divider'

import * as Styled from './styles/OverflowMenuItem.style'

import { OverflowMenuItemProps } from './OverflowMenuItem.types'

class OverflowMenuItem extends PureComponent<OverflowMenuItemProps> {
  public static defaultProps: Partial<OverflowMenuItemProps> = {
    disabled: false,
    focused: false,
    active: false,
  }

  private checkIsIcon = (): boolean => {
    if (!this.props.children) return false

    const component = this.props.children?.type as FunctionComponent
    const displayName = component?.displayName

    return displayName ? displayName.includes('Icon') : false
  }

  public render(): ReactNode {
    return (
      <Styled.OverflowMenuItem
        id={this.props.value}
        role='option'
        aria-label={this.props.srText}
        aria-selected={this.props.active}
        active={this.props.active!}
        disabled={this.props.disabled!}
        aria-disabled={this.props.disabled!}
        focused={this.props.focused!}
        onClick={this.props.onClick}
      >
        <Styled.OverflowMenuItemInnerWrapper>
          <If condition={this.props.children}>
            <Styled.IconPlaceholder isIcon={this.checkIsIcon()} group={this.props.children?.props?.group} disabled={this.props.disabled!}>
              {this.props.children}
            </Styled.IconPlaceholder>
          </If>
          <Styled.Text>{this.props.text}</Styled.Text>
        </Styled.OverflowMenuItemInnerWrapper>
        <If condition={this.props.disabled && this.props.helpText}>
          <Styled.DisabledTextContainer hasIcon={!!this.props.children}>
            <If condition={this.props.children}>
              <Styled.HiddenIconPlaceholder>{this.props.children}</Styled.HiddenIconPlaceholder>
            </If>
            <Styled.Text>{this.props.helpText}</Styled.Text>
          </Styled.DisabledTextContainer>
        </If>
        <Divider noMargin />
      </Styled.OverflowMenuItem>
    )
  }
}

export default OverflowMenuItem
