import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledModalRendererProps } from './ModalRenderer.style.types'

import localThemes from '../themes/ModalRenderer.theme'

type ModalRendererTheme = Pick<StyledModalRendererProps, 'theme'>;

type ModalRendererProps = Pick<StyledModalRendererProps, 'theme' | 'zIndex'>
export const ModalRenderer = styled.div((props: ModalRendererProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props.theme.spacing.responsive[3].sm}px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props.zIndex};
    ${props.theme.mixins.respondTo.md(css`
      padding: ${props.theme.spacing.responsive[3].md}px;
    `)}

    ${props.theme.mixins.respondTo.lg(css`
      padding: ${props.theme.spacing.responsive[3].lg}px;
    `)}
  `
})

export const ModalWrapper = styled.div((props: ModalRendererTheme) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${props.theme.mixins.respondTo.md(css`
    top: ${props.theme.spacing.fixed[5]}px;
    left: ${props.theme.spacing.fixed[5]}px;
    right: ${props.theme.spacing.fixed[5]}px;
    bottom: ${props.theme.spacing.fixed[5]}px;
    width: auto;
  `)}
`)

type ContainerWrapperProps = Pick<StyledModalRendererProps, 'theme' | 'size'>
export const ContainerWrapper = styled.div<ContainerWrapperProps>((props) => {
  const theme = localThemes(props.theme)

  return css`
    pointer-events: auto;
    width: ${theme.size[props.size!].sm};
    max-height: 100%;

    ${props.theme.mixins.respondTo.md(css`
      max-width: ${theme.size[props.size!].md};
    `)}

    ${props.theme.mixins.respondTo.lg(css`
      max-width: ${theme.size[props.size!].lg};
    `)}

    ${props.theme.mixins.respondTo.xl(css`
      max-width: ${theme.size[props.size!].xl};
    `)}

    & > [class*=Container] {
      width: 100%;
      max-height: 100%;

      & > div {
        max-height: 100%;
        overflow: auto;
      }
    }
`})

export const ModalContent = styled.div((props: ModalRendererTheme) => css`
  max-height: 100%;
  overflow: auto;
  padding: ${props.theme.spacing.responsive[7].sm}px;

  ${props.theme.mixins.respondTo.md(css`
    padding: ${props.theme.spacing.responsive[7].md}px;
  `)}

  ${props.theme.mixins.respondTo.lg(css`
    padding: ${props.theme.spacing.responsive[7].lg}px;
  `)}
`)

export const IconButtonWrapper = styled.div((props: ModalRendererTheme): FlattenSimpleInterpolation => css`
  position: absolute;
  top: ${props.theme.spacing.fixed[3]}px;
  right: ${props.theme.spacing.fixed[3]}px;
  z-index: 3;

  & > [class*=InteractiveIcon] {
    position: fixed;
    transform: translateX(-100%);
  }
`)
