import * as React from 'react'
import { v4 as uuid } from 'uuid'
import Container from '@vfuk/core-container'

import * as Styled from './styles/Accordion.style'
import localTheme from './themes/Accordion.theme'
import useAccordion from './hooks'
import { AccordionProps, UncontrolledAccordionExtraProps, ControlledAccordionExtraProps } from './Accordion.types'
import { useAccordionProps } from './hooks/useAccordion.types'

const Accordion: React.FC<AccordionProps> = ({ id, children, multi, level = 1, theme, appearance = 'primary', ...props }) => {
  const { onToggle, openIds: controlledOpenIds } = props as ControlledAccordionExtraProps
  const isControlled = React.useMemo((): boolean => !!controlledOpenIds && !!onToggle, [controlledOpenIds, onToggle])

  const { initiallyOpenIds } = props as UncontrolledAccordionExtraProps
  const { openIds: defaultOpenIds, onToggle: defaultOnToggle } = useAccordion({ initiallyOpenIds, multi } as useAccordionProps)
  const uniqueId = React.useMemo((): string => uuid(), [])
  const onToggleHandler = React.useMemo(() => (isControlled ? onToggle : defaultOnToggle), [isControlled, onToggle, defaultOnToggle])
  const openIds = React.useMemo(
    (): string[] => (isControlled ? controlledOpenIds : defaultOpenIds),
    [isControlled, controlledOpenIds, defaultOpenIds],
  )

  return (
    <Styled.Accordion id={id} level={level}>
      {React.Children.map(children, (child: React.ReactElement, index: number) => {
        const collapsibleContainerId = child.props.id ?? `${uniqueId}-${index}`
        return (
          <Choose key={collapsibleContainerId}>
            <When condition={level === 1}>
              <Container key={collapsibleContainerId} elevation appearance={localTheme.appearance[appearance!].containerAppearance}>
                {React.cloneElement(child, {
                  id: collapsibleContainerId,
                  isActive: openIds.includes(collapsibleContainerId),
                  onToggle: () => onToggleHandler(collapsibleContainerId),
                  inverse: localTheme.appearance[appearance!].inverseCollapsibleContainer,
                })}
              </Container>
            </When>
            <Otherwise>
              {React.cloneElement(child, {
                key: index,
                id: collapsibleContainerId,
                isActive: openIds.includes(collapsibleContainerId),
                onToggle: () => onToggleHandler(collapsibleContainerId),
                inverse: localTheme.appearance[appearance!].inverseCollapsibleContainer,
              })}
            </Otherwise>
          </Choose>
        )
      })}
    </Styled.Accordion>
  )
}

export default Accordion
