import React, { FC, ReactElement } from 'react'

import FieldWrapper from '@vfuk/core-field-wrapper'
import SearchSuggestion from '@vfuk/core-search-suggestion'

import { SearchSuggestionWithLabelProps } from './SearchSuggestionWithLabel.types'

const SearchSuggestionWithLabel: FC<SearchSuggestionWithLabelProps> = ({
  fieldWrapper,
  searchSuggestion,
  state,
}: SearchSuggestionWithLabelProps): ReactElement => {
  return (
    <FieldWrapper
      state={state!}
      helpText={fieldWrapper.helpText}
      label={fieldWrapper.label}
      formElementId={searchSuggestion.id}
      showLabel={fieldWrapper.showLabel}
      subText={fieldWrapper.subText}
      stateText={fieldWrapper.stateText}
      validateOn={fieldWrapper.validateOn}
      width={fieldWrapper.width}
      helpLink={fieldWrapper.helpLink}
      optional={fieldWrapper.optional}
      required={fieldWrapper.required}
    >
      <SearchSuggestion
        suggestions={searchSuggestion.suggestions}
        minCharacters={searchSuggestion.minCharacters}
        state={state!}
        dataAttributes={searchSuggestion.dataAttributes}
        id={searchSuggestion.id}
        name={searchSuggestion.name}
        onChange={searchSuggestion.onChange}
        placeholder={searchSuggestion.placeholder}
        required={searchSuggestion.required}
        value={searchSuggestion.value}
        onSelect={searchSuggestion.onSelect}
        domRef={searchSuggestion.domRef}
      />
    </FieldWrapper>
  )
}

export default SearchSuggestionWithLabel
