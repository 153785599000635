import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Row } from 'styled-bootstrap-grid'

import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledGridRow } from './GridRow.style.types'

import { GridColumn } from '../../GridColumn/styles/GridColumn.style'

// fixes issue where styled-bootstrap-grid applies flex-basis:0 which takes precedence over min-height
// causing items overlapping each other when direction is set to column or column-reverse
// below fn resets flex-basis to default value
const flexBasis = (direction: string): string | undefined => {
  if (direction === 'column' || direction === 'column-reverse') {
    return `
      > ${GridColumn} {
          flex-basis: auto;
      }
    `
  }
}

export const GridRow = styled(Row)(
  (props: StyledGridRow): FlattenSimpleInterpolation => css`
    ${props.noGutters &&
    `
    margin-right: 0;
    margin-left: 0;
      > ${GridColumn} {
        padding-right: 0;
        padding-left: 0;
      }
  `}

    ${props.marginTop && spacing('margin-top', props.marginTop)}

  ${props.marginBottom && spacing('margin-bottom', props.marginBottom)}

  ${props.direction &&
    `
    flex-direction: ${props.direction};
    ${flexBasis(props.direction)}
  `}

  ${props.directionMd &&
    respondTo.md(css`
      flex-direction: ${props.directionMd};
      ${flexBasis(props.directionMd)}
    `)}

  ${props.directionLg &&
    respondTo.lg(css`
      flex-direction: ${props.directionLg};
      ${flexBasis(props.directionLg)}
    `)}

  ${props.directionXl &&
    respondTo.xl(css`
      flex-direction: ${props.directionXl};
      ${flexBasis(props.directionXl)}
    `)}

  ${props.disableFlex && 'display: block;'}
  `,
)
