import React, { FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/LinkWithIcon.style'

import { LinkWithIconProps } from './LinkWithIcon.types'
import { Appearance } from './themes/LinkWithIcon.theme.types'

import localThemes from './themes/LinkWithIcon.theme'

export const LinkWithIcon: FC<LinkWithIconProps> = ({
  appearance = 'primary',
  htmlAttributes = {},
  size = 2,
  inverse = false,
  href,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  theme,
  id,
  srText,
  icon,
  text,
  customRouterProps,
}) => {
  const localTheme = localThemes(theme!)

  const getIconAppearance = (): Appearance['iconAppearance'] => {
    if (inverse) return localTheme.appearance[appearance].inverse.iconAppearance

    return localTheme.appearance[appearance].iconAppearance
  }

  return (
    <Styled.LinkWithIcon
      appearance={appearance!}
      inverse={inverse!}
      href={href}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      target={htmlAttributes!.target}
      dataAttributes={htmlAttributes!.dataAttributes}
      id={id}
      label={srText}
      customRouterProps={customRouterProps}
    >
      <Styled.IconContainer inverse={inverse!} justify={icon.justify!} appearance={appearance} size={size}>
        <Icon name={icon.name} group={icon.group || 'system'} inverse={inverse} size={size} appearance={getIconAppearance()} />
      </Styled.IconContainer>
      <Styled.LinkWithIconText size={size}>{text}</Styled.LinkWithIconText>
    </Styled.LinkWithIcon>
  )
}

export default withTheme(LinkWithIcon)
