import React, { ReactElement, FC } from 'react'
import Block from '@vfuk/core-block'

import * as Styled from './styles/FunctionalContentBlock.style'

import { FunctionalContentBlockProps } from './FunctionalContentBlock.types'

import { ButtonGroup, Footnote, Header, ContentRenderer } from './components'

import localTheme from './themes/FunctionalContentBlock.theme'

const FunctionalContentBlock: FC<FunctionalContentBlockProps> = ({
  content,
  mediaPosition = 'center',
  heading,
  primaryButton,
  secondaryButton,
  footnote,
  children,
  appearance = 'primary',
}: FunctionalContentBlockProps): ReactElement => {
  const localAppearance = localTheme.appearance[appearance!]
  return (
    <Block appearance={localAppearance.blockAppearance} paddingLevel={0}>
      <Styled.FunctionalContentBlock mediaPosition={mediaPosition}>
        <If condition={children && (mediaPosition === 'left' || mediaPosition === 'right')}>{children}</If>
        <Styled.ContentWrapper mediaPosition={mediaPosition}>
          <If condition={heading}>
            <Header inverse={localAppearance.inverseContent} heading={heading} position={mediaPosition} />
          </If>
          <If condition={content}>
            <ContentRenderer inverse={localAppearance.inverseContent} content={content} />
          </If>
          <If condition={children && mediaPosition === 'center'}>{children}</If>
          <If condition={primaryButton?.text || secondaryButton?.text}>
            <ButtonGroup
              inverse={localAppearance.inverseContent}
              primaryButton={primaryButton}
              secondaryButton={secondaryButton}
              mediaPosition={mediaPosition}
            />
          </If>
          <If condition={footnote}>
            <Footnote inverse={localAppearance.inverseContent} icon={footnote?.icon} text={footnote?.text} />
          </If>
        </Styled.ContentWrapper>
      </Styled.FunctionalContentBlock>
    </Block>
  )
}

export default FunctionalContentBlock
