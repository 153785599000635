/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledTabsProps } from './Tabs.style.types'

export const TabListContainer = styled.div(
  (props: Pick<StyledTabsProps, 'theme'>): FlattenSimpleInterpolation => css`
    position: relative;
    border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome3.default};

    button {
      width: 100%;
    }
  `,
)

export const TabList = styled.div<Pick<StyledTabsProps, 'overflowActive'>>(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
)

export const TabTitleContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    width: 100%;
  `,
)

export const OverflowMenuContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    bottom: 0;
  `,
)
