/* eslint-disable camelcase */
import axios from 'axios';
import {
  application,
  apiUrl,
  apigeeConfigApiUrl,
  apigeeConfigApiKey,
  envId
} from '../settings.json';

class ConfigService {
  fetchConfig = async () => {
    const headers = apigeeConfigApiUrl
      ? {
          'content-type': 'application/json',
          Authorization: `Basic ${apigeeConfigApiKey}`
        }
      : {
          'content-type': 'application/json'
        };
    const url = apigeeConfigApiUrl
      ? `${apigeeConfigApiUrl}/${window.REACT_APP_ENV_ID ||
          envId}/${application}`
      : `${apiUrl}/coreapp/configuration/${window.REACT_APP_ENV_ID ||
          envId}/${application}`;
    const { data } = await axios({
      method: 'get',
      url,
      headers
    });
    return {
      ...data,
      fetchDate: Date.now()
    };
  };
}

const inst = new ConfigService();
export default inst;
