/* eslint-disable max-len */
import React, { PureComponent, ReactNode, Fragment, ReactElement, createRef } from 'react'
import { v4 as uuid } from 'uuid'

import Icon from '@vfuk/core-icon'
import Heading from '@vfuk/core-heading'
import Link from '@vfuk/core-link'
import LinkWithIcon from '@vfuk/core-link-with-icon'
import MatchMedia from '@vfuk/core-match-media'
import { withTranslation } from 'react-i18next'

import { InteractionProps, InteractionEvent } from '@vfuk/core-interaction/dist/Interaction.types'

import * as Styled from './styles/FunctionalStepper.style'

import {
  FunctionalStepperProps,
  FunctionalStepperState,
  LinkWithIconDisabledStateProps,
  GetStateIconPropsReturn,
  Step,
  StepOnClickProp,
} from './FunctionalStepper.types'

export class FunctionalStepper extends PureComponent<FunctionalStepperProps, FunctionalStepperState> {
  public constructor(props: FunctionalStepperProps) {
    super(props)
    this.state = {
      id: props.id ? props.id : uuid(),
    }
  }

  public static defaultProps: Partial<FunctionalStepperProps> = {
    inverse: false,
    currentStep: 1,
    hideControls: false,
  }

  private domRefs: InteractionProps['domRef'][] = []

  public componentDidMount(): void {
    const currentStepIndex = this.props.currentStep! - 1
    const currentStep = this.props.steps[currentStepIndex]

    // Handles cases where component is rendered with the initial step being a link
    if (currentStep.href || currentStep.customRouterProps) this.handleExternalStepClick(currentStepIndex)
  }

  private getStateIconProps(index: number): GetStateIconPropsReturn {
    const isCurrentStep = index + 1 === this.props.currentStep
    const state = this.props.steps[index].state

    if (this.props.inverse) return !isCurrentStep ? { state } : { inverse: false }

    return !isCurrentStep ? { state } : { inverse: true }
  }

  private get backDisabledProps(): LinkWithIconDisabledStateProps {
    // If current step is the first step, disable the back link
    if (this.props.currentStep === 1)
      return {
        state: 'disabled',
      }

    // Otherwise the back button is always active
    return {}
  }

  private get nextDisabledProps(): LinkWithIconDisabledStateProps {
    // If current step is the same as the number of steps, disable the next link
    if (this.props.currentStep === this.props.steps.length)
      return {
        state: 'disabled',
      }

    // If the step after the current step is in error or success, make the next link active
    const nextStepState = this.props.steps[this.props.currentStep!].state
    if (nextStepState) return {}

    // Otherwise it must be active
    return {}
  }

  private getStepOnClick(step: Step, index: number): StepOnClickProp {
    // If the step has the onClick method attached to it, then it allows it to be clickable
    if (step.onClick)
      return {
        onClick: (event: InteractionEvent): void => {
          this.handleStepCallback(event, index)
        },
      }
    // Otherwise it doesn't pass the onClick method to the StepContainer
    return {}
  }

  private handleStepCallback(event: InteractionEvent, targetIndex: number): void {
    const targetStep = this.props.steps[targetIndex]

    if (targetStep.onClick) targetStep.onClick(event)
  }

  private handleExternalStepClick = (targetIndex: number): void => {
    const targetStepRef = this.domRefs[targetIndex]

    if (targetStepRef && targetStepRef.current) targetStepRef.current.click()
  }

  public render(): ReactNode {
    const currentStepIndex = this.props.currentStep! - 1

    return (
      <Styled.FunctionalStepper id={this.state.id} inverse={this.props.inverse!}>
        <Styled.Steps>
          {this.props.steps.map((step, index): ReactElement => {
            const isCurrentStep = index + 1 === this.props.currentStep

            this.domRefs.push(createRef())

            return (
              <Styled.StepContainer
                id={step.id || `${this.state.id}-step-${index}`}
                key={index}
                interactionRef={this.domRefs[index]}
                state={step.state}
                inverse={this.props.inverse!}
                customRouterProps={step.customRouterProps}
                href={step.href}
                {...this.getStepOnClick(step, index)}
              >
                <Styled.StepWrapper>
                  <Styled.Step state={step.state} isCurrentStep={isCurrentStep} inverse={this.props.inverse!}>
                    <Choose>
                      <When condition={step.state === 'success'}>
                        <Icon name='success' group='state' size={2} {...this.getStateIconProps(index)} />
                      </When>
                      <When condition={step.state === 'error'}>
                        <Icon name='error' group='state' size={2} {...this.getStateIconProps(index)} />
                      </When>
                      <When condition={step.state === 'warn'}>
                        <Icon name='warn' group='state' size={2} {...this.getStateIconProps(index)} />
                      </When>
                      <When condition={step.state === 'info'}>
                        <Icon name='info' group='state' size={2} {...this.getStateIconProps(index)} />
                      </When>
                      <When condition={step.icon}>
                        <Icon
                          name={step.icon!.name}
                          group={step.icon!.group}
                          size={2}
                          inverse={this.props.inverse ? !isCurrentStep : isCurrentStep}
                        />
                      </When>
                      <Otherwise>
                        <span>{index + 1}</span>
                      </Otherwise>
                    </Choose>
                  </Styled.Step>
                  <Styled.TextWrapper state={step.state} inverse={this.props.inverse}>
                    {step.title}
                  </Styled.TextWrapper>
                </Styled.StepWrapper>
              </Styled.StepContainer>
            )
          })}
        </Styled.Steps>
        <MatchMedia breakpoint='sm'>
          <Styled.StepperMobileControlsContainer>
            <Styled.StepperMobileControls>
              <Choose>
                <When condition={this.props.hideControls}>
                  <Heading size={1} weight={3} noMargin justify='center'>
                    {this.props.steps[this.props.currentStep! - 1].title}
                  </Heading>
                </When>
                <Otherwise>
                  <LinkWithIcon
                    appearance='secondary'
                    text={this.props.t!('Back')}
                    icon={{ name: 'chevron-left', justify: 'left' }}
                    onClick={(): void => {
                      this.handleExternalStepClick(currentStepIndex - 1)
                    }}
                    {...this.backDisabledProps}
                  />
                  <Heading size={1} weight={3} noMargin justify='center' inverse={this.props.inverse}>
                    {this.props.steps[this.props.currentStep! - 1].title}
                  </Heading>
                  <LinkWithIcon
                    appearance='secondary'
                    text={this.props.t!('Next')}
                    icon={{ name: 'chevron-right', justify: 'right' }}
                    onClick={(): void => {
                      this.handleExternalStepClick(currentStepIndex + 1)
                    }}
                    {...this.nextDisabledProps}
                  />
                </Otherwise>
              </Choose>
            </Styled.StepperMobileControls>
            <If condition={this.props.steps.find((step) => step.errorMessage)}>
              <Styled.ErrorListGroup>
                {this.props.steps.map((step, index) => {
                  return (
                    <Fragment key={index}>
                      <If condition={step.errorMessage && (step.state === 'error' || 'warn' || 'info')}>
                        <Styled.ErrorListItem>
                          <Link
                            text={step.errorMessage}
                            onClick={(): void => {
                              this.handleExternalStepClick(index)
                            }}
                            showUnderline
                          />
                        </Styled.ErrorListItem>
                      </If>
                    </Fragment>
                  )
                })}
              </Styled.ErrorListGroup>
            </If>
          </Styled.StepperMobileControlsContainer>
        </MatchMedia>
      </Styled.FunctionalStepper>
    )
  }
}

export default withTranslation()(FunctionalStepper)
