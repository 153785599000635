import { ThemeName } from '@vfuk/core-themes/dist/constants/themeName.types'
import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS2 from './MarketingShout.ws2.theme'
import WS10 from './MarketingShout.ws10.theme'

import { MarketingShoutTheme } from './MarketingShout.theme.types'

type LocalThemes = {
  [P in ThemeName]: (theme: Theme) => MarketingShoutTheme
}

const localThemes: LocalThemes = {
  WS2,
  WS10,
  VOXI: WS10,
}

const getLocalThemes = (theme: Theme): MarketingShoutTheme => {
  return localThemes[theme.name](theme)
}
export default getLocalThemes
