import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

export const HorizontalStepper = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    overflow: auto;
    justify-content: center safe;
    ${spacing('padding-top', 1)};
  `,
)
