import React, { FC, ReactElement, useEffect, cloneElement, RefObject } from 'react'
import { withTheme } from 'styled-components'
import Heading from '@vfuk/core-heading'
import Divider from '@vfuk/core-divider'
import StateNotification from '@vfuk/core-state-notification'
import SimpleNotification from '@vfuk/core-simple-notification'
import Advert from '@vfuk/core-advert'
import SimpleGrid from '@vfuk/core-simple-grid'
import MatchMedia from '@vfuk/core-match-media'
import FunctionalCarousel from '@vfuk/core-functional-carousel'

import { HeadingProps } from '@vfuk/core-heading/dist/Heading.types'

import * as Styled from './styles/CardList.style'

import { CardListProps } from './CardList.types'
import localThemes from './themes/CardList.theme'

import parseCardsSection from './utils/parseCardsSection'
import getCardSectionHeadingLevel from './utils/getCardSectionHeadingLevel'
import getMaxHeight from './utils/getMaxHeight'

export const CardList: FC<CardListProps> = ({
  dividerTop,
  stateNotification,
  heading,
  subHeading,
  topContent,
  simpleNotification,
  advert,
  cardsSections,
  footerContent,
  dividerBottom,
  theme,
  renderSameHeight = false,
}: CardListProps): ReactElement => {
  const localTheme = localThemes(theme)
  const [maxHeight, setMaxHeight] = React.useState<number[][]>([])

  const [refArray, setRefArray] = React.useState(Array.from({ length: cardsSections.length }, (e) => Array(0)))

  useEffect(() => {
    if (renderSameHeight) {
      const max = getMaxHeight(refArray)
      setMaxHeight(max)
    }
  }, [refArray])

  const initializeRow = (value: number[][][], sectionIndex: number, rowIndex: number): void => {
    value[sectionIndex] = value[sectionIndex] || []
    value[sectionIndex][rowIndex] = []
  }

  const onRender = (ref: RefObject<HTMLDivElement>, sectionIndex: number, rowIndex: number): void => {
    setRefArray((prev) => {
      return prev.map((value, index) => {
        if (index === sectionIndex) {
          if (!value[sectionIndex] || !value[sectionIndex][rowIndex]) initializeRow(value, sectionIndex, rowIndex)
          value[sectionIndex][rowIndex].push(ref.current?.clientHeight)
        }
        return value
      })
    })
  }

  return (
    <Styled.CardList>
      <If condition={dividerTop}>
        <Styled.Section>
          <Divider appearance='secondary' noMargin />
        </Styled.Section>
      </If>
      <If condition={stateNotification?.state}>
        <Styled.Section>
          <StateNotification {...stateNotification} />
        </Styled.Section>
      </If>
      <Styled.Section>
        <Heading {...heading} weight={3} size={3} noMargin />
      </Styled.Section>
      <If condition={subHeading?.text}>
        <Styled.Section>
          <Heading text={subHeading?.text} noMargin size={2} level={(heading.level + 1) as HeadingProps['level']} />
        </Styled.Section>
      </If>
      <If condition={topContent}>
        <Styled.Section>{topContent}</Styled.Section>
      </If>
      <If condition={simpleNotification?.text}>
        <Styled.Section>
          <SimpleNotification {...simpleNotification!} />
        </Styled.Section>
      </If>
      <If condition={advert?.headingText && !simpleNotification?.text}>
        <Styled.Section>
          <Advert
            headingText={advert!.headingText}
            image={advert!.image}
            text={advert!.text}
            countdownTimer={advert!.countdownTimer}
            href={advert!.href}
            customRouterProps={advert!.customRouterProps}
            onClick={advert!.onClick}
            onMouseDown={advert!.onMouseDown}
            onMouseUp={advert!.onMouseUp}
            onTouchStart={advert!.onTouchStart}
            onTouchEnd={advert!.onTouchEnd}
          />
        </Styled.Section>
      </If>
      {cardsSections.map((cardSection, sectionIndex) => {
        return (
          <Styled.CardsSectionContainer
            cardsSectionContainerColumns={
              cardSection.size ? localTheme.cardSection.size[cardSection.size].columns : localTheme.cardSection.size[5].columns
            }
            key={cardSection.heading ? cardSection.heading.text.toLowerCase().replace(' ', '-') : `grid-section-${sectionIndex}`}
          >
            <If condition={cardSection.heading?.text}>
              <Styled.Section>
                <Heading
                  text={cardSection.heading!.text}
                  size={3}
                  weight={3}
                  level={getCardSectionHeadingLevel({ type: 'heading', baseHeadingLevel: heading.level })}
                />
              </Styled.Section>
            </If>
            <If condition={cardSection.subHeading?.text && cardSection.heading?.text}>
              <Styled.Section>
                <Heading
                  text={cardSection.subHeading!.text}
                  size={1}
                  level={getCardSectionHeadingLevel({ type: 'subHeading', baseHeadingLevel: heading.level })}
                />
              </Styled.Section>
            </If>
            <Styled.Cards>
              {parseCardsSection(cardSection, renderSameHeight).map((breakpointCards, breakpointCardsIndex) => {
                return (
                  <MatchMedia
                    key={`grid-section-${sectionIndex}-${breakpointCards.breakpoint}-${breakpointCardsIndex}`}
                    breakpoint={breakpointCards.breakpoint}
                    andAbove={breakpointCardsIndex + 1 === Object.keys(cardSection.grid.columns).length}
                  >
                    <Choose>
                      <When condition={cardSection.carousel}>
                        <FunctionalCarousel
                          slidesToScroll={{
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                          }}
                          {...cardSection.carousel}
                          slidesToShow={{
                            xl: 3,
                            lg: 3,
                            md: 2,
                            sm: 1,
                          }}
                          infiniteLoop
                        >
                          {breakpointCards.cards}
                        </FunctionalCarousel>
                      </When>
                      <Otherwise>
                        <SimpleGrid columns={cardSection.grid.columns} spacing={localTheme.cardSection.gridSpacing}>
                          <Choose>
                            <When condition={renderSameHeight && Number(cardSection.grid.columns[breakpointCards.breakpoint]) > 1}>
                              {breakpointCards.cards.map((cardRow: ReactElement[], cardRowIndex) => {
                                return cardRow.map((card: ReactElement) => {
                                  return cloneElement(card, {
                                    slotsWrapperHeight: maxHeight[sectionIndex] ? maxHeight[sectionIndex][cardRowIndex] : 0,
                                    onRefRender: (ref: RefObject<HTMLDivElement>) => onRender(ref, sectionIndex, cardRowIndex),
                                  })
                                })
                              })}
                            </When>
                            <Otherwise>{breakpointCards.cards}</Otherwise>
                          </Choose>
                        </SimpleGrid>
                      </Otherwise>
                    </Choose>
                  </MatchMedia>
                )
              })}
            </Styled.Cards>
          </Styled.CardsSectionContainer>
        )
      })}
      <If condition={footerContent}>
        <Styled.Section>{footerContent}</Styled.Section>
      </If>
      <If condition={dividerBottom}>
        <Styled.Section>
          <Divider appearance='secondary' noMargin />
        </Styled.Section>
      </If>
    </Styled.CardList>
  )
}

export default withTheme(CardList)
