/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { OverlayProvider } from '@vfuk/core-overlay-controller';
import Modal from '@vfuk/core-modal';
import Link from '@vfuk/core-link';
import Divider from '@vfuk/core-divider';

const Sidebar = ({ menuItems, openFlyout, setOpenFlyout }) => {
  const SideMenu = menuItems.map(i => (
    <>
      <Link to={i.route} text={i.name} />
      <Divider />
    </>
  ));
  return (
    <OverlayProvider zIndex={10000}>
      <Modal
        isOpen={openFlyout}
        size={4}
        type="flyout"
        side="left"
        animate
        isClosable
        onCloseCb={() => {
          setOpenFlyout(false);
        }}
      >
        <div style={{ maxWidth: '80%', margin: 'auto', marginTop: '20px' }}>
          {SideMenu}
        </div>
      </Modal>
    </OverlayProvider>
  );
};

const mapStateToProps = (state) => ({
  menuItems: state.pages.menuItems,
});

export default connect(mapStateToProps)(Sidebar);
