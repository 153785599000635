import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ContainerTheme } from './Container.theme.types'

const containerLocal = (theme: Theme): ContainerTheme => {
  return {
    paddingLevel: {
      0: {
        spacingValue: 0,
      },
      1: {
        spacingValue: 2,
      },
      2: {
        spacingValue: 3,
      },
      3: {
        spacingValue: 4,
      },
      4: {
        spacingValue: 5,
      },
      5: {
        spacingValue: 6,
      },
      6: {
        spacingValue: 7,
      },
    },
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        color: theme.baseFont.color,
        highlight: {
          primary: {
            borderColor: theme.color.primary1.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: theme.color.secondary1.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: theme.color.secondary4.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: theme.color.warn.default,
          },
          error: {
            borderColor: theme.color.error.default,
          },
          success: {
            borderColor: theme.color.success.default,
          },
          info: {
            borderColor: theme.color.info.default,
          },
          incomplete: {
            borderColor: theme.color.incomplete.default,
          },
        },
      },
      secondary: {
        backgroundColor: theme.color.monochrome2.default,
        color: theme.color.monochrome6.default,
        highlight: {
          primary: {
            borderColor: theme.color.primary1.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: theme.color.secondary1.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: theme.color.secondary4.default,
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: theme.color.warn.default,
          },
          error: {
            borderColor: theme.color.error.default,
          },
          success: {
            borderColor: theme.color.success.default,
          },
          info: {
            borderColor: theme.color.info.default,
          },
          incomplete: {
            borderColor: theme.color.incomplete.default,
          },
        },
      },
      alt1: {
        backgroundColor: theme.color.monochrome5.default,
        color: theme.color.monochrome1.default,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt2: {
        backgroundColor: `linear-gradient(${theme.color.gradient1.default})`,
        color: theme.color.monochrome1.default,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt3: {
        backgroundColor: `linear-gradient(${theme.color.gradient2.default})`,
        color: theme.color.monochrome1.default,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt4: {
        backgroundColor: theme.color.primary1.default,
        color: theme.color.monochrome1.default,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
      alt5: {
        backgroundColor: theme.color.secondary1.default,
        color: theme.color.secondary1.default,
        highlight: {
          primary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          secondary: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
          alt1: {
            borderColor: 'transparent',
            level: {
              1: {
                borderWidth: 1,
              },
              2: {
                borderWidth: 2,
              },
            },
          },
        },
        state: {
          warn: {
            borderColor: 'transparent',
          },
          error: {
            borderColor: 'transparent',
          },
          success: {
            borderColor: 'transparent',
          },
          info: {
            borderColor: 'transparent',
          },
          incomplete: {
            borderColor: 'transparent',
          },
        },
      },
    },
  }
}

export default containerLocal
