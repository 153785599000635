import React, { ReactNode, PureComponent, Children } from 'react'

import Paragraph from '@vfuk/core-paragraph'
import ContainerWithLabel from '@vfuk/core-container-with-label'
import Divider from '@vfuk/core-divider'

import * as Styled from './styles/CardBuilder.style'

import { CardBuilderProps, lineBreakSpacerShape } from './CardBuilder.types'

export default class CardBuilder extends PureComponent<CardBuilderProps> {
  ref = React.createRef<HTMLDivElement>()

  get children(): ReactNode[] {
    return Children.toArray(this.props.children)
  }

  get validLineBreakSpacer(): lineBreakSpacerShape[] | undefined {
    /**
     * If children is and array and lineBreakSpacer is truthy, if lineBreakSpacer is greater than or equal to zero
     * and if lineBreakSpacer is less than or equal to the amount of children passed, return the lineBreakSpacer
     *  */
    if (
      this.props.lineBreakSpacer &&
      this.props.lineBreakSpacer.length > 0 &&
      Math.max(...this.props.lineBreakSpacer.map((line) => line.slotIndex)) <= this.children.length
    )
      return this.props.lineBreakSpacer
  }

  public componentDidMount(): void {
    setTimeout(() => {
      if (this.props.onRefRender) {
        this.props.onRefRender(this.ref)
      }
    }, 100)
  }

  render(): ReactNode {
    return (
      <Styled.CardBuilder>
        <ContainerWithLabel {...this.props.container} paddingLevel={0} allowOverflow={false}>
          <Styled.SlotsWrapper ref={this.ref} slotsWrapperHeight={this.props.slotsWrapperHeight}>
            {this.children.map((child, index) => {
              const lineBreakSpacer = this.validLineBreakSpacer?.find((spacer) => spacer.slotIndex === index)
              return (
                <Styled.CardSlots key={index} lineBreakPosition={lineBreakSpacer?.position}>
                  <If condition={this.props.dividers?.[index]}>
                    <Styled.DividerWrapper>
                      <Divider appearance={this.props.dividers?.[index]} />
                    </Styled.DividerWrapper>
                  </If>
                  {child}
                </Styled.CardSlots>
              )
            })}
          </Styled.SlotsWrapper>
        </ContainerWithLabel>
        <If condition={this.props.footerText}>
          <Styled.CardBuilderFooterTextWrapper>
            <Paragraph size={1}>{this.props.footerText}</Paragraph>
          </Styled.CardBuilderFooterTextWrapper>
        </If>
      </Styled.CardBuilder>
    )
  }
}
