import React, { PureComponent, ReactNode } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'
import Animate from '@vfuk/core-animate'
import IconButton from '@vfuk/core-icon-button'
import TextInput from '@vfuk/core-text-input'
import { TextInputProps } from '@vfuk/core-text-input/dist/TextInput.types'
import { InteractiveIconProps } from '@vfuk/core-interactive-icon/dist/InteractiveIcon.types'

import * as Styled from './styles/SearchTextInput.style'

import { SearchTextInputProps } from './SearchTextInput.types'

export default class SearchTextInput extends PureComponent<SearchTextInputProps> {
  private get showClearButton(): boolean {
    return !!(this.props.textInput.value && this.props.textInput.value.length)
  }

  private get clearButtonState(): InteractiveIconProps['state'] {
    if (this.props.state === 'disabled' || this.props.state === 'loading') return 'disabled'
    return undefined
  }

  public render(): ReactNode {
    return (
      <Styled.SearchWrapper>
        <Styled.SearchInput>
          <Styled.Input>
            <TextInput
              id={this.props.textInput.id}
              name={this.props.textInput.name}
              placeholder={this.props.textInput.placeholder}
              required={this.props.textInput.required}
              dataAttributes={this.props.textInput.dataAttributes}
              beforeChange={this.props.textInput.beforeChange}
              autoComplete={this.props.textInput.autoComplete}
              autoCapitalize={this.props.textInput.autoCapitalize}
              state={this.props.state as TextInputProps['state']}
              type='search'
              onChange={this.props.textInput.onChange}
              value={this.props.textInput.value}
              describedBy={this.props.describedBy}
              domRef={this.props.textInput.domRef}
            />
          </Styled.Input>
          <Styled.InteractionIcons>
            <Animate
              show={this.showClearButton}
              enter={{
                animations: ['fadeIn'],
                duration: 500,
              }}
              exit={{
                animations: ['fadeOut'],
              }}
            >
              <InteractiveIcon
                name='close'
                srText={`${this.props.t!('Clear')} ${this.props.label.toLowerCase()}`}
                size={2}
                onClick={this.props.onClear}
                state={this.clearButtonState}
              />
            </Animate>
          </Styled.InteractionIcons>
        </Styled.SearchInput>
        <Styled.SearchIcon size={4} hasState={this.props.state === 'error' || this.props.state === 'success'}>
          <IconButton
            id={`${this.props.textInput.id}-trigger`}
            loading={this.props.state === 'loading'}
            srText={this.props.searchIconButton?.srText ? this.props.searchIconButton.srText : this.props.t!('Search')}
            onClick={this.props.handleSubmit}
            size={4}
            icon={{ name: 'search' }}
          />
        </Styled.SearchIcon>
      </Styled.SearchWrapper>
    )
  }
}
