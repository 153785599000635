import { Dictionary } from '../../dictionary.types'

const en: Dictionary = {
  Optional: 'Optional',
  Required: 'Required',
  Show: 'Show',
  Hide: 'Hide',
  Search: 'Search',
  Clear: 'Clear',
  'No results found': 'No results found for {{value}}',
  'Go back': 'Go back',
  Carousel: 'Carousel',
  Edit: 'Edit',
  Item: '(0)[Item];(1-inf)[Items];',
  'Go to': 'Go to',
  Back: 'Back',
  'Go to the previous page': 'Go to the previous page',
  'Go to the next page': 'Go to the next page',
  Play: 'Play',
  Pause: 'Pause',
  'Show more': 'Show more',
  Next: 'Next',
  'Clear filters': 'Clear filters',
  'Sort by': 'Sort by',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Retry: 'Retry',
  Undo: 'Undo',
  Apply: 'Apply',
  'Clear all': 'Clear all',
  Error: 'Error',
  Complete: 'Complete',
  Incomplete: 'Incomplete',
  Loading: 'Loading',
  Close: 'Close',
  Reset: 'Reset',
  'Find out more': 'Find out more',
  Total: 'Total',
  Iteration: '{{iterator}} of {{array}}',
  'Use your current location': 'Use your current location',
  'Zoom in': 'Zoom in',
  'Navigates to': 'Navigates to {{direction}}',
  'Filter options': 'Filter options',
  Filter: '(0)[Filter];(1-inf)[Filters];',
  Applied: 'Applied',
  'Language name': 'English',
  'More info': 'More info',
}

export default en
