import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { NotificationWrapperProps, StyledStateNotificationProps } from './StateNotification.style.types'

type StateNotificationProps = Pick<StyledStateNotificationProps, 'theme'>
export const StateNotification = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
`)

export const IconWrapper = styled.div((props: StyledStateNotificationProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.advancedSpacing('padding', [4])};
  background: ${props.theme.color[props.state].default};
`)

type ContentWrapperProps = Pick<StyledStateNotificationProps, 'theme'>
export const ContentWrapper = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.advancedSpacing('padding', [4])};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
`)

export const NotificationWrapper = styled.div((props: NotificationWrapperProps): FlattenSimpleInterpolation => css`
  width: 100%;
  display: flex;
  ${props.hasButton && `
    flex-direction: column;
  `}
  ${props.hasButton && props.theme.mixins.respondTo.md(css`
    flex-direction: row;
  `)}
`)

export const CloseButton = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${props.theme.mixins.advancedSpacing('padding', [4, 4, 0, 0])};
  align-self: flex-start;
`)

export const ButtonWrapper = styled.div((props: StateNotificationProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: flex-start;
  ${props.theme.mixins.spacing('padding', 4)}

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
  `)}
`)
