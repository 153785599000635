/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledLinkProps } from './Link.style.types'

export const Link = styled(Interaction)((props: StyledLinkProps): FlattenSimpleInterpolation => {
  return css`
    color: ${props.theme.color.monochrome6.default};
    transition: 0.4s all ease;

    ${props.showUnderline && `
      text-decoration: underline;
    `}

    ${props.inverse && `
      color: ${props.theme.inverseColor.monochrome6.default};
    `}

    ${props.size === 1 && css`
      ${props.theme.mixins.typography.paragraph(1, false)};
    `}

    ${props.size === 2 && css`
      ${props.theme.mixins.typography.paragraph(2, false)};
    `}

    ${props.size === 3 && css`
      ${props.theme.mixins.typography.heading(1, false)};
    `}

    ${props.size === 4 && css`
      ${props.theme.mixins.typography.heading(2, false)};
    `}

    &:hover {
      color: ${props.theme.color.secondary7.default};
      text-decoration: underline;

      ${props.inverse && css`
        color: ${props.theme.inverseColor.secondary7.default};
      `}
    }

    &:focus {
      text-decoration: underline;
    }
  `
})
