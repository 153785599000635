import React, { PureComponent, ReactNode } from 'react'

import { OverlayProviderProps, OverlayProviderState, OverlayProviderPartial, RenderedOverlay, OverlayState } from './OverlayProvider.types'

import controller from '../controller/OverlayController'

import ModalRenderer from '../renderers/ModalRenderer/ModalRenderer'
import FlyoutRenderer from '../renderers/FlyoutRenderer/FlyoutRenderer'
import { OverlayCloseSource } from '../renderers/constants/constants'

const initialOverlayState: OverlayState = {
  id: '',
  isShowing: false,
  isTransitioning: false,
  content: null,
  initialFocusId: null,
  focusEnabledRefs: undefined,
  animate: true,
  isClosable: true,
  onCloseCb: null,
  srName: '',
  type: 'modal',
  renderOptions: {},
}

const ANIMATION_DELAY: number = 200

interface OverlayRendersMap {
  modal: typeof ModalRenderer
  flyout: typeof FlyoutRenderer
}

export default class OverlayProvider extends PureComponent<OverlayProviderProps, OverlayProviderState> {
  public static renderers: OverlayRendersMap = {
    modal: ModalRenderer,
    flyout: FlyoutRenderer,
  }

  public static defaultProps: Partial<OverlayProviderProps> = {
    zIndex: 100,
  }

  constructor(props: OverlayProviderProps) {
    super(props)
    controller.setProvider(this as OverlayProviderPartial)
  }

  state: OverlayProviderState = {
    renderedModals: [],
  }

  componentDidMount(): void {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  private handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      const { renderedModals } = this.state
      const shownOverlay = renderedModals[renderedModals.length - 1]

      if (!shownOverlay || !shownOverlay.isClosable) {
        return
      }
      this.removeOverlay(shownOverlay.id)
    }
  }

  public getModalById = (id: string): RenderedOverlay | null => {
    const { renderedModals } = this.state

    return renderedModals.find((modal) => modal.id === id) || null
  }

  public setStateForOverlay(overlayState: RenderedOverlay): void {
    this.setState(({ renderedModals }) => {
      const modalToRender = renderedModals.find((modal) => modal.id === overlayState.id)

      if (!modalToRender) {
        const newModals: OverlayState = { ...initialOverlayState, ...overlayState }
        return {
          renderedModals: [...renderedModals, newModals],
        }
      }

      const updatedModals = renderedModals.map((modal) => {
        return modal.id === overlayState.id ? { ...modal, ...overlayState } : modal
      })

      return {
        renderedModals: updatedModals,
      }
    })
  }

  public removeOverlay(id: string, source?: OverlayCloseSource): void {
    const overlay = this.getModalById(id)
    if (!overlay?.isShowing) return
    if (overlay?.onCloseCb) {
      overlay.onCloseCb(source)
    }
    this.setStateForOverlay({ id, isShowing: false })
    // delay removal from state to allow animation to finish
    setTimeout(() => {
      this.setState(({ renderedModals }) => {
        return { renderedModals: renderedModals.filter((modal) => modal.id !== id) }
      })
    }, ANIMATION_DELAY)
  }

  public reset(): void {
    this.setState({ renderedModals: [] })
  }

  public render(): ReactNode {
    return (
      <>
        {this.state.renderedModals.map((config, index) => {
          const { id, isShowing, animate, initialFocusId, focusEnabledRefs, isClosable, srName, type, renderOptions = {}, content } = config

          const Renderer = OverlayProvider.renderers[type || 'modal']
          return (
            <Renderer
              key={id}
              onClose={this.removeOverlay.bind(this, id)}
              hasClosed={controller.resetModalState}
              animate={animate}
              initialFocusId={initialFocusId}
              focusEnabledRefs={focusEnabledRefs}
              isClosable={isClosable}
              isOpen={isShowing}
              srName={srName}
              appearance={renderOptions?.appearance}
              size={renderOptions?.size}
              side={renderOptions?.side}
              overlayBlur={renderOptions?.overlayBlur}
              zIndex={this.props.zIndex!}
            >
              {content!}
            </Renderer>
          )
        })}
        {this.props.children}
      </>
    )
  }
}
