import React, { FC, ReactElement, useEffect, useRef, Fragment } from 'react'

import Step from './components/Step'

import * as Styled from './styles/HorizontalStepper.style'

import { HorizontalStepperProps } from './HorizontalStepper.types'

import scrollToStep from './helpers/scrollToStep'

const HorizontalStepper: FC<HorizontalStepperProps> = ({
  currentStep = 1,
  inverse = false,
  steps,
}: HorizontalStepperProps): ReactElement => {
  const stepperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollToStep({ stepperRef, currentStep })
  }, [currentStep])

  return (
    <Fragment>
      <Styled.HorizontalStepper ref={stepperRef}>
        {steps.map((step, index: number) => {
          const stepIndex = index + 1
          const isCompleted = stepIndex < currentStep
          const isActive = stepIndex === currentStep
          const isClickable = stepIndex < currentStep && step.onClick

          return (
            <Step
              key={stepIndex}
              inverse={inverse}
              isCompleted={isCompleted}
              isActive={isActive}
              onClick={isClickable ? (): void => step.onClick!(stepIndex) : undefined}
              title={step.title}
              srText={step.srText}
            />
          )
        })}
      </Styled.HorizontalStepper>
    </Fragment>
  )
}

export default HorizontalStepper
