import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo } from '@vfuk/core-mixins'

import { StyledInitialsAvatarProps } from './InitialsAvatar.style.types'

import localThemes from '../themes/InitialsAvatar.theme'

export const Initials = styled.span((props: StyledInitialsAvatarProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    color: ${localTheme.textColor};
    text-transform: uppercase;
    font-weight: ${props.theme.fontWeight[2]};
    font-size: ${localTheme.size[props.size!].sm};

    ${respondTo.md(css`
      font-size: ${localTheme.size[props.size!].md};
    `)}

    ${respondTo.lg(css`
      font-size: ${localTheme.size[props.size!].lg};
    `)}
  `
})
