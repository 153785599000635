import React, { FC, ReactElement } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import Span from '@vfuk/core-span'

import * as Styled from './styles/Tag.style'

import { TagProps } from './Tag.types'

import localThemes from './themes/Tag.theme'

export const Tag: FC<TagProps> = ({
  appearance = 'primary',
  htmlAttributes = {},
  inverse = false,
  theme,
  onClick,
  icon,
  text,
}): ReactElement => {
  const localTheme = localThemes(theme!).appearance[appearance!]
  const componentTheme = inverse ? localTheme.inverse : localTheme
  return (
    <Styled.Tag appearance={appearance} onClick={onClick} inverse={inverse} htmlAttributes={htmlAttributes}>
      <If condition={icon}>
        <Icon name={icon!.name} group='system' size={1} appearance='primary' inverse={componentTheme.inverseIcon} />
      </If>
      <Span weight={3} inverse={componentTheme.inverseText}>
        {text}
      </Span>
      <Icon name='close' group='system' size={1} appearance='primary' inverse={componentTheme.inverseIcon} />
    </Styled.Tag>
  )
}

export default withTheme(Tag)
