import React, { PureComponent, ReactNode } from 'react'

import dayjs from 'dayjs'

import Span from '@vfuk/core-span'
import AnimationContainer from '@vfuk/core-animation-container'

import secondsToDhms from './utils/secondsToDhms'

import TimerBlock from './components/TimerBlock'

import * as Styled from './styles/DateCountdown.style'

import { DateCountdownProps, DateCountdownState, units } from './DateCountdown.types'

export default class DateCountdown extends PureComponent<DateCountdownProps, DateCountdownState> {
  public static defaultProps: Partial<DateCountdownProps> = {
    size: 3,
  }

  public static countdownSections = ['days', 'hours', 'minutes', 'seconds']

  public static timerInterval = 1000

  private countdownTimer: null | number = null

  private delayTimer: null | number = null

  public state: DateCountdownState = {
    isExpired: false,
    remainingSeconds: 0,
    values: {
      days: 1,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  }

  componentDidMount(): void {
    this.initialiseTimer()
  }

  componentWillUnmount(): void {
    if (this.delayTimer) {
      this.stopDelay()
    }
    if (this.countdownTimer) {
      this.stopCountdown()
    }
  }

  private timerTick = (): void => {
    const remainingSeconds = this.state.remainingSeconds - 1
    if (remainingSeconds === 0) {
      this.stopCountdown()
      return
    }
    this.setState({
      remainingSeconds,
      values: {
        ...secondsToDhms(remainingSeconds),
      },
    })
  }

  private startCountdown(delay: number): void {
    this.delayTimer = setTimeout(() => {
      this.delayTimer = null
      this.countdownTimer = setInterval(this.timerTick, DateCountdown.timerInterval)
    }, delay * 1000)
  }

  private stopDelay(): void {
    clearTimeout(this.delayTimer!)
    this.delayTimer = null
  }

  private stopCountdown(): void {
    clearInterval(this.countdownTimer!)
    this.countdownTimer = null
    this.setState({
      isExpired: true,
      values: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    })
  }

  private initialiseTimer(): void {
    const now = Date.now()
    const startDate = this.props.startDate ? this.props.startDate : now
    const remainingSeconds = dayjs(this.props.endDate).diff(startDate, 'second')
    const delayStart = dayjs(startDate).diff(now, 'second')
    // It has not expired
    if (remainingSeconds > 0) {
      this.setState({
        remainingSeconds,
        values: {
          ...secondsToDhms(remainingSeconds),
        },
      })
      // Delays the start of the timer if needed
      this.startCountdown(delayStart < 0 ? 0 : delayStart)
      return
    }
    // It has already expired
    this.setState({
      remainingSeconds: 0,
      isExpired: true,
    })
  }

  public render(): ReactNode {
    return (
      <Styled.CountdownContainer size={this.props.size!} inverse={this.props.inverse}>
        <Choose>
          <When condition={this.state.isExpired && this.props.text?.expiry}>{this.props.text!.expiry}</When>
          <Otherwise>
            <If condition={this.props.text?.prefix}>
              <Span inverse={this.props.inverse}>{this.props.text!.prefix}</Span>
            </If>
            <AnimationContainer>
              <Styled.CountdownTimer
                showUnits={this.props.showUnits}
                text={this.props.text}
                size={this.props.size}
                orientation={this.props.orientation}
                role='timer'
                aria-live='off'
              >
                {DateCountdown.countdownSections.map((section, index) => {
                  if (this.props.format && !this.props.format[section as units]) return null
                  return (
                    <Styled.CountdownSection key={index}>
                      <TimerBlock
                        inverse={this.props.inverse}
                        unit={section}
                        showUnit={this.props.showUnits && this.props.size === 3}
                        remainingTime={this.state.values[section as units]}
                      />
                      <Span inverse={this.props.inverse}>:</Span>
                    </Styled.CountdownSection>
                  )
                })}
              </Styled.CountdownTimer>
            </AnimationContainer>
            <If condition={this.props.text && this.props.text.suffix}>
              <Span inverse={this.props.inverse}>{this.props.text!.suffix}</Span>
            </If>
          </Otherwise>
        </Choose>
      </Styled.CountdownContainer>
    )
  }
}
