import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing } from '@vfuk/core-mixins'

export const Footnote = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    text-align: left;

    ${respondTo.md(css`
      max-width: 648px;
      margin: 0 auto;
    `)}

    ${respondTo.lg(css`
      max-width: 620px;
    `)}
  `,
)

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 4)};
  `,
)
