import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { CheckBoxTheme } from './CheckBox.theme.types'

const checkBoxLocal = (theme: Theme): CheckBoxTheme => {
  return {
    iconGroup: 'system',
    iconName: 'tick',
    indeterminateIconName: 'minus',
    borderColor: theme.color.monochrome4.default,
    state: {
      checked: {
        backgroundColor: theme.color.secondary1.default,
      },
    },
  }
}

export default checkBoxLocal
