import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo, spacing } from '@vfuk/core-mixins'

import { StyledFunctionalContentBlockProps } from './FunctionalContentBlock.style.types'

export const FunctionalContentBlock = styled.div(
  (props: StyledFunctionalContentBlockProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;

    ${respondTo.lg(css`
      ${props.mediaPosition === 'center' &&
      css`
        justify-content: center;
      `}

      ${props.mediaPosition === 'right' &&
      css`
        flex-direction: row-reverse;
      `}

    ${props.mediaPosition === 'left' &&
      css`
        flex-direction: row;
      `}
    `)};
  `,
)

// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
export const ContentWrapper = styled.div<StyledFunctionalContentBlockProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;
    ${spacing('gap', 6)};
    ${advancedSpacing('padding', [6, 4])};
    flex-direction: column;
    width: 100%;
    justify-content: center;

    ${respondTo.lg(css`
      ${(props.mediaPosition === 'left' || props.mediaPosition === 'right') &&
      css`
        width: 50%;
      `}

      ${props.mediaPosition === 'left' &&
      css`
        padding-left: ${props.theme.spacing.responsive[10].lg}px;
      `}

    ${props.mediaPosition === 'right' &&
      css`
        padding-right: ${props.theme.spacing.responsive[10].lg}px;
      `}
    `)};

    ${respondTo.xl(css`
      ${props.mediaPosition === 'left' &&
      css`
        padding-left: ${props.theme.spacing.responsive[10].xl}px;
      `}

      ${props.mediaPosition === 'right' &&
      css`
        padding-right: ${props.theme.spacing.responsive[10].xl}px;
      `}
    `)};
  `,
)
