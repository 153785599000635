import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Container } from '@vfuk/core-container/dist/styles/Container.style'
import { spacing } from '@vfuk/core-mixins'

import { StyledAccordionProps } from './Accordion.style.types'

export const Accordion = styled.div<StyledAccordionProps>(
  (props: StyledAccordionProps): FlattenSimpleInterpolation => css`
    & > ${Container} {
      ${props.level === 1 &&
      css`
        ${spacing('margin-bottom', 2)};

        &:last-child {
          margin-bottom: 0;
        }
      `}
    }
  `,
)
