import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, spacing } from '@vfuk/core-mixins'

export const BenefitItem = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    ${spacing('gap', 2)};
  `,
)

export const BenefitItemTextContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    height: 100%;
  `,
)

export const BenefitItemSubIconContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    ${advancedSpacing('margin', [2, 0])};
    align-items: center;
    ${spacing('gap', 2)};
  `,
)
