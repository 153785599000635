import browserCookie from 'browser-cookies'

import { Res } from './setCookie.types'

import isClient from '../isClient/isClient'

const setCookie = (name: string, value: string, expireAfterDays?: number, res?: Res): void => {
  if (res && res.cookie) {
    res.cookie(name, value, { expires: expireAfterDays })
  } else {
    const client = isClient()
    let secure = false
    if (client && document && document.location) {
      secure = document.location.protocol.toLowerCase().includes('s')
    }
    if (client) {
      browserCookie.set(name, value, { expires: expireAfterDays, secure })
    }
  }
}

export default setCookie
