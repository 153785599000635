import React, { FC, ReactElement } from 'react'
import { IframeResizerProps } from 'iframe-resizer-react'

import { IframeWrapperProps } from './IframeWrapper.types'
import * as Styled from './styles/IframeWrapper.style'

type IframeProps = Pick<
  IframeResizerProps,
  'width' | 'height' | 'src' | 'heightCalculationMethod' | 'allow' | 'title' | 'id' | 'sizeHeight'
>

const IframeWrapper: FC<IframeWrapperProps> = ({
  url,
  title,
  id,
  height = 'bodyOffset',
  width = '100%',
  allow = [],
}: IframeWrapperProps): ReactElement => {
  const isAutoResizeHeight = height === 'lowestElement' || height === 'bodyOffset'

  const iframeProps: IframeProps = {
    src: url,
    title,
    id,
    width,
    height: isAutoResizeHeight ? 'auto' : height,
    heightCalculationMethod: height === 'lowestElement' ? height : 'bodyOffset',
    sizeHeight: isAutoResizeHeight,
    allow: allow.join(';'),
  }

  return <Styled.Iframe {...iframeProps} />
}

export default IframeWrapper
