import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { elevation, respondTo, spacing, typography } from '@vfuk/core-mixins'

import { StyledTooltipProps } from './Tooltip.style.types'

import localThemes from '../themes/Tooltip.theme'

export const TooltipWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    max-width: 288px;

    ${respondTo.lg(css`
      max-width: 320px;
    `)}
  `,
)

type StyledTooltipTriggerProps = Pick<StyledTooltipProps, 'theme'>
export const TooltipTrigger = styled.span<StyledTooltipTriggerProps>(
  (props: StyledTooltipTriggerProps): FlattenSimpleInterpolation => css`
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }
  `,
)

type StyledTooltipArrowProps = Pick<StyledTooltipProps, 'theme' | 'appearance' | 'zIndex'>
export const TooltipArrow = styled.span<StyledTooltipArrowProps>((props: StyledTooltipArrowProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    &,
    &::before {
      position: absolute;
      width: 10px;
      height: 10px;
      box-shadow: ${props.theme.elevation.size}px 4px 4px -4px ${props.theme.elevation.color};
      z-index: ${props.zIndex};
    }

    &::before {
      content: '';
      background-color: ${localTheme.appearance[props.appearance].backgroundColor};
      transform: rotate(45deg);
    }
  `
})

export const TooltipBody = styled.div<StyledTooltipProps>((props: StyledTooltipProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    background-color: ${localTheme.appearance[props.appearance].backgroundColor};
    color: ${localTheme.appearance[props.appearance].color};
    border-radius: ${props.theme.border.radius[2]};
    ${elevation(true)}
    ${spacing('padding', 3)}
    z-index: ${props.zIndex};

    &[data-popper-placement^='top'] > ${TooltipArrow} {
      bottom: -5px;
    }

    &[data-popper-placement^='bottom'] > ${TooltipArrow} {
      top: -5px;
    }

    ${props.size === 1 &&
    css`
      ${typography.paragraph(1, false)}
    `}

    ${props.size === 2 &&
    css`
      ${typography.paragraph(2, false)}
    `}
  `
})
