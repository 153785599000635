import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Heading } from '@vfuk/core-heading/dist/styles/Heading.style'
import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import { StyledCardBenefitsSlotProps } from './CardBenefitsSlot.style.types'

export const CardBenefitsSlot = styled.div(
  (props: StyledCardBenefitsSlotProps): FlattenSimpleInterpolation => css`
    background-color: ${props.theme.color.monochrome2.default};
    ${advancedSpacing('padding', [3, 4])}
    ${advancedSpacing('margin', [2, 0])}

  ${Heading} {
      ${spacing('margin-bottom', 3)};
    }
  `,
)
