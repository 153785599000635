import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledParagraphProps } from './Paragraph.style.types'

import localThemes from '../themes/Paragraph.theme'

export const Paragraph = styled.p((props: StyledParagraphProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)

  return css`
    ${props.theme.mixins.typography.paragraph(props.size!, !props.noMargin)};
    
    ${props.appearance && `
      color: ${localTheme.appearance[props.appearance].color};
    `}

    ${props.inverse && `
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}

    ${props.weight && `
      font-weight: ${props.theme.fontWeight[props.weight]};
    `}

    ${props.justify && `
      text-align: ${props.justify};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  `
})
