import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/ContentRenderer.style'

import { ContentRendererProps } from './ContentRenderer.types'

const ContentRenderer: FC<ContentRendererProps> = ({ content, inverse }: ContentRendererProps): ReactElement => (
  <Styled.ContentText inverse={inverse} contentPosition={content?.contentPosition}>
    {content?.bodyText}
  </Styled.ContentText>
)

export default ContentRenderer
