import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import { StyledSpacingProps } from './Spacing.style.types'

export const Spacing = styled.div<StyledSpacingProps>(
  (props: StyledSpacingProps): FlattenSimpleInterpolation => css`
    ${props.spacingLevel.top &&
    css`
      ${spacing('margin-top', props.spacingLevel.top)}
    `}

    ${props.spacingLevel.right &&
    css`
      ${spacing('margin-right', props.spacingLevel.right)}
    `}

  ${props.spacingLevel.bottom &&
    css`
      ${spacing('margin-bottom', props.spacingLevel.bottom)}
    `}

  ${props.spacingLevel.left &&
    css`
      ${spacing('margin-left', props.spacingLevel.left)}
    `}
  `,
)
