import React, { FC, useMemo, Fragment } from 'react'
import Icon from '@vfuk/core-icon'
import Link from '@vfuk/core-link'
import Heading from '@vfuk/core-heading'

import * as Styled from './styles/CardHeadingAndIconSlot.style'

import { CardHeadingAndIconSlotProps } from './CardHeadingAndIconSlot.types'

const CardHeadingAndIconSlot: FC<CardHeadingAndIconSlotProps> = ({
  heading,
  icon,
  prefix,
  suffix,
  link,
  subIconGroup = 'system',
  subIcons = [],
  subIconSeparatorText,
  level = 1,
}) => {
  const subIconsWithGroup = useMemo(() => {
    return subIcons.map((subIcon) => ({ ...subIcon, group: subIconGroup }))
  }, [subIconGroup, subIcons])

  return (
    <Styled.CardHeadingAndIconSlot justifyIcon={icon?.justify ?? 'left'}>
      <Styled.IconContainer>
        <Choose>
          <When condition={icon?.component}>{icon!.component}</When>
          <When condition={icon?.name}>
            <Icon size={4} name={icon!.name} />
          </When>
        </Choose>
      </Styled.IconContainer>
      <Styled.HeadingContainer justifyIcon={icon?.justify ?? 'left'} hasIcon={!!icon}>
        <If condition={prefix}>
          <Styled.PreffixSuffixContainer level={level}>{prefix}</Styled.PreffixSuffixContainer>
        </If>
        <Heading size={level === 2 ? 1 : 2} level={heading.level} text={heading.name} weight={3} noMargin />
        <If condition={suffix}>
          <Styled.PreffixSuffixContainer level={level}>{suffix}</Styled.PreffixSuffixContainer>
        </If>
        <If condition={link}>
          <Styled.LinkContainer>
            <Link {...link} size={1} showUnderline />
          </Styled.LinkContainer>
        </If>
        <If condition={subIconsWithGroup.length}>
          {subIconsWithGroup.map((subIconProps, index) => {
            const showSeparatorText = index !== 0 && subIconSeparatorText
            return (
              <Fragment key={index}>
                {showSeparatorText && (
                  <Styled.IconsSeparatorText key={`separator-${index}`}>{subIconSeparatorText}</Styled.IconsSeparatorText>
                )}
                <Icon key={`${subIconProps.name}-${index}`} {...subIconProps} />
              </Fragment>
            )
          })}
        </If>
      </Styled.HeadingContainer>
    </Styled.CardHeadingAndIconSlot>
  )
}

export default CardHeadingAndIconSlot
