import { registry, render } from 'pages/renderer';
import { shallowEqual, useSelector } from 'react-redux';
import TemplatedPage from '../components/TemplatedPage';

registry.Page = TemplatedPage;

const AppRouter2 = () => {
  console.log(`Rendering`);
  const routesJson = useSelector(store => store.pages.routes, shallowEqual);
  return render(routesJson);
};
/*
Caveat: To compile a component as a component, outside of "children", give it a "__render: false (!!)
                "component": {
                  "__component": "Page",
                  "__render": false,
                  "__props": {
                    "page": "3"
                  }
                }


const comp = AppRouter2();
console.log(comp);
*/

export default AppRouter2;
