import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledColumnModalTemplateProps } from './ColumnModalTemplate.style.types'

export const ColumnModalTemplate = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
  `,
)

export const HeaderContentContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    max-width: 724px;
    margin: 0 auto;
    ${spacing('margin-bottom', 5)}
  `,
)

export const ColumnsContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;

    ${respondTo.md(css`
      flex-direction: row;
    `)}
  `,
)

export const Column = styled.div<StyledColumnModalTemplateProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    ${spacing('margin-bottom', 3)}
    justify-content: center;
    width: 100%;

    ${respondTo.md(css`
      &:first-child {
        justify-content: flex-end;
        ${spacing('margin-right', 3)}
        margin-bottom: 0;
      }

      &:last-child {
        justify-content: flex-start;
        margin-right: 0;
      }

      ${props.oneColumn &&
      css`
        &:first-child {
          justify-content: center;
        }
      `}

      ${props.withoutMarginBottom &&
      css`
        ${spacing('margin-bottom', 0)}
      `}
    `)}
  `,
)

export const SeparatorTextContainer = styled.div<StyledColumnModalTemplateProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    ${spacing('margin-bottom', 3)}

    ${respondTo.md(css`
      width: auto;
      ${spacing('margin-right', 3)}
      margin-bottom: 0;

      ${props.withoutMarginBottom &&
      css`
        ${spacing('margin-bottom', 0)}
      `}
    `)}
  `,
)

export const BodyTextContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-top', 5)}
  `,
)

export const LinksContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;

    ${spacing('margin-top', 5)}
  `,
)

export const LinkWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 3)}

    &:last-child {
      margin-right: 0;
    }
  `,
)
