import React, { FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'
import localThemes from '@vfuk/core-button/dist/themes/Button.theme'

import * as Styled from './styles/ButtonWithIcon.style'

import { ButtonWithIconProps } from './ButtonWithIcon.types'

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  appearance = 'primary',
  theme,
  width = 'auto',
  loading = false,
  htmlAttributes,
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  icon,
  customRouterProps,
}) => {
  const localThemeAppearance = localThemes(theme!).appearance[appearance!]

  return (
    <Styled.ButtonWithIcon
      appearance={appearance!}
      dataAttributes={htmlAttributes?.dataAttributes}
      disabled={state === 'disabled' || loading!}
      href={href}
      icon={icon!}
      id={id}
      inverse={inverse!}
      label={text}
      loading={loading!}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      state={state!}
      target={htmlAttributes?.target}
      type={htmlAttributes?.type}
      width={width!}
      customRouterProps={customRouterProps}
    >
      <Choose>
        <When condition={inverse}>
          <Choose>
            <When condition={state === 'selected'}>
              <Icon
                appearance={localThemeAppearance.inverse.state[state!].iconAppearance}
                inverse={localThemeAppearance.inverse.state[state!].inverseIcon}
                name='tick'
                size={3}
                isResponsive={false}
              />
              {text}
            </When>
            <When condition={state === 'success'}>
              <Icon
                appearance={localThemeAppearance.inverse.state[state!].iconAppearance}
                inverse={localThemeAppearance.inverse.state[state!].inverseIcon}
                name={icon.name}
                size={3}
                isResponsive={false}
              />
              {text}
            </When>
            <When condition={loading}>
              <Styled.LoadingIcon>
                <LoadingSpinner
                  appearance={localThemeAppearance.inverse.loadingSpinnerAppearance}
                  inverse={localThemeAppearance.inverse.inverseIcon}
                  size={2}
                />
              </Styled.LoadingIcon>
              <Styled.HiddenText>{text}</Styled.HiddenText>
            </When>
            <Otherwise>
              <Icon
                appearance={localThemeAppearance.inverse.iconAppearance}
                inverse={localThemeAppearance.inverse.inverseIcon}
                name={icon.name}
                group='system'
                size={3}
                isResponsive={false}
              />
              {text}
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          <Choose>
            <When condition={state === 'selected'}>
              <Icon
                appearance={localThemeAppearance.state[state!].iconAppearance}
                inverse={localThemeAppearance.state[state!].inverseIcon}
                name='tick'
                group='system'
                size={3}
                isResponsive={false}
              />
              {text}
            </When>
            <When condition={state === 'success'}>
              <Icon
                appearance={localThemeAppearance.state[state!].iconAppearance}
                inverse={localThemeAppearance.state[state!].inverseIcon}
                name={icon.name}
                group='system'
                size={3}
                isResponsive={false}
              />
              {text}
            </When>
            <When condition={loading}>
              <Styled.LoadingIcon>
                <LoadingSpinner
                  appearance={localThemeAppearance.loadingSpinnerAppearance}
                  inverse={localThemeAppearance.inverseIcon}
                  size={2}
                />
              </Styled.LoadingIcon>
              <Styled.HiddenText>{text}</Styled.HiddenText>
            </When>
            <Otherwise>
              <Icon
                appearance={localThemeAppearance.iconAppearance}
                inverse={localThemeAppearance.inverseIcon}
                name={icon.name}
                group='system'
                size={3}
                isResponsive={false}
              />
              {text}
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </Styled.ButtonWithIcon>
  )
}

export default withTheme(ButtonWithIcon)
