import React, { ReactElement, FC } from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'

import SimpleGrid from '@vfuk/core-simple-grid'

import * as Styled from './styles/SmallPicker.style'

import { SmallPickerProps } from './SmallPicker.types'

const SmallPicker: FC<SmallPickerProps> = ({
  label,
  selected,
  groupName,
  inverse,
  onChange,
  onBlur,
  items,
  columns,
}: SmallPickerProps): ReactElement => {
  const defaultColumns = {
    sm: items.length <= 4 ? items.length : 5,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset onBlur={handleOnBlur}>
      <Styled.Legend>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2}>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                dataAttributes={pickerItem.dataAttributes}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
                size={1}
              />
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default SmallPicker
