import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Span from '@vfuk/core-span'
import { typography } from '@vfuk/core-mixins'

import { MainContentProps, StyledSandwichProps, StyledTextWrapperProps } from './Sandwich.style.types'

import localTheme from '../themes/Sandwich.theme'

import { SizeParagraph, SizeHeading } from '../themes/Sandwich.theme.types'

const getFontSize = (props: StyledTextWrapperProps): FlattenSimpleInterpolation => {
  const element = localTheme.size[props.textType][props.size].element

  let size: SizeParagraph['size'] | SizeHeading['size']

  if (element === 'paragraph') {
    size = localTheme.size[props.textType][props.size].size as SizeParagraph['size']
    return css`
      ${typography.paragraph(size, false)}
    `
  }

  size = localTheme.size[props.textType][props.size].size as SizeHeading['size']
  return css`
    ${typography.heading(size, false)}
  `
}

export const Sandwich = styled.div(
  (props: StyledSandwichProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
  `,
)

export const TextWrapper = styled(Span)(
  (props: StyledTextWrapperProps): FlattenSimpleInterpolation => css`
    ${getFontSize(props)}

    ${props.weight && `font-weight: ${props.theme.fontWeight[props.weight]};`}
  `,
)

export const MainContent = styled(Span)(
  (props: MainContentProps): FlattenSimpleInterpolation => css`
    ${typography.heading(props.size, false)}
  `,
)
