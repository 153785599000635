import React, { FC, ReactElement, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

import Paragraph from '@vfuk/core-paragraph'
import Sandwich from '@vfuk/core-sandwich'

import checkVerticalJustification from './utils/checkVerticalJustification'

import * as Styled from './styles/CardSandwichSlot.style'

import { CardSandwichSlotProps, GetSandwichJustificationsReturn } from './CardSandwichSlot.types'

const CardSandwichSlot: FC<CardSandwichSlotProps> = ({
  primaryPrice,
  secondaryPrice,
  subText,
  appearance = 'primary',
  verticalAlign = 'bottom',
  level = 1,
}: CardSandwichSlotProps): ReactElement => {
  const getSandwichVerticalJustifications = useMemo((): GetSandwichJustificationsReturn | undefined => {
    if (!secondaryPrice?.text) return

    return {
      primary: checkVerticalJustification(primaryPrice.text, secondaryPrice.text),
      secondary: checkVerticalJustification(secondaryPrice.text, primaryPrice.text),
    }
  }, [primaryPrice, secondaryPrice])

  return (
    <Styled.CardSandwichSlot>
      <Styled.SandwichContainer verticalAlign={verticalAlign} alignSecondaryPrice={secondaryPrice?.align} hasSubText={!!subText}>
        <Styled.PriceWrapper
          hasSecondaryPrice={!isEmpty(secondaryPrice)}
          verticalJustification={getSandwichVerticalJustifications?.primary}
        >
          <Sandwich appearance={appearance === 'primary' ? 'alt1' : 'secondary'} level={2} size={level === 2 ? 1 : 2} {...primaryPrice} />
        </Styled.PriceWrapper>
        <If condition={!isEmpty(secondaryPrice)}>
          <Styled.PriceWrapper verticalJustification={getSandwichVerticalJustifications?.secondary}>
            <Sandwich
              appearance={appearance === 'primary' ? 'alt1' : 'secondary'}
              level={2}
              size={level === 2 ? 1 : 2}
              {...secondaryPrice}
            />
          </Styled.PriceWrapper>
        </If>
      </Styled.SandwichContainer>
      <If condition={subText}>
        <Paragraph size={1} appearance='secondary'>
          {subText}
        </Paragraph>
      </If>
    </Styled.CardSandwichSlot>
  )
}

export default CardSandwichSlot
