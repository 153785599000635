import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { RangeSliderFieldSetTheme } from './RangeSlider.theme.types'

const rangeSliderFieldSetLocal = (theme: Theme): RangeSliderFieldSetTheme => ({
  appearance: {
    primary: {
      color: theme.color.primary1,
    },
    secondary: {
      color: theme.color.secondary1,
    },
  },
})

export default rangeSliderFieldSetLocal
