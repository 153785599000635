import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { CarouselWrapper } from '@vfuk/core-functional-carousel/dist/styles/FunctionalCarousel.style'
import { CardBuilder } from '@vfuk/core-card-builder/dist/styles/CardBuilder.style'
import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import { StyledCardListProps } from './CardList.style.types'

type CardListProps = Pick<StyledCardListProps, 'theme'>
export const CardList = styled.div(
  (props: CardListProps): FlattenSimpleInterpolation => css`
    width: 100%;
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;

    ${CardBuilder} {
      height: auto;
    }
  `,
)

export const Section = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    ${spacing('margin-bottom', 4)};
  `,
)

export const CardsSectionContainer = styled.div(
  (props: StyledCardListProps): FlattenSimpleInterpolation => css`
    max-width: ${(props.theme.gridConfig.springWidth / 12) * props.cardsSectionContainerColumns}px;
    margin: 0 auto;
  `,
)

export const Cards = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('margin', [8, 0])};

    ${CarouselWrapper} .slick-slide > div {
      min-width: 0;
    }
  `,
)
