import React, { FC, ReactElement } from 'react'
import { withTheme } from 'styled-components'

import Container from '@vfuk/core-container'
import CardHeader from '@vfuk/core-card-header'
import CardBody from '@vfuk/core-card-body'

import * as Styled from './styles/LinkCard.style'

import { LinkCardProps } from './LinkCard.types'

import localTheme from './themes/LinkCard.theme'

export const LinkCard: FC<LinkCardProps> = ({
  appearance = 'primary',
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
  href,
  headingText,
  elevation,
  image,
  pill,
  marketingShout,
  text,
}: LinkCardProps): ReactElement => {
  const linkCardAppearance = localTheme().appearance[appearance!]
  return (
    <Styled.LinkCard
      onClick={onClick}
      customRouterProps={customRouterProps}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      href={href}
      label={headingText}
      fullSize
    >
      <Container appearance={linkCardAppearance.containerAppearance} elevation={elevation} paddingLevel={0}>
        <Styled.LinkCardContent>
          <CardHeader
            primaryImage={{
              alt: image!.alt || headingText,
              ...image,
            }}
            pill={pill}
            marketingShout={marketingShout}
          />
          <CardBody
            heading={{
              text: headingText,
              size: 2,
              level: 2,
              inverse: linkCardAppearance.inverseText,
            }}
            chevron={{
              appearance: linkCardAppearance.iconAppearance,
              inverse: linkCardAppearance.inverseIcon,
            }}
            paragraph={{
              text,
              inverse: linkCardAppearance.inverseText,
            }}
          />
        </Styled.LinkCardContent>
      </Container>
    </Styled.LinkCard>
  )
}

export default withTheme(LinkCard)
