import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import { AnimationTypes } from '@vfuk/core-styles/dist/animations/animation.types'

import { AnimationConfig } from '../Animate.types'

const getDuration = (animationCfg: AnimationConfig, animationName: AnimationTypes, theme: Theme): number => {
  return animationCfg.duration || theme.animations[animationName].config.duration
}

export default getDuration
