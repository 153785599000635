import React, { PureComponent, ReactNode } from 'react'

import BannerBody from '@vfuk/core-banner-body'
import Overlay from '@vfuk/core-overlay'

import * as Styled from './styles/SkinnyBanner.style'

import { SkinnyBannerProps } from './SkinnyBanner.types'

export default class SkinnyBanner extends PureComponent<SkinnyBannerProps> {
  public static defaultProps: Partial<SkinnyBannerProps> = {
    enableOverlay: false,
  }

  public render(): ReactNode {
    return (
      <Styled.SkinnyBanner backgroundImage={this.props.backgroundImage}>
        <If condition={this.props.enableOverlay}>
          <Overlay show position='absolute' zIndex={1} />
        </If>
        <BannerBody
          justify='center'
          inverse={true}
          heading={{
            level: this.props.heading.level || 2,
            text: this.props.heading.text,
            weight: 4,
          }}
          text={this.props.text}
        />
      </Styled.SkinnyBanner>
    )
  }
}
