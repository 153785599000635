import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { SpanTheme } from './Span.theme.types'

const spanLocal = (theme: Theme): SpanTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
        },
      },
      secondary: {
        color: theme.color.secondary7.default,
        inverse: {
          color: theme.inverseColor.secondary7.default,
        },
      },
      alt1: {
        color: theme.color.secondary1.default,
        inverse: {
          color: theme.inverseColor.secondary1.default,
        },
      },
      alt2: {
        color: theme.color.monochrome5.default,
        inverse: {
          color: theme.inverseColor.monochrome5.default,
        },
      },
    },
  }
}

export default spanLocal
