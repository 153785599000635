import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { respondTo, spacing } from '@vfuk/core-mixins'

import { StyledContainerWithLabelProps } from './ContainerWithLabel.style.types'

type ContainerWithLabelProps = Pick<StyledContainerWithLabelProps, 'theme' | 'hasLabel' | 'level' | 'showOuterTopPadding'>

export const ContainerWithLabel = styled.div(
  (props: ContainerWithLabelProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;

    ${respondTo.md(css`
      ${!props.hasLabel &&
      props.showOuterTopPadding &&
      css`
        padding-top: 34px;
      `}
    `)}

    & > *:last-child {
      ${props.hasLabel &&
      css`
        border-top-left-radius: 0;

        &::after {
          z-index: 2;
          border-top-left-radius: 0;
        }
      `}
      ${props.level === 2 &&
      css`
        border-top-right-radius: 0;

        &::after {
          border-top-right-radius: 0;
        }
      `}
    }
  `,
)

export const Header = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    height: 28px;

    ${respondTo.md(css`
      height: 34px;
    `)}
  `,
)

type LabelContainerProps = Pick<StyledContainerWithLabelProps, 'theme' | 'appearance' | 'level' | 'state'>
export const LabelContainer = styled.div(
  (props: LabelContainerProps): FlattenSimpleInterpolation => css`
    ${spacing('padding-left', 3)}
    ${spacing('padding-right', 3)}

  ${spacing('margin-right', 6)}

  ${props.level === 2 &&
    css`
      justify-content: center;
      width: 100%;

      ${spacing('margin-right', 0)}
    `}

  background-color: ${props.appearance === 'primary' ? props.theme.color.primary1.default : props.theme.color.secondary1.default};
    color: ${props.theme.color.monochrome1.default};
    display: inline-flex;
    align-items: center;
    border-radius: 6px 6px 0 0;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;

    ${props.state === 'success' &&
    css`
      background-color: ${props.theme.color.success.default};
    `}

    & > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
)
