const CONFIG_REQUEST = 'CONFIG_REQUEST';
const CONFIG_REQUEST_SUCCESS = 'CONFIG_REQUEST_SUCCESS';
const CONFIG_REQUEST_FAIL = 'CONFIG_REQUEST_FAIL';
const SET_PAGE_CONFIG = 'SET_PAGE_CONFIG';

const fetchConfig = () => ({
  type: CONFIG_REQUEST
});

const setPageConfig = payload => ({
  type: SET_PAGE_CONFIG,
  payload
});

export {
  CONFIG_REQUEST,
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_FAIL,
  SET_PAGE_CONFIG,
  fetchConfig,
  setPageConfig
};
