import React, { PureComponent } from 'react'

import * as Styled from './styles/Span.style'

import { SpanProps } from './Span.types'

export default class Span extends PureComponent<SpanProps> {
  public static defaultProps: Partial<SpanProps> = {
    appearance: 'primary',
    inverse: false,
    display: 'inline',
  }

  public render(): JSX.Element {
    return (
      <Styled.Span
        as={this.props.display === 'block' ? ('div' as React.ElementType) : ('span' as React.ElementType)}
        appearance={this.props.appearance!}
        inverse={this.props.inverse!}
        weight={this.props.weight}
        className={this.props.className}
      >
        {this.props.children}
      </Styled.Span>
    )
  }
}
