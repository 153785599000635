import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS2 from './SimpleNotification.ws2.theme'
import WS10 from './SimpleNotification.ws10.theme'
import VOXI from './SimpleNotification.voxi.theme'

import { SimpleNotificationTheme } from './SimpleNotification.theme.types'

const getLocalTheme = (theme?: Theme): SimpleNotificationTheme => {
  switch (theme?.name) {
    case 'VOXI':
      return VOXI()
    case 'WS2':
      return WS2()
    case 'WS10':
    default:
      return WS10()
  }
}

export default getLocalTheme
