import React, { ReactElement, FC } from 'react'
import isEmpty from 'lodash/isEmpty'

import Button from '@vfuk/core-button'
import LinkWithIcon from '@vfuk/core-link-with-icon'

import * as Styled from './styles/CardInteractionSlot.style'

import { CardInteractionSlotProps } from './CardInteractionSlot.types'

const CardInteractionSlot: FC<CardInteractionSlotProps> = ({
  primaryButton,
  secondaryButton,
  linkWithIcon,
  level = 1,
}: CardInteractionSlotProps): ReactElement | null => {
  if (isEmpty(primaryButton) && isEmpty(linkWithIcon)) {
    return null
  }
  const isFull = isEmpty(secondaryButton) && isEmpty(linkWithIcon)
  return (
    <Styled.CardInteractionSlot>
      <If condition={!isEmpty(primaryButton)}>
        <Styled.ButtonsContainer fullWidth={isFull}>
          <If condition={!isEmpty(secondaryButton)}>
            <Styled.ButtonWrapper>
              <Button {...secondaryButton!} appearance='alt1' />
            </Styled.ButtonWrapper>
          </If>
          <Styled.ButtonWrapper>
            <Button {...primaryButton!} appearance={level === 2 ? 'secondary' : 'primary'} width={isFull ? 'full' : 'auto'} />
          </Styled.ButtonWrapper>
        </Styled.ButtonsContainer>
      </If>
      <If condition={!isEmpty(linkWithIcon)}>
        <Styled.LinkWithIconContainer>
          <LinkWithIcon {...linkWithIcon!} />
        </Styled.LinkWithIconContainer>
      </If>
    </Styled.CardInteractionSlot>
  )
}

export default CardInteractionSlot
