import React, { PureComponent, ReactNode } from 'react'
import Icon from '@vfuk/core-icon'
import Pill from '@vfuk/core-pill'
import MarketingShout from '@vfuk/core-marketing-shout'
import Image from '@vfuk/core-image'

import * as Styled from './styles/CardHeader.style'

import { CardHeaderProps } from './CardHeader.types'

export default class CardHeader extends PureComponent<CardHeaderProps> {
  public render(): ReactNode {
    return (
      <Styled.CardHeader>
        <If condition={this.props.pill}>
          <Styled.PillWrapper>
            <Pill text={this.props.pill!.text} />
          </Styled.PillWrapper>
        </If>
        <If condition={this.props.marketingShout}>
          <Styled.MarketingShoutWrapper>
            <MarketingShout text={this.props.marketingShout?.text} weight={this.props.marketingShout?.weight}>
              {this.props.marketingShout?.children}
            </MarketingShout>
          </Styled.MarketingShoutWrapper>
        </If>
        <If condition={this.props.secondaryImage}>
          <Styled.SecondaryImageWrapper>
            <Image {...this.props.secondaryImage} alt={this.props.secondaryImage!.alt} />
          </Styled.SecondaryImageWrapper>
        </If>
        <If condition={this.props.icon}>
          <Styled.IconWrapper>
            <Icon group={this.props.icon!.group} {...this.props.icon!} isResponsive={false} />
          </Styled.IconWrapper>
        </If>
        <If condition={this.props.primaryImage}>
          <Styled.ImageWrapper>
            <Image {...this.props.primaryImage} alt={this.props.primaryImage!.alt} />
          </Styled.ImageWrapper>
        </If>
      </Styled.CardHeader>
    )
  }
}
