import React, { FC, ReactElement } from 'react'
import Paragraph from '@vfuk/core-paragraph'
import Icon from '@vfuk/core-icon'

import * as Styled from './styles/Footnote.style'

import { FootnoteProps } from './Footnote.types'

const Footnote: FC<FootnoteProps> = ({ icon, text, inverse }: FootnoteProps): ReactElement => (
  <Styled.Footnote>
    <If condition={icon && icon.name}>
      <Styled.IconWrapper>
        <Icon name={icon!.name} size={5} inverse={inverse} />
      </Styled.IconWrapper>
    </If>
    <If condition={text}>
      <Paragraph size={1} inverse={inverse}>
        {text}
      </Paragraph>
    </If>
  </Styled.Footnote>
)

export default Footnote
