import { IconCardTheme } from './IconCard.theme.types'

const iconCardLocal = (): IconCardTheme => ({
  appearance: {
    primary: {
      containerAppearance: 'primary',
      level: {
        1: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'primary',
          secondaryButtonAppearance: 'secondary',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
        2: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'secondary',
          secondaryButtonAppearance: 'alt1',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
        3: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'alt1',
          secondaryButtonAppearance: 'alt1',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
      },
    },
    secondary: {
      containerAppearance: 'secondary',
      level: {
        1: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'primary',
          secondaryButtonAppearance: 'secondary',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
        2: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'secondary',
          secondaryButtonAppearance: 'alt1',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
        3: {
          iconAppearance: 'primary',
          primaryButtonAppearance: 'alt1',
          secondaryButtonAppearance: 'alt1',
          inverseButton: false,
          inverseText: false,
          inverseIcon: false,
        },
      },
    },
    alt1: {
      containerAppearance: 'alt1',
      level: {
        1: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'primary',
          secondaryButtonAppearance: 'secondary',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
        2: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'secondary',
          secondaryButtonAppearance: 'alt1',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
        3: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'alt1',
          secondaryButtonAppearance: 'alt1',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
      },
    },
    alt2: {
      containerAppearance: 'alt3',
      level: {
        1: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'primary',
          secondaryButtonAppearance: 'secondary',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
        2: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'secondary',
          secondaryButtonAppearance: 'alt1',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
        3: {
          iconAppearance: 'secondary',
          primaryButtonAppearance: 'alt1',
          secondaryButtonAppearance: 'alt1',
          inverseButton: true,
          inverseText: true,
          inverseIcon: false,
        },
      },
    },
  },
})

export default iconCardLocal
