import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing, srOnly } from '@vfuk/core-mixins'

import { StyledImagePickerProps } from './ImagePicker.style.types'

export const ImageWrapper = styled.span<StyledImagePickerProps>(
  (props: StyledImagePickerProps): FlattenSimpleInterpolation => css`
    display: flex;
    ${spacing('padding', 1)};
    ${spacing('margin-right', 2)};

    & img {
      border-radius: ${props.theme.border.radius[2]};
      min-width: 52px;
      min-height: 52px;
    }
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const PickerItemWrapper = styled.span((): FlattenSimpleInterpolation => [])

export const Fieldset = styled.fieldset((): FlattenSimpleInterpolation => [])
