import React, { ReactElement, FC } from 'react'

import IconAndImageCard from '@vfuk/core-icon-and-image-card'
import ColumnModalTemplate from '@vfuk/core-column-modal-template'
import StateNotification from '@vfuk/core-state-notification'

import { IconAndImageCardModalTemplateProps } from './IconAndImageCardModalTemplate.types'

const IconAndImageCardModalTemplate: FC<IconAndImageCardModalTemplateProps> = ({
  primaryIconAndImageCard,
  secondaryIconAndImageCard,
  headerContent,
  ...columnModalTemplateProps
}: IconAndImageCardModalTemplateProps): ReactElement => {
  let headerContentProp

  if (headerContent) {
    headerContentProp = <StateNotification {...headerContent} />
  }

  return (
    <ColumnModalTemplate
      {...columnModalTemplateProps}
      leftContent={<IconAndImageCard {...primaryIconAndImageCard} />}
      rightContent={!!secondaryIconAndImageCard && <IconAndImageCard {...secondaryIconAndImageCard} />}
      headerContent={headerContentProp}
    />
  )
}

export default IconAndImageCardModalTemplate
