import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './CardList.ws10.theme'

import { CardListTheme } from './CardList.theme.types'

const getLocalTheme = (theme: Theme): CardListTheme => {
  switch (theme.name) {
    case 'VOXI':
    case 'WS2':
    case 'WS10':
    default:
      return WS10
  }
}

export default getLocalTheme
