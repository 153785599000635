import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { ToggleTheme } from './Toggle.theme.types'

const toggleLocal = (theme: Theme): ToggleTheme => {
  return {
    appearance: {
      primary: {
        active: {
          icon: 'tick',
          color: theme.color.secondary1.default,
        },
        inactive: {
          icon: 'close',
          color: theme.color.monochrome4.default,
        },
      },
    },
    state: {
      disabled: {
        active: {
          color: theme.color.secondary1.disabled,
        },
        inactive: {
          color: theme.color.monochrome4.disabled,
        },
      },
    },
  }
}

export default toggleLocal
