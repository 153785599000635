import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { Paragraph } from '@vfuk/core-paragraph/dist/styles/Paragraph.style'
import { advancedSpacing, applyFocus, spacing, srOnly } from '@vfuk/core-mixins'

import { StyledFilterListProps } from './FilterList.style.types'

export const FilterList = styled.fieldset(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-wrap: wrap;
    ${spacing('gap', 4)};
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

type StyledFilterItemPropsLabel = Pick<StyledFilterListProps, 'imgSet' | 'theme' | 'checked' | 'size'>
export const Span = styled.span<StyledFilterItemPropsLabel>(
  (props: StyledFilterItemPropsLabel): FlattenSimpleInterpolation => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${props.imgSet &&
    css`
      min-width: 24px;
      width: ${props.imgSet.width};
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${props.imgSet.src});
      background-size: 100% 100%;
      ${spacing('margin-right', 1)}

      ${props.size === 1 &&
      css`
        min-width: 16px;
        height: 16px;
      `}
    ${props.checked &&
      props.imgSet.selected &&
      css`
        background-image: url(${props.imgSet.selected});
      `}
    `}
  `,
)

type StyledFilterItemProps = Pick<StyledFilterListProps, 'state' | 'appearance' | 'size' | 'theme' | 'checked' | 'inverse' | 'imgSet'>
export const FilterItem = styled.label<StyledFilterItemProps>(
  (props: StyledFilterItemProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 3])}
    min-height: 42px;
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome6.default};
    border-radius: ${props.theme.border.radius[3]};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: ${props.theme.color.monochrome1.default};

    ${props.size === 1 &&
    css`
      min-height: 34px;
    `}

    ${props.appearance === 'secondary' &&
    css`
      background: ${props.theme.color.monochrome2.default};
    `}

  ${applyFocus()}

  &:hover {
      box-shadow: inset 0px 0px 0px 1px ${props.theme.color.monochrome5.hover};
      ${props.imgSet?.hover &&
      css`
        ${Span} {
          background-image: url(${props.imgSet.hover});
        }
      `}
    }

    ${props.checked &&
    css`
      background: ${props.theme.color.secondary1.default};
      border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} transparent;

      &:hover {
        background: ${props.theme.color.secondary1.hover};
        box-shadow: none;
      }

      ${Paragraph} {
        color: ${props.theme.color.monochrome1.default};
      }
    `}

    ${props.state === 'disabled' &&
    css`
      opacity: 38%;
      cursor: not-allowed;
      &:hover {
        border-width: ${props.theme.border.width[1]};
      }
    `}

  ${Icon} {
      ${spacing('margin-right', 1)}
    }

    ${props.inverse &&
    css`
      background: ${props.theme.inverseColor.monochrome1.default};
      border-color: ${props.theme.inverseColor.monochrome6.default};
      ${Paragraph} {
        color: ${props.theme.inverseColor.monochrome6.default};
      }
      &:hover {
        box-shadow: inset 0px 0px 0px 1px ${props.theme.inverseColor.monochrome6.default};
      }
      ${props.checked &&
      css`
        background: ${props.theme.inverseColor.secondary1.default};
        border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} transparent;

        ${Paragraph} {
          color: ${props.theme.inverseColor.monochrome1.default};
        }
        &:hover {
          box-shadow: none;
          background: ${props.theme.inverseColor.secondary1.hover};
        }
      `}
    `}
  `,
)

type StyledFilterItemInputProps = Pick<StyledFilterListProps, 'state'>
export const FilterInput = styled.input(
  (props: StyledFilterItemInputProps): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
    `}
  `,
)
