/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { advancedSpacing, respondTo } from '@vfuk/core-mixins'

import { StyledMarketingShoutProps } from './MarketingShout.style.types'

import localThemes from '../themes/MarketingShout.theme'

export const MarketingShoutContainer = styled.div(
  (props: Pick<StyledMarketingShoutProps, 'theme' | 'absolutePosition' | 'align'>): FlattenSimpleInterpolation => css`
    ${respondTo.md(css`
      ${props.absolutePosition === false &&
      props.align === 'right' &&
      css`
        text-align: right;
        margin-left: auto;
      `}
    `)}
  `,
)

export const MarketingShout = styled.div(
  (props: Pick<StyledMarketingShoutProps, 'theme' | 'absolutePosition' | 'align' | 'appearance'>): FlattenSimpleInterpolation => {
    const localTheme = localThemes(props.theme)
    return css`
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
      position: ${props.absolutePosition ? 'absolute' : 'relative'};
      box-sizing: border-box;
      z-index: 3;
      vertical-align: top;
      width: 100%;

      ${props.absolutePosition &&
      props.align === 'right' &&
      css`
        right: 0;
        top: 0;
      `}

      ${props.absolutePosition &&
      props.align === 'left' &&
      css`
        left: 0;
        top: 0;
      `}

    ${props.absolutePosition === false &&
      css`
        display: inline-block;
      `}

    ${respondTo.md(css`
        min-width: 144px;
        width: auto;
        max-width: 400px;

        ${localTheme.cornerStyle(props.align!, localTheme.appearance[props.appearance!].backgroundColor)}
      `)}
    `
  },
)

export const MarketingShoutText = styled.div(
  (props: Pick<StyledMarketingShoutProps, 'theme' | 'align' | 'appearance' | 'weight'>): FlattenSimpleInterpolation => {
    const localTheme = localThemes(props.theme)
    return css`
      color: ${localTheme.appearance[props.appearance!].textColor};
      background-color: ${localTheme.appearance[props.appearance!].backgroundColor};
      font-weight: ${props.weight === 2 ? props.theme.fontWeight[3] : props.theme.fontWeight[2]};
      position: relative;
      z-index: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${advancedSpacing('padding', [1, 2])};

      ${respondTo.md(css`
        ${props.align === 'left' &&
        css`
          padding-right: 0;
        `}

        ${props.align === 'right' &&
        css`
          padding-left: 0;
        `}
      `)}
    `
  },
)
