import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Link.style'

import { LinkProps } from './Link.types'

const Link: FC<LinkProps> = ({
  showUnderline = false,
  href,
  to,
  htmlAttributes = {},
  onClick,
  inverse,
  srText,
  text,
  children,
  size = 2,
  id,
}: LinkProps): ReactElement => {
  return (
    <Styled.Link
      href={href}
      to={to}
      target={htmlAttributes!.target}
      onInteraction={onClick}
      showUnderline={showUnderline!}
      inverse={inverse}
      label={srText}
      rel={htmlAttributes!.rel}
      dataAttributes={htmlAttributes!.dataAttributes}
      size={size}
      id={id}
    >
      {text || children}
    </Styled.Link>
  )
}

export default Link
