import React, { FC, ReactElement } from 'react'

import FunctionalTab from '@vfuk/core-functional-tabs'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/TabTitle.style'

import { TabTitleProps } from './TabTitle.types'

export const TabTitle: FC<TabTitleProps> = ({
  isActiveTab,
  tabId,
  tabWidth,
  text,
  appearance,
  inverse,
  isOverflowMenuOpen,
  onClick,
  onFocus,
  onKeyPress,
  overflowActive,
}): ReactElement => {
  return (
    <Styled.TabContainer
      role='presentation'
      overflowActive={overflowActive}
      tabWidth={tabWidth}
      onClick={onClick}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
    >
      <FunctionalTab tabId={tabId}>
        <Styled.TabTitle
          isActiveTab={isActiveTab}
          appearance={appearance!}
          inverse={inverse!}
          tabWidth={tabWidth}
          data-text={text}
          overflowActive={overflowActive}
        >
          {text}
          {overflowActive && (
            <Styled.IconContainer isOverflowMenuOpen={isOverflowMenuOpen}>
              <Icon appearance='primary' inverse={inverse!} name='chevron-down' size={1} />
            </Styled.IconContainer>
          )}
        </Styled.TabTitle>
      </FunctionalTab>
    </Styled.TabContainer>
  )
}
