import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { SelectInputTheme } from './SelectInput.theme.types'

const selectInputTheme = (theme: Theme): SelectInputTheme => {
  return {
    state: {
      success: {
        color: theme.color.monochrome5.default,
        backgroundColor: theme.color.monochrome1.default,
        border: {
          color: theme.color.success.default,
          width: theme.border.width[2],
        },
      },
      info: {
        color: theme.color.monochrome5.default,
        backgroundColor: theme.color.monochrome1.default,
        border: {
          color: theme.color.info.default,
          width: theme.border.width[2],
        },
      },
      warn: {
        color: theme.color.monochrome5.default,
        backgroundColor: theme.color.monochrome1.default,
        border: {
          color: theme.color.warn.default,
          width: theme.border.width[2],
        },
      },
      error: {
        color: theme.color.monochrome5.default,
        backgroundColor: theme.color.monochrome1.default,
        border: {
          color: theme.color.error.default,
          width: theme.border.width[2],
        },
      },
      disabled: {
        color: theme.color.monochrome5.disabled,
        backgroundColor: theme.color.monochrome2.default,
        border: {
          color: theme.color.monochrome5.disabled,
          width: theme.border.width[1],
        },
      },
    },
  }
}

export default selectInputTheme
