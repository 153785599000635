import { FunctionalContentBlockTheme } from './FunctionalContentBlock.theme.types'

const functionalContentBlockLocal: FunctionalContentBlockTheme = {
  appearance: {
    primary: {
      blockAppearance: 'primary',
      inverseContent: false,
    },
    secondary: {
      blockAppearance: 'secondary',
      inverseContent: true,
    },
    alt1: {
      blockAppearance: 'alt1',
      inverseContent: true,
    },
    alt2: {
      blockAppearance: 'alt2',
      inverseContent: false,
    },
  },
}

export default functionalContentBlockLocal
