import { Theme } from '@vfuk/core-themes/dist/themes.types'

import WS10 from './CheckBox.ws10.theme'
import VOXI from './CheckBox.voxi.theme'

import { CheckBoxTheme } from './CheckBox.theme.types'

const getLocalTheme = (theme: Theme): CheckBoxTheme => {
  switch (theme.name) {
    case 'VOXI':
      return VOXI(theme)
    case 'WS2':
    case 'WS10':
    default:
      return WS10(theme)
  }
}

export default getLocalTheme
