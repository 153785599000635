import React, { FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'

import * as Styled from './styles/Button.style'

import { ButtonProps } from './Button.types'

import localThemes from './themes/Button.theme'

export const Button: FC<ButtonProps> = ({
  appearance = 'primary',
  theme,
  width = 'auto',
  loading = false,
  htmlAttributes,
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  to,
  srText,
}) => {
  const localTheme = localThemes(theme!)
  const isDisabledOrLoading = state === 'disabled' || loading!

  return (
    <Styled.Button
      appearance={appearance!}
      dataAttributes={htmlAttributes?.dataAttributes}
      disabled={isDisabledOrLoading}
      href={!isDisabledOrLoading ? href : undefined}
      id={id}
      inverse={inverse!}
      label={srText || text}
      loading={loading!}
      onInteraction={onClick}
      state={state!}
      target={htmlAttributes?.target}
      to={!isDisabledOrLoading ? to : undefined}
      type={htmlAttributes?.type}
      width={width!}
    >
      <Choose>
        <When condition={loading}>
          <Styled.LoadingIcon>
            <Choose>
              <When condition={inverse}>
                <LoadingSpinner
                  appearance={localTheme.appearance[appearance!].inverse.iconAppearance}
                  inverse={localTheme.appearance[appearance!].inverse.inverseIcon}
                  size={2}
                />
              </When>
              <Otherwise>
                <LoadingSpinner
                  appearance={localTheme.appearance[appearance!].iconAppearance}
                  inverse={localTheme.appearance[appearance!].inverseIcon}
                  size={2}
                />
              </Otherwise>
            </Choose>
          </Styled.LoadingIcon>
          <Styled.HiddenText>{text}</Styled.HiddenText>
        </When>
        <When condition={inverse && state === 'selected'}>
          <Icon
            appearance={localTheme.appearance[appearance!].inverse.state[state!].iconAppearance}
            inverse={localTheme.appearance[appearance!].inverse.state[state!].inverseIcon}
            name='tick'
            group='system'
            size={3}
            isResponsive={false}
          />
          {text}
        </When>
        <When condition={state === 'selected'}>
          <Icon
            appearance={localTheme.appearance[appearance!].state[state!].iconAppearance}
            inverse={localTheme.appearance[appearance!].state[state!].inverseIcon}
            name='tick'
            group='system'
            size={3}
            isResponsive={false}
          />
          {text}
        </When>
        <Otherwise>{text}</Otherwise>
      </Choose>
    </Styled.Button>
  )
}

export default withTheme(Button)
