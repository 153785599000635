import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { srOnly, typography } from '@vfuk/core-mixins'

import { StyledSliderTextInputProps } from './SliderTextInput.style.types'

export const TextInputContainer = styled.span<StyledSliderTextInputProps>(
  (props: StyledSliderTextInputProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;

    > [class*='TextInputWrapper'] {
      width: 72px;
      ${props.textInputPosition === 'right' &&
      css`
        margin-left: 21px;

        ${props.prefix &&
        css`
          margin-left: 0;
        `}
      `}

      ${props.textInputPosition === 'left' &&
      css`
        margin-right: 21px;

        ${props.suffix &&
        css`
          margin-right: 0;
        `}
      `}
    }
  `,
)

export const TextInputPrefix = styled.span<StyledSliderTextInputProps>(
  (props: StyledSliderTextInputProps): FlattenSimpleInterpolation => css`
    ${typography.paragraph(2, false)};

    ${props.textInputPosition === 'right' &&
    css`
      ${props.prefix &&
      css`
        margin-right: 8px;
        margin-left: 21px;
      `}

      ${props.suffix &&
      css`
        margin-left: 8px;
      `}
    `}

    ${props.textInputPosition === 'left' &&
    css`
      ${props.suffix &&
      css`
        margin-left: 8px;
        margin-right: 21px;
      `}

      ${props.prefix &&
      css`
        margin-right: 8px;
      `}
    `}
  `,
)

export const HiddenLabel = styled.label(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()};
  `,
)
