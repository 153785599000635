import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing, typography } from '@vfuk/core-mixins'

export const TextContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    overflow: hidden;
    display: flex;
    align-items: center;
    ${spacing('margin-right', 2)};
  `,
)

export const Text = styled.span(
  (): FlattenSimpleInterpolation => css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${typography.heading(1, false)};
  `,
)

export const ChildrenContainer = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 2)};
    display: inline-flex;
    align-items: center;
  `,
)
