import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledInlineLinkProps } from './InlineLink.style.types'

export const InlineLink = styled(Interaction)(
  (props: StyledInlineLinkProps): FlattenSimpleInterpolation => css`
    color: ${props.theme.color.monochrome6.default};
    text-decoration: underline;
    font-weight: inherit;

    ${props.inverse &&
    css`
      color: ${props.theme.inverseColor.monochrome6.default};
    `}

    &:hover {
      color: ${props.theme.color.secondary7.default};

      ${props.inverse &&
      css`
        color: ${props.theme.inverseColor.secondary7.default};
      `}
    }

    &:visited {
      color: ${props.theme.color.secondary6.default};

      ${props.inverse &&
      css`
        color: ${props.theme.inverseColor.secondary6.default};
      `}
    }
  `,
)
