/* eslint-disable complexity, max-len */
import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import DateTextInput from '@vfuk/core-date-text-input'
import dateFormatter from '@vfuk/core-date-text-input/dist/utils/dateFormatter'

import { DateTextInputWrapperProps } from './DateTextInputWrapper.types'

import * as Styled from './styles/DateTextInputWrapper.style'

dayjs.extend(customParseFormat)

export default class DateTextInputWrapper extends PureComponent<DateTextInputWrapperProps> {
  handleOnChange = (date: Date | null | 'error', dateString: string): void => {
    if (date && date !== 'error') {
      // Clears the errors array when the date is valid
      this.props.onInvalidInputEntry([])
    }
    this.props.onDateChange(date, dateString)
  }

  private convertStringToDate(): Date | null {
    if (this.props.value) {
      return dateFormatter(this.props.value, this.props.showMonthYearPicker).toDate()
    }
    return null
  }

  public render(): ReactNode {
    return (
      <Styled.DateTextInputWrapper>
        <DateTextInput
          name={this.props.name}
          id={this.props.id}
          value={this.convertStringToDate()}
          rawValue={this.props.rawValue}
          required={this.props.required}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          includeDates={this.props.includeDates}
          excludeDates={this.props.excludeDates}
          filterDate={this.props.filterDate}
          showMonthYearPicker={this.props.showMonthYearPicker}
          onInvalidInputEntry={this.props.onInvalidInputEntry}
          onBlur={this.props.onBlur}
          onChange={this.handleOnChange}
          onClick={this.props.onClick}
          state={this.props.state}
          errorMessages={this.props.errorMessages}
          autoComplete={this.props.inputAutoComplete}
          describedBy={this.props.describedBy}
          labels={this.props.labels}
        />
        <If condition={this.props.state === 'disabled' || !this.props.state}>
          <Styled.IconWrapper>
            <Styled.IconContainer>
              <Icon name='calendar' size={1} />
            </Styled.IconContainer>
          </Styled.IconWrapper>
        </If>
      </Styled.DateTextInputWrapper>
    )
  }
}
