import React, { PureComponent, ReactNode } from 'react'

import LinkWithIcon from '@vfuk/core-link-with-icon'

import { CollapsibleContainer, CollapsibleContainerBody } from '@vfuk/core-collapsible-container'

import Heading from '@vfuk/core-heading'

import { BenefitItemProps } from './components/BenefitItem/BenefitItem.types'

import BenefitItem from './components/BenefitItem'

import { CardBenefitsSlotProps, CardBenefitsSlotState } from './CardBenefitsSlot.types'

import * as Styled from './styles/CardBenefitsSlot.style'

export default class CardBenefitsSlot extends PureComponent<CardBenefitsSlotProps> {
  public static defaultProps: Partial<CardBenefitsSlotProps> = {
    appearance: 'primary',
    level: 1,
    showToggle: true,
  }

  public state: CardBenefitsSlotState = {
    isOpen: this.props.collapsibleContainer.isInitiallyOpen || !this.props.showToggle,
  }

  private onToggle = (): void => {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => this.props.collapsibleContainer.onClick && this.props.collapsibleContainer.onClick(this.state.isOpen),
    )
  }

  public render(): ReactNode {
    return (
      <Styled.CardBenefitsSlot>
        <If condition={this.props.heading}>
          <Heading level={this.props.heading?.level || 5} size={1} weight={3} text={this.props.heading?.text} noMargin />
        </If>
        {this.props.benefitItems.slice(0, 3).map((benefitItem: BenefitItemProps, index) => (
          <BenefitItem key={index} {...benefitItem} cardLevel={this.props.level!} />
        ))}
        <CollapsibleContainer onToggle={this.onToggle} isActive={this.state.isOpen}>
          <CollapsibleContainerBody>
            {this.props.benefitItems.slice(3).map((benefitItem: BenefitItemProps, index) => (
              <BenefitItem key={index} {...benefitItem} cardLevel={this.props.level!} />
            ))}
          </CollapsibleContainerBody>
        </CollapsibleContainer>
        <If condition={this.props.collapsibleContainer.heading?.text && this.props.benefitItems.length > 3 && this.props.showToggle}>
          <LinkWithIcon
            appearance={this.props.appearance}
            text={this.props.collapsibleContainer.heading.text!}
            icon={{
              justify: 'right',
              name: this.state.isOpen ? 'chevron-up' : 'chevron-down',
            }}
            onClick={this.onToggle}
            size={2}
          />
        </If>
      </Styled.CardBenefitsSlot>
    )
  }
}
