import React, { Fragment, ReactElement, FC } from 'react'

import Icon from '@vfuk/core-icon'
import ListGroup from '@vfuk/core-list-group'

import * as Styled from './styles/StateText.style'
import { StateTextProps } from './StateText.types'

import { STATE_TEXT } from '../../constants'

const StateText: FC<StateTextProps> = ({ state, stateText, formElementId, validateOn }: StateTextProps): ReactElement => {
  const stateTextId = `${formElementId}-${STATE_TEXT}`

  const getAriaLive = (): 'polite' | 'assertive' | 'off' => {
    if (!validateOn) return 'off'
    if (state === 'error') return 'assertive'
    return 'polite'
  }

  return (
    <Fragment>
      <Styled.StateTextContainer
        id={stateTextId}
        multipleStateText={Array.isArray(stateText)}
        state={state}
        aria-live={getAriaLive()}
        aria-atomic={true}
      >
        <Icon name={state} group='state' size={1} />
        <Choose>
          <When condition={Array.isArray(stateText)}>
            <ListGroup>
              {(stateText as string[]).map((text, key) => {
                return (
                  <Styled.StateText state={state} key={key}>
                    {text}
                  </Styled.StateText>
                )
              })}
            </ListGroup>
          </When>
          <Otherwise>{stateText}</Otherwise>
        </Choose>
      </Styled.StateTextContainer>
    </Fragment>
  )
}

export default StateText
