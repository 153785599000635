import React, { ReactElement, FC } from 'react'

import * as Styled from './styles/InlineLink.style'

import { InlineLinkProps } from './InlineLink.types'

const InlineLink: FC<InlineLinkProps> = ({
  htmlAttributes = {},
  srText,
  inverse = false,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  children,
  href,
  customRouterProps,
}: InlineLinkProps): ReactElement => {
  return (
    <Styled.InlineLink
      href={href}
      target={htmlAttributes!.target}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      inverse={inverse}
      label={srText}
      rel={htmlAttributes!.rel}
      dataAttributes={htmlAttributes!.dataAttributes}
      customRouterProps={customRouterProps}
    >
      {children}
    </Styled.InlineLink>
  )
}

export default InlineLink
