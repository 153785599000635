import React, { ReactElement, FC } from 'react'

import Container from '@vfuk/core-container'
import Divider from '@vfuk/core-divider'
import FunctionalCardPanel from '@vfuk/core-functional-card-panel'

import { FunctionalCardPanelProps } from '@vfuk/core-functional-card-panel/dist/FunctionalCardPanel.types'

import * as Styled from './styles/ListCard.style'

import { ListCardProps } from './ListCard.types'

const ListCard: FC<ListCardProps> = ({ listContentItems }: ListCardProps): ReactElement => {
  return (
    <Container elevation paddingLevel={0}>
      {listContentItems.map((item: FunctionalCardPanelProps, index: number) => (
        <Styled.PanelWrapper key={index}>
          <FunctionalCardPanel
            headingText={item.headingText}
            text={item.text}
            iconLeft={item.iconLeft}
            iconRight={item.iconRight}
            onClick={item.onClick}
            button={item.button}
            pill={item.pill}
            href={item.href}
            onMouseDown={item.onMouseDown}
            onMouseUp={item.onMouseUp}
            onTouchStart={item.onTouchStart}
            onTouchEnd={item.onTouchEnd}
            customRouterProps={item.customRouterProps}
            htmlAttributes={item.htmlAttributes}
          >
            <If condition={listContentItems[index + 1]}>
              <Divider noMargin />
            </If>
          </FunctionalCardPanel>
        </Styled.PanelWrapper>
      ))}
    </Container>
  )
}

export default ListCard
