import React, { ReactElement, FC } from 'react'

import * as Styled from './styles/FunctionalAvatar.style'

import { FunctionalAvatarProps } from './FunctionalAvatar.types'

const FunctionalAvatar: FC<FunctionalAvatarProps> = ({
  size = 2,
  backgroundImage,
  backgroundColor,
  children,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  href,
  dataAttributes,
  srText,
  customRouterProps,
}: FunctionalAvatarProps): ReactElement => {
  return (
    <Styled.FunctionalAvatar
      size={size}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      href={href}
      dataAttributes={dataAttributes}
      label={srText}
      customRouterProps={customRouterProps}
    >
      <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
    </Styled.FunctionalAvatar>
  )
}

export default FunctionalAvatar
