import { DateTextInputTheme } from './DateTextInput.theme.types'

const dateTextInputLocal: DateTextInputTheme = {
  yearInputWidth: {
    sm: '72px',
    mdAndAbove: '84px',
  },
}

export default dateTextInputLocal
