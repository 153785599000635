/* eslint-disable max-len */
import { Theme } from '@vfuk/core-themes/dist/themes.types'

import { FunctionalCarouselTheme } from './FunctionalCarousel.theme.types'

import getSharedThemeValues from './SharedThemeValues'

const functionalCarouselLocal = (theme: Theme): FunctionalCarouselTheme => {
  const sharedThemeValues = getSharedThemeValues(theme)

  return {
    ...sharedThemeValues,
    cardBoxShadowOffset: theme.elevation.offset,
  }
}

export default functionalCarouselLocal
