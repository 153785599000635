import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledInteractiveIconProps } from './InteractiveIcon.style.types'

export const InteractiveIcon = styled(Interaction)((props: StyledInteractiveIconProps): FlattenSimpleInterpolation => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  position: relative;

  ${props.disabled && css`
    cursor: inherit;
  `}
`)
