import { HorizontalBarChartTheme } from '../../themes/HorizontalBarChart.theme.types'

function colourMap(localTheme: HorizontalBarChartTheme): { colors: string[]; inversedColors: string[] } {
  const appearanceValue = localTheme.appearance

  const predefinedColors: string[] = [
    appearanceValue.secondary.backgroundColor,
    appearanceValue.alt1.backgroundColor,
    appearanceValue.alt2.backgroundColor,
    appearanceValue.alt3.backgroundColor,
    appearanceValue.alt4.backgroundColor,
    appearanceValue.alt5.backgroundColor,
  ]

  const predefinedInversedColors: string[] = [
    appearanceValue.secondary.inverse.backgroundColor,
    appearanceValue.alt1.inverse.backgroundColor,
    appearanceValue.alt2.inverse.backgroundColor,
    appearanceValue.alt3.inverse.backgroundColor,
    appearanceValue.alt4.inverse.backgroundColor,
    appearanceValue.alt5.inverse.backgroundColor,
  ]

  return {
    colors: predefinedColors,
    inversedColors: predefinedInversedColors,
  }
}

export default colourMap
