import React, { FC } from 'react'

import * as Styled from './styles/Pill.style'

import { PillProps } from './Pill.types'

const Pill: FC<PillProps> = ({ appearance = 'primary', state, text }) => {
  return (
    <Styled.Pill appearance={appearance!} state={state}>
      {text}
    </Styled.Pill>
  )
}
export default Pill
