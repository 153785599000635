import React, { FC, ReactElement } from 'react'
import YoutubeVideo from '@vfuk/core-youtube-video'

import { VideoWrapper } from './styles/ContentVideo.style'

import { ContentVideoProps } from './ContentVideo.types'

const ContentVideo: FC<ContentVideoProps> = ({ videoPosition, videoId, videoTitle }: ContentVideoProps): ReactElement => (
  <VideoWrapper videoPosition={videoPosition}>
    <YoutubeVideo videoId={videoId} title={videoTitle} showControls={true} />
  </VideoWrapper>
)

export default ContentVideo
