import { AccordionTheme } from './Accordion.theme.types'

const accordionLocal: AccordionTheme = {
  appearance: {
    primary: {
      containerAppearance: 'primary',
      inverseCollapsibleContainer: false,
    },
    secondary: {
      containerAppearance: 'secondary',
      inverseCollapsibleContainer: false,
    },
  },
}

export default accordionLocal
